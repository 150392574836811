import { IntegrationsUpdate } from "features/Integrations/IntegrationsUpdate";
import { Wrapper } from "features/common/components/Wrapper";
import GlobalStyle from "styles/globalStyles";

const IntegrationsPage: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <IntegrationsUpdate />
    </Wrapper>
  );
};
export default IntegrationsPage;
