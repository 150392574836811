import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  gap: 16px;
  flex-direction: column;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-width: 720px;
`;

export const ContainerFields = styled.div`
  width: 100%;
`;

export const DropdownBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f4f3ff;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 750px;
  overflow: scroll;
  justify-content: flex-start;
`;
