import { createSlice } from "@reduxjs/toolkit";

interface IUserCredentials {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

const initialState: IUserCredentials = {
  accessToken: "",
  idToken: "",
  refreshToken: "",
};

const userCredentialsSlice = createSlice({
  name: "userCredentials",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      return action.payload;
    },
    resetCredentials: (state) => {
      return initialState;
    },
  },
});

export const { setCredentials, resetCredentials } =
  userCredentialsSlice.actions;

export default userCredentialsSlice.reducer;
