import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Modal } from "features/common/components/Modal/Modal";
import { InputField } from "../../../InputField/InputField";
import { inputFields } from "./utils";
import { ModalProps } from "../types";
import DXButton from "features/common/components/DXButton";
import { getData, put } from "services/api";
import { queryClient } from "index";
import { DropdownUser } from "../ModalAddUser/DropdownUser";

import {
  ContainerFields,
  Body,
  Wrapper,
  Container,
} from "../ModalAddUser/ModalAddUser.styles";

export default function ModalEditUser({
  isOpen,
  closeModal,
  userLeft,
  className,
  companyId,
  userToEdit,
  isEditing,
}: ModalProps) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  const { data: userData, isLoading } = useQuery(`getUser`, () =>
    getData(`/memberships/user/${userToEdit}`)
  );

  useEffect(() => {
    const fullName = userData?.name;
    const names = fullName?.split(" ");
    const firstName = names?.[0];
    const lastName = names?.[1];

    setUsers([
      {
        firstName: firstName,
        lastName: lastName,
        email: userData?.email,
        role: userData?.role,
      },
    ]);
  }, [userData]);

  const handleSubmitUser = async (user) => {
    try {
      const response = await put(`/memberships/user/${userData?.id}`, {
        company_id: companyId,
        role: user.role,
        name: `${user.firstName} ${user.lastName}`,
      }).then(() => queryClient.invalidateQueries(`getUsers_${companyId}`));
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const promises = users.map((user) => handleSubmitUser(user));
    await Promise.all(promises);
    closeModal();
  };

  const handleInputChange = (index, fieldId, value) => {
    const newUsers = [...users];
    newUsers[index][fieldId] = value;
    setUsers(newUsers);
  };

  return (
    <>
      <Modal.Body>
        <Wrapper>
          {users.map((user, index) => (
            <Body key={index}>
              {inputFields.map((field) => (
                <ContainerFields key={field.id}>
                  <InputField
                    label={field.label}
                    id={`${field.id}-${index}`}
                    type={field.type}
                    required={field.required}
                    placeholder={field.placeholder}
                    value={user[field.id]}
                    disabled={field.id === "email" && isEditing}
                    onChange={(event) =>
                      handleInputChange(index, field.id, event.target.value)
                    }
                  />
                </ContainerFields>
              ))}
              <ContainerFields>
                <DropdownUser
                  title={t("user-type")}
                  value={user.role}
                  handleChange={(event) =>
                    handleInputChange(index, "role", event.target.value)
                  }
                />
              </ContainerFields>
            </Body>
          ))}
        </Wrapper>
      </Modal.Body>
      <Container>
        <DXButton type="button" children="Save" onClick={handleSubmit} />
      </Container>
    </>
  );
}
