import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Step } from "features/common/components/Step/Step";
import { common } from "theme";
import { FilesStep1 } from "./Files";

import { StepContainer } from "../../TemplateLibrary.styles";
import { Container } from "./Step1.styles";

export const Step1 = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StepContainer className={isOpen ? "isOpen" : "isClose"}>
      <Step
        title="Type 1"
        color={common.colors.purple600}
        text={t("template-title")}
        subtitle={t("template-subtitle")}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <Container>
          <FilesStep1 />
        </Container>
      )}
    </StepContainer>
  );
};
