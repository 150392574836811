import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  position: relative;
  height: 200px;
  background-color: rgb(244, 243, 255);
  border-radius: 10px;


  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlg}) {
    gap: 20px;
    align-items: center;
    position: inherit;
    height: 100%;
  }
`;
export const Title = styled.div`
  color: black;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
`;
