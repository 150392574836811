import styled from "styled-components";

export const Subtitle = styled.div`
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const Img = styled.img`
  width: 150px;
  &.hubspot {
    width: 225px;
  }
`;

export const Card = styled.div`
  display: flex;
  padding: 48px 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  height: 228px;
  justify-content: center;
  cursor: pointer;

  //TODO: fix this hover
  &:hover {
    outline: 3px solid var(--gray-300, #d0d5dd);
  }

  &.selected {
    outline: 3px solid #235fdb;
  }

  &.hubspot {
    background: var(--gray-100, #f2f4f7);
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
`;
