import axios from "axios";
import store from "../redux/store";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const credentials = store.getState().userCredentials;
  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${credentials.accessToken}`,
  };
  return { ...config, headers };
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([400, 401].includes(error.response?.status)) {
      //   localStorage.clear();
      //   window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const getData = async (endpoint: string, options = {}) => {
  const { data } = await api.get(endpoint, options);
  return data;
};

export const getResponse = async (endpoint: string, options = {}) => {
  return await api.get(endpoint, options);
};

export const post = async (endpoint: string, options = {}) => {
  const response = await api.post(endpoint, options);
  return response?.data;
};

export const put = async (endpoint: string, options = {}) => {
  const response = await api.put(endpoint, options);
  return response?.data;
};

export const deleteData = async (endpoint: string, options = {}) => {
  const { data } = await api.delete(endpoint, options);
  return data;
};

export const checkUserInvite = async () => {

  const url = "/memberships/validate-invite"

  const res = await api.get(url)

  return res.data.valid_invite
}

export default api;
