import { useEffect, useState } from "react";
import { Container, Text, Title } from "./BillingInvoicing.styles";
import Box from "@mui/material/Box";
import DXButton from "features/common/components/DXButton";
import Stack from "@mui/material/Stack";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DxTable from "features/common/components/DXTable/DXTable";
import { useQuery } from "react-query";
import { getData } from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { downloadJSON } from "utils/downloadJSON";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

interface IInvoiceRow {
  id: string;
  date: string;
  name: string;
  frequecy: string;
  amount: number;
}

const formatUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const BillingInvoicing = () => {
  const { currentCompany } = useSelector((state: RootState) => state);
  const [tableData, setTableData] = useState<IInvoiceRow[]>([]);
  const [billDetails, setBillDetails] = useState({ renews: "", nextAmount: 0 });
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
    {
      field: "frequency",
      headerName: "Billing Frequency",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
    {
      field: "download",
      headerName: "Download",
      flex: 1,
      disableColumnMenu: true,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        permissions?.billing?.invoices?.download_invoices === 'enable' ? (
          <DXButton
            variant="text"
            color="primary"
            onClick={() => window.open(params.row.download)}
          >
            <CloudDownloadOutlinedIcon />
          </DXButton>
        ) : null
      ),
    },
  ];

  const { data: invoicesData, isLoading } = useQuery(`getInvoices`, () =>
    getData(`/sales/invoice?membership_account_id=${currentCompany?.account.id}`)
  );

  const { data: billingDetails, isSuccess } = useQuery(`getInvoices`, () =>
    getData(
      `/sales/upcoming-invoice?membership_account_id=${currentCompany?.account.id}`
    )
  );

  const handlePlanClick = () => {
    window.location.href = 'https://billing.stripe.com/p/login/eVaeX890t0qg3bWdQQ';
  };

  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);


  useEffect(() => {
    if (isSuccess) {
      setBillDetails({
        renews: billingDetails.items[0].next_payment_attempt
          ? billingDetails.items[0].next_payment_attempt
          : "--",
        nextAmount: billingDetails.items[0].amount_due,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      setTableData(
        invoicesData.items.map((item, index) => {
          return {
            id: index + 1,
            date: new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(item.effective_at * 1000),
            name: item.customer_name,
            frequency: item.lines.data[0].plan.interval,
            amount: formatUSD.format(item.amount_paid / 100),
            download: item.invoice_pdf,
          };
        })
      );
    }
  }, [isLoading]);

  return (
    <>
      {permissions?.billing?.billing_details?.full_data === 'enable' && (
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Title>Billing Details</Title>
            {permissions?.billing?.billing_details?.edit_plan_button === 'enable' && (
              <DXButton 
          variant="text" 
          color="info" 
          size="medium"
          onClick={handlePlanClick}>
                Edit Plan
              </DXButton>
            )}
          </Box>
          <Stack direction={"row"} gap={10} width="100%">
            <Box sx={{ width: "100%" }}>
              <Text>Company:</Text>
              <Text>{currentCompany?.company?.name}</Text>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Text>Billed To:</Text>
              <Text>{currentCompany?.account?.name}</Text>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Text>Renews:</Text>
              <Text>{billDetails?.renews}</Text>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Text>Next Billing Amount:</Text>
              <Text>{formatUSD.format(billDetails?.nextAmount / 100)}</Text>
            </Box>
          </Stack>
        </Container>
      )}
      {permissions?.billing?.invoices?.invoice_history === 'enable' && (
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Title>Invoices</Title>
            {permissions?.billing?.invoices?.export_data === 'enable' && (
              <DXButton
                variant="text"
                color="primary"
                size="large"
                startIcon={<CloudDownloadOutlinedIcon />}
                onClick={() =>
                  downloadJSON(invoicesData.items, "Invoices_Report.json")
                }
              >
                Export Data
              </DXButton>
            )}
          </Box>
          <DxTable
            checkboxField={false}
            rows={tableData}
            columns={columns}
            className="profile"
          />
        </Container>
      )}
    </>
  );
};

export default BillingInvoicing;
