import { getData } from "services/api";

const useConnectQuickbooks = () => {
  const QBConnect = async (
    accountId: string,
    cognitoId: string,
    isCheckedAccounting?: boolean,
    companyId?: string,
    isProfile?: boolean
  ) => {
    try {
      const isSignUpPage = window.location.href.includes("sign-up");
      const isUploadFile = window.location.href.includes("uploadFiles");

      const failRedirect = isSignUpPage
        ? `${process.env.REACT_APP_ROOT_DOMAIN}sign-up/connected%3Dfalse%235`
        : isUploadFile
          ? `${process.env.REACT_APP_ROOT_DOMAIN}uploadFiles%3Fconnected%3Dfalse%26id%3D${companyId}`
          : isProfile
            ? `${process.env.REACT_APP_ROOT_DOMAIN}settings%2F%3Fconnected%3Dfalse%26tabValue%3D0%26id%3D${companyId}`
            : `${process.env.REACT_APP_ROOT_DOMAIN}settings%2F%3Fconnected%3Dfalse%26tabValue%3D1%26openModal%3Dtrue%26id%3D${companyId}`;

      const successRedirect = isSignUpPage
        ? `${process.env.REACT_APP_ROOT_DOMAIN}sign-up%3Fconnected%3Dtrue%26id%3D${companyId}%235`
        : isUploadFile
          ? `${process.env.REACT_APP_ROOT_DOMAIN}uploadFiles%2F%3Fconnected%3Dtrue%26id%3D${companyId}`
          : isProfile
            ? `${process.env.REACT_APP_ROOT_DOMAIN}settings%2F%3Fconnected%3Dtrue%26tabValue%3D0%26id%3D${companyId}`
            : `${process.env.REACT_APP_ROOT_DOMAIN}settings%2F%3Fconnected%3Dtrue%26tabValue%3D1%26openModal%3Dtrue%26id%3D${companyId}`;

      let response;

      if (isCheckedAccounting) {
        response = await getData(
          `quickbooks/authUrlPayment?${companyId ? `membership_company_id=${companyId}` : ""
          }&membership_account_id=${accountId}&cognito_id=${cognitoId}&on_fail_redirect=${failRedirect}&on_success_redirect=${successRedirect}`
        );
      } else {
        response = await getData(
          `quickbooks/authUrl?${companyId ? `membership_company_id=${companyId}` : ""
          }&membership_account_id=${accountId}&cognito_id=${cognitoId}&on_fail_redirect=${failRedirect}&on_success_redirect=${successRedirect}`
        );
      }

      if (response.authUrl) {
        window.location.href = response.authUrl;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { QBConnect };
};

export default useConnectQuickbooks;
