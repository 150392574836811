import { createSlice } from "@reduxjs/toolkit";
import { Account, Company } from "redux/types";

interface ICurrentCompany {
  account?: Account;
  company?: Company;
  rbac?: Record<string, string>;
}

const initialState: ICurrentCompany = {
  account: undefined,
  company: undefined,
  rbac: undefined
};

const currentCompanySlice = createSlice({
  name: "currentCompany",
  initialState,
  reducers: {
    setCurrentCompany: (state, action) => {
      return action.payload;
    },
    resetCurrentCompany: (state) => {
      return initialState;
    },
  },
});

export const { setCurrentCompany, resetCurrentCompany } =
  currentCompanySlice.actions;

export default currentCompanySlice.reducer;
