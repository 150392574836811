import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Icon } from "../Icon";
import ModalDeleteUser from "./UserModals/ModalDeleteUser/ModalDeleteUser";
import TableUser from "./TableUser/TableUser";
import DXModal from "../DXModal";
import DXButton from "../DXButton/DXButton";
import ModalAddUserForm from "./UserModals/ModalAddUser/ModalAddUserForm";
import ModalEditUserForm from "./UserModals/ModalEditUser/ModalEditUserForm";
import { License } from "redux/types";
import useCurrentUser from "hooks/useCurrentUser";
import ModalAddUserClient from "./UserModals/ModalAddUser/ModalAddUserClient";

import {
  Container,
  Text,
  TextContainer,
  Information,
  Row,
} from "./AddUsers.styles";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}
interface AddUserProps {
  isOrganization?: boolean;
  setUsers?: Dispatch<SetStateAction<ICreateUser[]>>;
  users?: ICreateUser[];
  licenses?: License;
}

export const AddUsers = ({
  isOrganization,
  users,
  setUsers,
  licenses,
}: AddUserProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [userIndex, setUserIndex] = useState<number>();
  const { dxUserData } = useCurrentUser();
  const [licensesLeft, setLicensesLeft] = useState(0);

  useEffect(() => {
    setLicensesLeft(
      dxUserData.accounts?.[0].license.max_users -
      parseInt(dxUserData.accounts?.[0].companies[0]?.number_of_users || "0")
    );
  }, [dxUserData]);

  return (
    <Container>
      <TextContainer>
        <Text>{t("add-user-invite")}</Text>
        <Text className="subtitle">
          {t(
            isOrganization
              ? "add-user-invite-subtitle"
              : "add-user-invite-account"
          )}
        </Text>
      </TextContainer>
      <Information>
        <Row>
          <Icon iconName="user-02" size="M" />
          <Text className="information">
            You have {licensesLeft} user licenses left.
          </Text>
        </Row>
      </Information>
      <TableUser
        setIsEditModal={setIsEditModal}
        setIsDeleteModal={setIsDeleteModal}
        users={users}
        setUserIndex={setUserIndex}
      />
      <DXButton
        type="submit"
        children="Add User(s)"
        onClick={() => setIsOpen(true)}
        disabled={licensesLeft <= 0}
      />
      {isOpen && isOrganization && (
        <ModalAddUserForm
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          users={users}
          setUsers={setUsers}
          userLeft={licensesLeft}
        />
      )}
      {isOpen && !isOrganization && (
        <ModalAddUserClient
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          users={users}
          setUsers={setUsers}
          userLeft={licensesLeft}
          accountId={dxUserData?.accounts[0]?.id}
        />
      )}
      {isEditModal && (
        <DXModal
          title={"Edit User"}
          open={isEditModal}
          onClose={() => setIsEditModal(false)}
          customWidth={800}
          children={
            <ModalEditUserForm
              isOpen={isEditModal}
              closeModal={() => setIsEditModal(false)}
              users={users}
              setUsers={setUsers}
              userIndex={userIndex}
            />
          }
        />
      )}

      {isDeleteModal && (
        <ModalDeleteUser
          isOpen={isDeleteModal}
          closeModal={() => setIsDeleteModal(false)}
          setUsers={setUsers}
          users={users}
          userIndex={userIndex}
        />
      )}
    </Container>
  );
};
