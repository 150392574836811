import DXInputField from "../DXInputField/DXInputField";
import { Column } from "./InputField.styles";

interface InputFieldProps {
  label;
  id;
  type;
  required;
  placeholder: string;
  value: string;
  onChange;
  disabled?: any;
}

export const InputField = ({
  label,
  id,
  type,
  required,
  placeholder,
  value,
  onChange,
  disabled,
}: InputFieldProps) => {
  return (
    <Column>
      <DXInputField
        label={label}
        type={type}
        id={id}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </Column>
  );
};
