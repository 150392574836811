import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
`;

export const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
  }

  &.step3 {
    display: flex;
    flex-direction: column;
  }
    
  &.disabledType3 {
    opacity: 0.5;
    }
`;