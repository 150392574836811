import styled from "styled-components";

import { IconElementProps } from "./types";
import { L, M, XS } from "./utils";

const calculateSize = (size?: string) => {
  switch (size) {
    case XS:
      return "4px";
    case M:
      return "16px";
    case L:
      return "32px";
    case undefined:
    default:
      return "20px";
  }
};

export const IconElement = styled.img<IconElementProps>`
  cursor: ${({ cursor }) => cursor};
  width: ${({ size }) => calculateSize(size)};
  height: ${({ size }) => calculateSize(size)};
  &.isOpen {
    rotate: 180deg;
  }
  &.pointer {
    cursor: pointer;
  }
`;
