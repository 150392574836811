import DXChip from "features/common/components/DXChip/DXChip";
import useCurrentUser from "hooks/useCurrentUser";
import { useTranslation } from "react-i18next";
import { quantityLicensesCompanies } from "utils/quantityLicensesAndUsers";
import { Form } from "./Form";
import {
  Content,
  PageContainer,
  StepContainer,
  StepTitle,
  Title,
} from "./ManageClientCompany.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export const ManageClientAccount = ({ onClose }) => {
  const { t } = useTranslation();
  const { dxUserData } = useCurrentUser();
  const { currentCompany } = useSelector((state: RootState) => state);
  const licensesLeft = quantityLicensesCompanies(dxUserData, currentCompany?.account);


  return (
    <PageContainer>
      <StepContainer>
        <Content>
          <Title>
            <StepTitle>{t("step-Client-Account-Title")}</StepTitle>
          </Title>
          <DXChip
            label={`You have ${licensesLeft} company licenses left.`}
            color="success"
          />
          <Form onClose={onClose} />
        </Content>
      </StepContainer>
    </PageContainer>
  );
};
