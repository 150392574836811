import { useTranslation } from "react-i18next";

import { Step } from "features/common/components/Step/Step";
import { common } from "theme";
import DXButton from "features/common/components/DXButton/DXButton";

import { ButtonContainer, StepContainer } from "../UploadFiles.styles";
import { useHistory } from "react-router-dom";

export const Step1 = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <StepContainer>
      <Step
        title="Step 1"
        color={common.colors.purple600}
        text={t("input-data")}
        isUpload
      />
      <ButtonContainer>
        <DXButton
          children={t("button-template-library")}
          color={"primary"}
          variant={"contained"}
          size={"small"}
          onClick={() => history.push("/templateLibrary")}
        />
      </ButtonContainer>
    </StepContainer>
  );
};
