import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "./DropdownQBAccountTypes.styles";

interface DropdownProps {
  title?: string;
  options?: string | string[];
  setOrganizationUser?: Dispatch<SetStateAction<string>>;
  organizationSize?: string;
  handleChange?: any;
  value?: string;
  className?: string;
}

export const DropdownQBAccountTypes = ({
  title,
  options,
  handleChange,
  value,
  className,
}: DropdownProps) => {
  const { t } = useTranslation();
  console.log({ value })
  return (
    <Styled.Container>
      <Styled.TextForm>{title}</Styled.TextForm>
      <Styled.Dropdown
        displayEmpty
        defaultValue=""
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          MenuListProps: {
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
              maxWidth: className === "small" ? 600 : 718,
              minWidth: className === "small" ? 600 : 718,
            },
          },
        }}
        onChange={handleChange}
        value={value}
        renderValue={(selected) => {
          switch (selected) {
            case "QBO":
              return t("quickbooks-option-1");
            case "QBO_AND_PAYMENT":
              return t("quickbooks-option-2");
            default:
              return "";
          }
        }}
      >
        <Styled.MenuItemStyle className="organization" value={"QBO"}>
          <Styled.Option>
            <Styled.Column>
              <Styled.Row className="editUser">
                <Styled.Text>
                  {t("quickbooks-option-1")}
                </Styled.Text>
              </Styled.Row>
            </Styled.Column>
          </Styled.Option>
        </Styled.MenuItemStyle>
        <Styled.Divider />
        <Styled.MenuItemStyle className="organization" value={"QBO_AND_PAYMENT"}>
          <Styled.Option>
            <Styled.Column>
              <Styled.Row className="editUser">
                <Styled.Text>{t("quickbooks-option-2")}</Styled.Text>
              </Styled.Row>
            </Styled.Column>
          </Styled.Option>
        </Styled.MenuItemStyle>
        <Styled.Divider />
      </Styled.Dropdown>
    </Styled.Container>
  );
};
