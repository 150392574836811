import { useTranslation } from "react-i18next";

import { StepProps } from "../types";
import { Form } from "./Form/Form";

import * as StyledStep from "features/Steps/Steps.styles";

export const Profile = ({ setCurrentStep }: StepProps) => {
  const { t } = useTranslation();

  return (
    <StyledStep.PageContainer>
      <StyledStep.StepContainer>
        <StyledStep.Content>
          <StyledStep.Title>
            <StyledStep.StepTitle>
              {t("step-Profile-Title")}
            </StyledStep.StepTitle>
            <StyledStep.StepSubtitle>
              {t("step-Profile-Subtitle")}
            </StyledStep.StepSubtitle>
          </StyledStep.Title>
          <Form setCurrentStep={setCurrentStep} />
        </StyledStep.Content>
      </StyledStep.StepContainer>
    </StyledStep.PageContainer>
  );
};
