import styled from "styled-components";

import { common } from "theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${common.colors.white};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
`;

export const Text = styled.div`
  color: ${common.colors.black2};
  font-family: ${common.fonts.primary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
`;

export const Image = styled.img`
  cursor: pointer;
`;
