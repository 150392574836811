import LaunchIcon from "@mui/icons-material/Launch";
import { getData } from "services/api";
import DXButton from "../DXButton";
import { ReportProps } from "./types";

import {
  ButtonContainer,
  ContainerLeft,
  ContainerRight,
  IconFile,
  ReportContainer,
  Text
} from "./Report.styles";
import { Icon } from "../Icon";
import toast from "react-hot-toast";

export const Report = ({
  isSuccess,
  isPartialSuccess,
  isTemplateSuccess,
  text,
  message,
  file_created_at,
  report,
  status,
  url,
}: ReportProps) => {
  const handleDownloadClick = (url: string) => {
    window.location.href = url;
  };

  const handleReport = async (url: string) => {
    try {
      const res = await getData(url);
      handleDownloadClick(res.url);
    } catch (error) {
      toast.error("Error downloading report. Please try again.");
      console.error(error);
    }
  };

  return (
    <ReportContainer className={isSuccess || isPartialSuccess ? "isSuccess" : isTemplateSuccess ? "isTemplateSuccess" : ""}>
      <ContainerLeft>
        <IconFile
          iconName="iconFile"
        // TODO
        // onClick={() => {
        //   url !== "" && handleDownloadClick(url);
        // }}
        />
        <Text>
          {text} {file_created_at}
        </Text>
        <ButtonContainer>
          {isSuccess && !isPartialSuccess && (
            // TODO: check if the quickbook is the same for open it
            <DXButton
              endIcon={<LaunchIcon />}
              color="success"
              variant="outlined"
              onClick={() => window.open("https://accounts.intuit.com/")}
            >
              Open QuickBooks
            </DXButton>
          )}
          {
            isPartialSuccess && (
              <DXButton
                endIcon={<LaunchIcon />}
                color="success"
                variant="outlined"
                onClick={() => handleReport(`${report}?includeDeduplication=true`)}
              >
                Open Report
              </DXButton>
            )}
          {!isSuccess && report && !isPartialSuccess && (message !== "TEMPLATE_FAILURE" && status !== "EMPTY_TEMPLATE") && (
            <DXButton
              endIcon={<LaunchIcon />}
              color="error"
              variant="outlined"
              onClick={() => handleReport(report)}
            >
              Open Report
            </DXButton>
          )}
        </ButtonContainer>
      </ContainerLeft>
      <ContainerRight>
        <Icon iconName={isSuccess || isPartialSuccess ? "check-circle" : isTemplateSuccess ? "check-circle" : "alert-circle"} />
        <Text>{message}</Text>
      </ContainerRight>
    </ReportContainer>
  );
};
