import { Dispatch, SetStateAction } from "react";
import { MenuItem } from "@mui/material";

import * as Styled from "./Dropdown.styles";

interface DropdownProps {
  title?: string;
  options?: string | string[];
  setOrganizationSize?: Dispatch<SetStateAction<string>>;
  organizationSize?: string;
  handleChange?: any;
  value?: string;
}

export const Dropdown = ({
  title,
  options,
  handleChange,
  value,
}: DropdownProps) => {
  return (
    <Styled.Container>
      <Styled.TextForm>{title}</Styled.TextForm>
      <Styled.Dropdown
        displayEmpty
        defaultValue=""
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          MenuListProps: { sx: { paddingTop: 0, paddingBottom: 0 } },
        }}
        onChange={handleChange}
        value={value}
      >
        {/* organization size option */}

        <MenuItem value={10}>0 - 10</MenuItem>
        <MenuItem value={50}>11 - 50</MenuItem>
        <MenuItem value={99}>51-99</MenuItem>
        <MenuItem value={999}>100-999</MenuItem>
        <MenuItem value={9999}>1000-9999</MenuItem>
        <MenuItem value={10000}>1000+</MenuItem>
        {/* TODO: make it common - here are the options of organization and account  */}
        {/* {options === "organization" && <OrganizationOptions />}
        {options === "account" && <AccountOptions />} */}
      </Styled.Dropdown>
    </Styled.Container>
  );
};
