import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Box, FormControlLabel, Radio } from "@mui/material";

import DXIcon from "features/common/components/DXIcon";
import DXModal from "features/common/components/DXModal";
import QBButton from "features/common/components/QBButton/QBButton";
import QBLogoutButton from "features/common/components/QBButton/QBLogoutButton";
import { Step } from "features/common/components/Step/Step";
import useConnectQuickbooks from "hooks/useConnectQuickbooks";
import useCurrentUser from "hooks/useCurrentUser";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { post } from "services/api";
import { common } from "theme/common";
import * as Styled from "../../common/components/DragAndDrop/DragAndDrop.styles";
import {
  ButtonContainer,
  DragAndDropContainer,
  Input,
  QBStep,
  StepContainerColumn
} from "./Steps.styles";

interface Step2Props {
  accountId: string;
  companyId: string;
  setIsUploading: Dispatch<SetStateAction<boolean>>;
}

interface IFilesToUpload {
  name: string;
  size: number;
  status: string;
}

export const Step2 = ({ accountId, companyId, setIsUploading }: Step2Props) => {
  const { t } = useTranslation();
  const [batchJobId, setBatchJobId] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState<IFilesToUpload[]>([]);
  const [uploadsDone, setUploadsDone] = useState(false);
  const { currentCompany } = useSelector((state: RootState) => state);
  const { userData, dxUserData } = useCurrentUser();
  const params = new URLSearchParams(window.location.search);
  const { QBConnect } = useConnectQuickbooks();
  const [isOpenModalQB, setIsOpenModalQB] = useState(false);
  const [isCheckedAccounting, setIsChecked] = useState(false);
  const isReconnect = params.get("connected") === "false";
  const [isQBconnected, setQBconnected] = useState(false)
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const getUploadParams = async ({ file, meta }) => {
    try {
      const response = await post("/files/v2/files", {
        original_filename: file.name,
        metadata: meta,
        origin: "quickbooks",
        content_type: file.type,
        membership_account_id: accountId,
        membership_company_id: companyId,
        batch_job_id: batchJobId,
      });

      return { body: file, url: response.preSignedUrl, meta, method: "put" };
    } catch (error) {
      console.error("Failed to upload file", error);
    }
  };

  const handleChangeStatus = (file: IFileWithMeta, status) => {
    setIsUploading(false);
    if (status === "preparing") {
      setFilesToUpload((prev) => {
        prev.push({
          name: file.meta.name,
          status: status,
          size: file.meta.size,
        });

        return prev;
      });
      setUploadsDone(false);
    }

    if (status === "error_upload") {
      toast.error(`Failed to upload file ${file.meta.name}!`);
    }

    if (status === "done") {
      const updated: IFilesToUpload[] = filesToUpload.map((f) => {
        if (f.name === file.meta.name && f.size === file.meta.size) {
          return { ...f, status: status };
        }
        return f;
      });
      setIsUploading(true);
      setFilesToUpload(updated);
    }
  };

  useEffect(() => {
    if (filesToUpload.length > 0)
      setUploadsDone(filesToUpload.every((f) => f.status === "done"));
  }, [filesToUpload]);

  useEffect(() => {
    setBatchJobId(uuidv4());
  }, []);

  const handleJobs = async () => {
    try {
      await post("/jobs/v2/jobs", {
        origin: "quickbooks",
        metadata: {},
        membership_account_id: accountId,
        membership_company_id: companyId,
        batch_job_id: batchJobId,
      });
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload file", error);
    }
  };

  const handleQBconnection = () => {
    setIsOpenModalQB(true)
  }

  useEffect(() => {
    if (dxUserData?.accounts?.length) {
      setQBconnected(Boolean(currentCompany?.account?.companies.find(el => el.id === currentCompany?.company?.id)?.credentials_id))
    }
  }, [dxUserData, currentCompany])

  return (
    <StepContainerColumn>
      <Step
        title="Step 2"
        color={common.colors.blueDark}
        text={t("upload-files-step2")}
        isUpload
      />
      <DragAndDropContainer>
        <Dropzone
          disabled={!isQBconnected}
          onChangeStatus={handleChangeStatus}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getUploadParams={getUploadParams}
          inputContent={
            <Styled.Information>
              <Styled.IconContainer>
                <Styled.IconUpload iconName="upload-icon" />
              </Styled.IconContainer>
              <Styled.ColumnText>
                <Styled.RowText>
                  <Styled.Text className="upload">
                    {t("step-Organization-Upload-Button1")}
                  </Styled.Text>
                  <Styled.Text>
                    {t("step-Organization-Upload-Button2")}
                  </Styled.Text>
                </Styled.RowText>
                <Styled.Text>
                  {t("step-Organization-Upload-Button-Information-Sheet")}
                </Styled.Text>
              </Styled.ColumnText>
            </Styled.Information>
          }
          styles={{
            dropzone: {
              border: "1px dashed #bdb4fe",
              height: "220px",
            },
            inputLabel: {
              paddingTop: "60px",
            },
            dropzoneActive: {
              background: "#d6d1fa",
              outline: "#bdb4fe",
            },
          }}
        />
      </DragAndDropContainer>
      <ButtonContainer>
        {isQBconnected ?
          <QBButton
            children={t("send-files-quickbooks")}
            disabled={!uploadsDone}
            onClick={handleJobs}
          />
          :
          <QBButton
            children={t("quickbooks-button")}
            onClick={handleQBconnection}
          />
        }
      </ButtonContainer>
      <DXModal
        title="Add a QuickBooks Account"
        open={isOpenModalQB}
        onClose={() => setIsOpenModalQB(!isOpenModalQB)}
        customWidth={700}
      >

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
          width: "100%",
          paddingTop: "32px",
        }}>

          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}>

            <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
              <QBStep>Step 1</QBStep>
            </Box>

            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>

              <Box sx={{ width: "220px" }}>
                <QBLogoutButton
                  children={"Confirm you are logged out"}
                  onClick={() => window.open("https://accounts.intuit.com/", '_blank')}
                  sx={{ width: "220px", fontSize: "13px", backgroundColor: "#6938EF" }}
                />
              </Box>

              <Input style={{ textAlign: "justify", marginTop: "20px" }}>
                Please click the button above to verify. If an existing QuickBooks workspace opens,  please sign out of that workspace before continuing to Step 2 on this screen. If instead you are prompted to sign in to QuickBooks, this confirms you are already successfully logged out and can proceed to Step 2. Please wait to sign in until Step 3 as you need to verify the type of account first.
              </Input>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            gap: 2,
          }}>

            <Box sx={{ minWidth: "60px" }}>
              <QBStep>Step 2</QBStep>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>

              <Box sx={{ fontWeight: "bold" }}>
                <Input>Select type of account</Input>
              </Box>

              <Box>
                <Input style={{ color: "#6938EF", margin: 0, cursor: "pointer" }}
                  onClick={() => setOpenInfoModal(true)}
                >
                  *How do I know if I have access to the QuickBooks Online Payments module? </Input>
              </Box>

              <Box sx={{
                display: "flex",
                justifyContent: "center",
              }}>
                <FormControlLabel
                  sx={{ width: "50%", margin: 0, }}
                  control={<Radio />}
                  label="QuickBooks Online only"
                  onChange={() => setIsChecked(false)}
                  checked={!isCheckedAccounting}
                />

                <FormControlLabel
                  sx={{ width: "50%", margin: 0 }}
                  control={<Radio />}
                  label={"QuickBooks Online and \n QuickBooks Online Payments"}
                  onChange={() => setIsChecked(true)}
                  checked={isCheckedAccounting}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            gap: 2,
          }}>

            <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
              <QBStep>Step 3</QBStep>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
            }}>

              <Box sx={{ width: "220px" }}>
                <QBButton
                  children={isReconnect ? "Reconnect" : "Connect to QuickBooks"}
                  endIcon={<DXIcon name="ConnectQuickbook" />}
                  onClick={() => QBConnect(currentCompany?.account.id, userData.sub, isCheckedAccounting, companyId, true)}
                  sx={{ width: "220px", fontSize: "13px" }}
                />
              </Box>

              <Input style={{ marginTop: "20px" }}>Once connected, you can seamlessly toggle across workstreams directly in DatumXpress.</Input>
            </Box>
          </Box>
        </Box>

      </DXModal>

      <DXModal title={""} open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
        <Input>The QuickBooks Online Payments module is an add-on to standard QuickBooks Online plans that enables features related to the remittance of payments. To upgrade your QuickBooks plan to include payments please <a href="https://quickbooks.intuit.com/money/see-plans/" target="_blank">click here</a>.<br /><br />

          To confirm if your account has Payments' access, please check your product subscription tab or Billing tab within QuickBooks which will indicate "QuickBooks Online Payments."<br /><br />

          Within DatumXpress, this module allows you to access templates associates with Bill Payments, Credit Card Charges, and Transfers which are otherwise disabled. </Input>
      </DXModal>
    </StepContainerColumn>
  );
};
