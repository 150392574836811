import styled from "styled-components";

export const Text = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding-left: 16px;
`;

export const Container = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

export const AccountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextFilter = styled.div`
  color: var(--gray-modern-700, #364152);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const TextUpgrade = styled.div`
  color: var(--gray-800, #1d2939);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.number {
    color: #235fdb;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const UpgradeButton = styled.div`
  color: #235fdb;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
`;

export const UpgradeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 32px;
  gap: 4px;
`;

export const CompanyContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

export const CircularProgressContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
`;
