import styled from "styled-components";

export const TextForm = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const Input = styled.input`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray700};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.purple300};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.lavender},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const InputContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;

  &.column {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
