import styled from "styled-components";

export const ComponentTitle = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
  background: white;
  border-width: 0px;
  border-bottom: 0px;

  &.isClose {
    border-radius: 12px;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.03),
      0px 1px 4px 0px rgba(16, 24, 40, 0.06);
  }
`;

export const Title = styled.div`
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const UserComponent = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  border: 1px solid var(--purple-100, #ebe9fe);
  background: var(--purple-50, #f4f3ff);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--gray-700, #344054);
`;

export const OrgComponent = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  border: 1px solid var(--purple-100, #ebe9fe);
  background: #ecfff8;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--gray-700, #344054);
`;


export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

export const Icon = styled.div`
  border-radius: 6px;
  border: 1px solid var(--gray-100, #f2f4f7);
  background: var(--gray-50, #f9fafb);
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const Input = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-around;
  width: 100%;
`;

export const IconAction = styled.button`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: flex-end;
  font-weight: 500;
  background: transparent;
  border: none;
`;

export const ActionContainer = styled.div``;

export const ContainerDelete = styled.div`
  display: flex;
`;

export const Padding = styled.div`
  margin-left: 250px;
`;
