import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import CryptoJS from "crypto-js";
import { MainLayout } from "features/common/components/MainLayout/MainLayout";
import { SecureData } from "features/common/components/SecureData/SecureData";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { RootState } from "redux/reducers";
import { getData } from "services/api";
import { v4 as uuidv4 } from "uuid";
import { ActivityLog } from "./Steps/ActivityLog";
import { Step1 } from "./Steps/Step1";
import { Step2 } from "./Steps/Step2";
import { Container, Text } from "./UploadFiles.styles";
import { ILogData } from "./types";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { useHistory } from "react-router-dom";

export const UploadFiles = () => {
  const { t } = useTranslation();
  const [logData, setLogData] = useState<ILogData[]>([]);
  const [signedUrl, setSignedUrl] = useState<string>("");
  const { currentCompany } = useSelector((state: RootState) => state);

  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const { boot } = useIntercom();
  const history = useHistory();

  const { data: content, isLoading } = useQuery("getProfileInformation", async () => {
    try {
      console.log("Getting profile information")
      const data = await getData("/memberships/user/profile");
      if (data) {
        console.log("Setting current company")
        dispatch(setDXUserData(data));
        if (currentCompany?.account && currentCompany?.company) {
          console.log("Setting current company", currentCompany)
          const account = data.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
          const company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
          const rbac = data.rbac;
          dispatch(setCurrentCompany({ account, company, rbac }))
        } else {
          const account = data.accounts[0]
          const company = data.accounts[0].companies[0]
          const rbac = data.rbac;
          dispatch(setCurrentCompany({ account, company, rbac }))
        }
        return data.response;
      }
    } catch (err) {
      throw err;
    }
  })



  useEffect(() => {
    let second;

    const fetchDataAndUpdateStatus = async () => {
      let logDataResponse = await updateActivityLog();
      let fileStatus = logDataResponse?.map((log) => log.file_status);
      if (fileStatus?.includes("PENDING") || fileStatus?.includes("STARTED")) {
        second = setInterval(async () => {
          console.log("Updating activity log, file is pending")
          logDataResponse = await updateActivityLog();
          fileStatus = logDataResponse?.map((log) => log.file_status);
          if (!fileStatus?.includes("PENDING") && !fileStatus?.includes("STARTED")) {
            clearInterval(second);
          }
        }, 3000);
      };
      console.log("Checking file status");
      console.log({ fileStatus });
    };

    fetchDataAndUpdateStatus();

    if (isUploading) {
      second = setInterval(() => {
        console.log("Updating activity log, uploading is true")
        updateActivityLog();
      }, 30000);
    }


    console.log("Clearing interval")
    return () => clearInterval(second);
  }, [currentCompany, isUploading])

  useMemo(() => {
    const compRole = currentCompany?.company?.role;
    const orgRole = currentCompany?.account?.role;
    const rbac = currentCompany?.rbac;
    const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

    if (permissions?.main_dashboard?.side_bar?.upload_files === 'disable') {
      history.push("/settings");
    }
  }, [currentCompany])


  const updateActivityLog = async () => {
    let data;
    try {
      data = await getData(`/jobs/v2/jobs?membership_account_id=${currentCompany?.account?.id}&membership_company_id=${currentCompany?.company?.id}&offset=0&limit=1000`);
      setLogData(data.response);
      setSignedUrl(data.signedUrl);
    } catch (err) {
      console.log(err);
    }
    return data?.response;
  };

  useEffect(() => {

    if (content && !isLoading) {
      if (!currentCompany?.account) {
        dispatch(
          setCurrentCompany({
            account: content?.accounts[0],
            company: content?.accounts[0]?.companies[0],
            rbac: content.rbac
          })
        );
      }
    }
    const secretKey = "o_vAXwSgKjxc9ig6rJwRC6HKsZlaG0l6ihPZCDcF";

    const hash = CryptoJS.HmacSHA256('email@datumexpress.com', secretKey).toString(CryptoJS.enc.Hex);
    CryptoJS.AES.encrypt("my message", "secret key 123").toString();
    boot({
      api_base: "https://app.intercom.com",
      app_id: "zvmp41ux",
      user_id: uuidv4(),
      user_hash: hash,
      actionColor: "rgb(105, 56, 239)",
      backgroundColor: "#0040C1",
    } as any);
    dispatch(setDXUserData(content));
  }, [content]);

  return (
    <MainLayout>
      <Container>
        <Text>{t("upload-files")}</Text>
        <Step1 />
        <Step2
          accountId={currentCompany?.account?.id || ""}
          companyId={currentCompany?.company?.id || ""}
          setIsUploading={setIsUploading}
        />
        <ActivityLog logData={logData} signedUrl={signedUrl} />
        <SecureData />
      </Container>
    </MainLayout>
  );
};
