import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 16px 24px 16px;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.gray300};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.blueDark2};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  &.pointer {
    cursor: pointer;
  }
  &.language {
    width: 90px;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Divider = styled.div`
  height: 12px;
  background: ${({ theme }) => theme.colors.gray300};
  width: 1px;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  gap: 8px;
`;

export const DividerFooter = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.gray300};
`;

export const ContainerFooter = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.blueDark2};
  flex-direction: column;
`;

export const TextFooter = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;
