import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "features/common/components/Icon";
import { useHashParameters } from "hooks/useHashParameters";
import { Step } from "./Step";
import { Account } from "./Steps/Account/Account";
import { ClientsAccount } from "./Steps/ClientsAccount/ClientsAccount";
import { Organization } from "./Steps/Organization/Organization";
import { Plan } from "./Steps/Plan/Plan";
import { Profile } from "./Steps/Profile/Profile";
import { stepsData } from "./utils";

import * as Styled from "./Steps.styles";

export const Steps = () => {
  const { t } = useTranslation();
  const [hashParam] = useHashParameters();

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    setCurrentStep(parseInt(hashParam.charAt(0)));
  }, [hashParam]);

  const isInviteQuery = () => {

    if (hashParam.includes("invite_email")) {
      return 1
    } else {
      return 5
    }
  }

  // const { data: userInvite } = useQuery(
  //   `getInvite`,
  //   () => getData(`/memberships/validate-invite`),
  //   {
  //     enabled: !!credentials.accessToken,
  //   }
  // );

  // useEffect(() => {
  //   if (userInvite && userInvite.valid_invite === true) {
  //     history.push("/settings");
  //   }
  // }, [userInvite]);

  return (
    <Styled.Container>
      <Styled.StepsContainer>
        <Styled.StepsList>
          {stepsData.slice(0, isInviteQuery()).map((step, index) => (
            <Step
              key={index}
              title={step.title}
              subtitle={step.subtitle}
              index={index}
              currentStep={currentStep}
            />
          ))}
        </Styled.StepsList>
        <Styled.InformationContainer>
          <Styled.Information> © DatumXpress, LLC 2022</Styled.Information>
          <Styled.EmailContainer>
            <Icon iconName="email" size="M" />
            <Styled.Information><a 
            href="mailto:info@datumxpress.com"
            style={{
              color: 'rgb(71, 84, 103)',
              textDecoration: "none"
            }}
            >{t("emailInfo")}</a></Styled.Information>
          </Styled.EmailContainer>
        </Styled.InformationContainer>
      </Styled.StepsContainer>
      {hashParam.includes("1") && <Profile setCurrentStep={setCurrentStep} />}
      {hashParam === "2" && (
        <Account
          setCurrentStep={setCurrentStep}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      )}
      {hashParam === "3" && (
        <Plan setCurrentStep={setCurrentStep} selectedCard={selectedCard} />
      )}
      {hashParam === "4" && (
        <Organization
          setCurrentStep={setCurrentStep}
          selectedCard={selectedCard}
        />
      )}
      {hashParam === "5" && <ClientsAccount setCurrentStep={setCurrentStep} />}
    </Styled.Container>
  );
};
