import * as Styled from "./styles";

interface DropdownProps {
  title?: string;
  handleChange?: any;
  value?: string;
  companies: { name: string; id: string }[];
}

export const DropdownCompanies = ({
  title,
  handleChange,
  value,
  companies,
}: DropdownProps) => (
  <Styled.Container>
    <Styled.TextForm>{title}</Styled.TextForm>
    <Styled.Dropdown
      displayEmpty
      defaultValue=""
      inputProps={{ "aria-label": "Without label" }}
      MenuProps={{
        MenuListProps: { sx: { paddingTop: 0, paddingBottom: 0 } },
      }}
      onChange={handleChange}
      value={value}
    >
      {companies.map(({ name, id }) => (
        <Styled.MenuItemStyle className="organization" value={id}>
          <Styled.Option>
            <Styled.Text>{name}</Styled.Text>
          </Styled.Option>
        </Styled.MenuItemStyle>
      ))}
    </Styled.Dropdown>
  </Styled.Container>
);
