import { IDXUserData } from "redux/types";

export const quantityLicenses = (dxUserData: IDXUserData, account?) => {
  console.log({ dxUserData, account })
  if (dxUserData.accounts) {
    const licenseMaxCompanies = dxUserData.accounts[0].license.max_companies;
    const companiesNumbers = dxUserData.accounts[0].companies ? dxUserData.accounts[0].companies.length : 0;
    return licenseMaxCompanies - (companiesNumbers > licenseMaxCompanies ? 0 : companiesNumbers);
  } else if (account) {
    const licenseMaxCompanies = account.license.max_companies;
    const companiesNumbers = account.companies ? account.companies.length : 0;
    return licenseMaxCompanies - (companiesNumbers > licenseMaxCompanies ? 0 : companiesNumbers);
  }
  return 0;
}

export const quantityLicensesUsers = (dxUserData: IDXUserData, account?) => {
  console.log({ dxUserData, account })

  if (dxUserData?.created_at) {
    if (account) {
      const accountMaxUsers = account.license.max_users
      const licensesUsed = account.license.used_licenses

      return accountMaxUsers - licensesUsed
    } else {

      const accountMaxUsers = dxUserData.accounts[0].license.max_users
      const licensesUsed = dxUserData.accounts[0].license.used_licenses

      return accountMaxUsers - licensesUsed
    }
  }

  return 0;
}

export const quantityLicensesCompanies = (dxUserData: IDXUserData, account?) => {
  console.log({ dxUserData, account })

  if (dxUserData?.created_at) {
    if (account) {
      const accountMaxCompanies = account.license.max_companies
      const companiesNumbers = account.companies ? account.companies.length : 0

      return accountMaxCompanies - (companiesNumbers > accountMaxCompanies ? 0 : companiesNumbers)
    } else {
      const maxCompanies = dxUserData.accounts[0].license.max_companies
      const numberOfCompanies = dxUserData.accounts[0].companies.length

      return maxCompanies - numberOfCompanies
    }
  }

  return 0
}

export const quantityMaxCompanies = (dxUserData: IDXUserData, account?) => {
  console.log({ dxUserData, account })

  if (dxUserData.accounts) {
    return dxUserData.accounts[0].license.max_companies;
  } else if (account) {
    return account.license.max_companies;
  }
  return 0;
}

export const quantityMaxUsers = (dxUserData: IDXUserData, account?) => {
  console.log({ dxUserData, account })

  if (dxUserData.accounts) {
    return dxUserData.accounts[0].license.max_users;
  } else if (account) {
    return account.license.max_users;
  }
  return 0;
}