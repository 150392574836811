import { useTranslation } from "react-i18next";

import { Files } from "features/common/components/Files/Files";
import { StepTitle } from "features/common/components/StepTitle/StepTitle";
import { common } from "theme";
import { step3Information } from "../Step3/utils";

import { FilesContainer } from "../Step1/Step1.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export const FilesStep3 = () => {
  const { t } = useTranslation();
  const disabledType3 = false


  return (
    <>
      <StepTitle
        title={t("template-Step3.1-title")}
        number={"Step 1"}
        color={common.colors.green2}
      />

      <FilesContainer className={`step3 ${disabledType3 ? 'disabledType3' : null}`}>
        {step3Information.map(({ text, iconName, url }) => (
            <Files
              title={text}
              color={common.colors.green50}
              iconDownload="downloadGreen"
              icon={iconName}
              url={url}
              disabled={
                text === 'Amortization & Depreciation' ? true : false}
            />
        ))}
      </FilesContainer>
      <StepTitle
        title={t("template-Step3.2-title")}
        number={"Step 2"}
        color={common.colors.green2}
      />
    </>
  );
};