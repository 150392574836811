import { LinearProgress } from "@mui/material";
import styled from "styled-components";

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const ContainerCard = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Text = styled.div`
  color: var(--gray-900, #101828);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const TextSubtitle = styled.div`
  color: var(--dark-blue-100, #061e46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.bold {
    color: var(--gray-900, #101828);
    font-weight: 500;
  }

  &.blue {
    color: #235fdb;
    font-family: Inter;
    font-weight: 600;
    cursor: pointer;
  }

  &.italic {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &.plan {
    gap: 3px;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--gray-200, #eaecf0);

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const CardText = styled.div`
  color: var(--gray-700, #344054);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &.plan {
    color: var(--dark-blue-100, #061e46);
    font-weight: 500;
    line-height: 22px;
  }

  &.card {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &.planSubtitle {
    color: var(--dark-blue-80, #3a4468);
    font-family: Hind;
    font-size: 14px;
    line-height: 20px;
  }

  &.expiration {
    color: var(--gray-600, #475467);
    font-size: 14px;
    line-height: 20px;
  }

  &.number {
    color: var(--primary-700, #7316ec);
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: 3px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CardTextContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const LinearProgressStyle = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    border-radius: 4px;
    background: var(--gray-200, #eaecf0);
    height: 8px;
  }

  &.MuiLinearProgress-barColorPrimary {
    background-color: var(--primary-600, #9747ff);
  }
`;
