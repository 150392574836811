export const singleQBOoptions = [
  {
    name: "Single User",
    icon: "single-user-one-qbo",
    className: "startup",
  },
  {
    name: "Multi User",
    icon: "multi-user-one-qbo",
    className: "company",
  }
];

export const multipleQBOoptions = [
  {
    name: "Single User",
    icon: "single-user-multi-qbo",
    className: "accountant",
  },
  {
    name: "Multi User",
    icon: "multi-user-multi-qbo",
    className: "firm",
  },
];
