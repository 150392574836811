import { DataGridTable } from "./DXTable.styles";
import { CustomCheckbox } from "./CustomCheckbox";

interface TableProps {
  checkboxField: boolean;
  rows: any;
  columns: any;
  className?: string;
}

export default function DxTable({
  checkboxField,
  rows,
  columns,
  className,
}: TableProps) {
  return (
    <div style={{ width: "100%" }}>
      <DataGridTable
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        components={{
          BaseCheckbox: CustomCheckbox,
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        checkboxSelection={checkboxField}
        className={className}
      />
    </div>
  );
}
