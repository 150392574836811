import { useEffect, useState } from "react";
import { getData } from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "./rbac";
import { useHistory } from 'react-router-dom';

export const useFetchCurrentCompany = async (page: string) => {
    const dispatch = useDispatch();
    const { currentCompany } = useSelector((state: RootState) => state);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(`Getting profile information from ${page}`);
                const data = await getData("/memberships/user/profile");
                if (data) {
                    console.log("Setting current company");
                    dispatch(setDXUserData(data));
                    let account, company, rbac;
                    if (currentCompany?.account && currentCompany?.company) {
                        console.log("Setting current company for Template Library page");
                        account = data.accounts.find(acc => acc?.id === currentCompany?.account?.id);
                        company = account.companies.find(comp => comp?.id === currentCompany?.company?.id);
                    } else {
                        account = data.accounts[0];
                        company = account.companies[0];
                    }
                    rbac = data.rbac;
                    dispatch(setCurrentCompany({ account, company, rbac }));
                }
            } catch (error) {
                console.error("Failed to fetch profile information", error);
                setError(error);
            }
        }
        fetchData();
    }, []);
    const useSetPermissions = async () => {
        const history = useHistory();
        if (currentCompany.account && currentCompany.company) {
            const compRole = currentCompany.company?.role;
            const orgRole = currentCompany.account?.role;
            const rbac = currentCompany.rbac;
            const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);
            console.log("permissions", permissions);
            if (!permissions || permissions.main_dashboard.side_bar.template_library === 'disable') {
                history.push("/settings");
            }
            return permissions
        }
    }
        await useSetPermissions();
    };

