import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-narrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-narrow-right.svg";
import { ReactComponent as Search } from "../../../../assets/icons/search-lg.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { ReactComponent as PlusGreen } from "../../../../assets/icons/plusGreen.svg";
import { ReactComponent as PlusPurple } from "../../../../assets/icons/plusPurple.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
import { ReactComponent as ChevronUp } from "../../../../assets/icons/chevron-up.svg";
import { ReactComponent as UserPurple } from "../../../../assets/icons/userPurple.svg";
import { ReactComponent as ConnectQuickbook } from "../../../../assets/icons/connectQuickbook.svg";
import { ReactComponent as LogoutQuickbook } from "../../../../assets/icons/log-out.svg";

const customIcons = {
  ArrowLeft,
  ArrowRight,
  Search,
  Plus,
  Edit,
  Trash,
  PlusGreen,
  ChevronUp,
  UserPurple,
  ConnectQuickbook,
  LogoutQuickbook,
  PlusPurple
};

export default customIcons;
