import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { Icon } from "../Icon";

export const Container = muiStyled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const Text = muiStyled(Box)`
  color: #1d2939;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const StepContainer = muiStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.06),
    0px 2px 2px 0px rgba(16, 24, 40, 0.03);
`;

export interface StepProps {
  color: string;
}

export const StepIcon = styled.div<StepProps>`
  display: flex;
  width: 48px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px 30px 0px 12px;
  background: ${({ color }) => color};
`;

export const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.06),
    0px 2px 2px 0px rgba(16, 24, 40, 0.03);
  height: 50px;
  max-width: 500px;
  min-width: 280px;

  :hover {
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 0px 24px -4px rgba(16, 24, 40, 0.15);
    cursor: pointer;
  }
`;

export const TextInformation = muiStyled(Box)`
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const ButtonContainer = muiStyled(Box)`
  margin-right: 24px;
`;

export const Subtitle = muiStyled(Box)`
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const ContainerText = muiStyled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContainerInfo = muiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const IconDownload = styled(Icon)``;

export const IconContainer = muiStyled(Box)`
  padding-right: 24px;
`;

export const IconFile = styled(Icon)``;

export const IconHover = styled.button<StepProps>`
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  background: transparent;
  border: none;

  :hover {
    background: ${({ color }) => color};
  }
  &.show {
    background: ${({ color }) => color};
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;