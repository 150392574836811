import Amplify from "aws-amplify";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
  ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID as string,
};

let cognitoUserSaved: CognitoUser;

export const getUser = (): CognitoUser => cognitoUserSaved;
export const setUser = (newUser: CognitoUser): void => {
  cognitoUserSaved = newUser;
};

export const getCognitoUser = (
  cognitoSession: string,
  cognitoUsername: string,
  email: string
): CognitoUser => {
  const userData = {
    Username: email,
    Pool: new CognitoUserPool(poolData),
  };
  const cognitoUser = new CognitoUser(userData) as any;
  cognitoUser.Session = cognitoSession;
  cognitoUser.username = cognitoUsername;
  return cognitoUser;
};
