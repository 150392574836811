import { FC, useEffect } from "react";

import { Sidebar } from "./Sidebar/Sidebar";
import { Topbar } from "./Topbar/Topbar";
import { Footer } from "../Footer/Footer";

import { Container, ContainerFooter, MainPanel } from "./MainLayout.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import useCurrentUser from "hooks/useCurrentUser";
import { Auth } from "aws-amplify";
import { setCredentials } from "redux/actions";

const MainLayout: FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser()
        .then((response) => {
          dispatch(
            setCredentials({
              accessToken: response.signInUserSession.accessToken.jwtToken,
              idToken: response.signInUserSession.idToken.jwtToken,
              refreshToken: response.signInUserSession.refreshToken.token,
            })
          );
        })
        .catch((error) => {
          window.location.href = "/sign-in";
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Container>
      <Sidebar />
      <Topbar />
      <MainPanel>{children}</MainPanel>
      <ContainerFooter>
        <Footer />
      </ContainerFooter>
    </Container>
  );
};

export { MainLayout };
