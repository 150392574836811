import { Dispatch, SetStateAction, useState } from "react";
import { Box } from "@mui/material";
import { Auth } from "aws-amplify";

import DXButton from "features/common/components/DXButton";
import DXInputField from "features/common/components/DXInputField";
import DXModal from "features/common/components/DXModal/DXModal";
import DXAlert from "features/common/components/DXAlert/DXAlert";
import { WrapperAlert } from "./Profile.styles";
import { post } from "services/api";

export interface PasswordModalProps {
  isPasswordOpen: boolean;
  setIsPassword: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
}
export const ChangePasswordModal = ({
  isPasswordOpen,
  setIsPassword,
  setAlertMessage,
}: PasswordModalProps) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isError, setError] = useState("");

  const isButtonDisabled = () => {
    if (newPassword !== confirmNewPassword || newPassword === "") return true;
    return false;
  };

  const handleChangePassword = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      await post("memberships/user-change-password", {});
      setAlertMessage("Password Updated");
      setIsPassword(false);
    } catch (error) {
      setError(error.message);
      console.log("error changing password:", error);
    }
  };

  return (
    <DXModal
      title={"Change Password"}
      open={isPasswordOpen}
      onClose={() => setIsPassword(false)}
      children={
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <DXInputField
              placeholder="Current Password"
              label="Current Password"
              type="password"
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <DXInputField
              placeholder="New Password"
              label="New Password"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <DXInputField
              placeholder="Confirm New Password"
              label=" Confirm New Password"
              type="password"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingTop: 4,
            }}
          >
            {isError && (
              <WrapperAlert className="password">
                <DXAlert
                  children={isError}
                  color="error"
                  sx={{ width: "100%" }}
                  onClose={() => {
                    setError("");
                  }}
                />
              </WrapperAlert>
            )}

            <DXButton
              variant="contained"
              disabled={isButtonDisabled()}
              onClick={handleChangePassword}
              sx={{ minWidth: "140px" }}
            >
              Save Changes
            </DXButton>
          </Box>
        </>
      }
    />
  );
};
