import { useTranslation } from "react-i18next";
import { Report } from "features/common/components/Report/Report";
import DXButton from "features/common/components/DXButton";
import { Link } from "react-router-dom";

import {
  ContainerActivity,
  ContainerReports,
  StepContainerColumn,
} from "./Steps.styles";
import { TextInformation } from "../UploadFiles.styles";
import { ILogData } from "../types";
import { Typography } from "@mui/material";
import DXAlert from "features/common/components/DXAlert";
import toast from "react-hot-toast";


export const ActivityLog: React.FC<{ logData: ILogData[], signedUrl: string }> = ({ logData, signedUrl }) => {
  const { t } = useTranslation();

  const handleDownloadClick = (url: string) => {
    try {
      window.location.href = url;
    } catch (error) {
      toast.error("Error downloading upload history. Please try again.");
      console.error(error);
    };
  }
  
  return (
    <StepContainerColumn>
      <ContainerActivity>
        <TextInformation>{t("activity-log")}</TextInformation>
        {/* add icon */}
        <DXAlert
          sx={{
            fontSize: "15px",
            color: 'black',
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "0px",
            paddingBottom: "0px",
            maxWidth: "80%",
            textAlign: "justify"
          }}
          children={
            <>
              <strong>Error Resolution in Beta</strong>: Errors are flagged in the final column of your report. Please resolve in that exact file and upload into Step 2.
            </>
          }
          color="success"
          severity="info"
          icon={false}
        />
        <DXButton
          children={"Export Upload History"}
          color={"primary"}
          size={"medium"}
          onClick={() => handleDownloadClick(signedUrl)}
          disabled={logData.length === 0}
        />
      </ContainerActivity>
      {/* TODO: connect to backend - add pagination -add scroll */}
      <ContainerReports>
        {logData?.length > 0 &&
          logData.map((log) => {
            return (
              <Report
                key={log.job_id}
                isSuccess={log.file_status === "SUCCESS"}
                isPartialSuccess={log.file_status === "PARTIAL_UPDATE_NO_ERROR"}
                isTemplateSuccess={log.file_status === "TEMPLATE_SUCCESS"}
                text={`${log.file_name} (4MB)`}
                file_created_at={new Date(log.file_created_at).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                status={log.file_status}
                report={log.report}
                message={
                  log.file_status === "SUCCESS"
                    ? "File successfully uploaded."
                    : log.file_status === "TEMPLATE_FAILURE"
                      ? "Processing and validating error, please verify the file."
                      : log.file_status === "TEMPLATE_SUCCESS"
                        ? "Template recognized and is being processed"
                        : log.file_status === "TEMPLATE_MORE_THAN_ONE" ||
                          log.file_status === "TEMPLATE_MISSING"
                          ? <>Template not detected. Please <Link to={'templateLibrary'}>fill out</Link> and upload.</>
                          : log.file_status === "PENDING"
                            ? "Pending"
                            : log.file_status === "PARTIAL_SUCCESS"
                              ? "The file was partially uploaded"
                              : log.file_status === "PARTIAL_UPDATE_NO_ERROR"
                                ? "The file was partially uploaded"
                                : log.file_status === "TEMPLATE_ERROR"
                                  ? "Error"
                                  : log.file_status === "EMPTY_TEMPLATE"
                                    ? <>Template is empty. Please <Link to={'templateLibrary'}>fill out</Link> and re-upload.</>
                                    : log.file_status === "ERROR"
                                      ? "Error while sending to quickbooks, please verify the report."
                                      : ""
                }
              />
            );
          })}

        {logData.length === 0 && (
          <Typography sx={{ color: "black" }}>No Activity Log</Typography>
        )}
      </ContainerReports>
    </StepContainerColumn>
  );
};
