import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Modal } from "features/common/components/Modal/Modal";
import { InputField } from "../../../InputField/InputField";
import { inputFields } from "./utils";
import { ModalProps } from "../types";
import DXButton from "features/common/components/DXButton";
import { DropdownUser } from "../ModalAddUser/DropdownUser";

import {
  ContainerFields,
  Body,
  Wrapper,
  Container,
} from "../ModalAddUser/ModalAddUser.styles";

export default function ModalEditUserForm({
  users,
  setUsers,
  closeModal,
  userIndex,
}: ModalProps) {
  const [editUser, setEditUser] = useState([users[userIndex]]);
  const { t } = useTranslation();

  const handleInputChange = (index, fieldId, value) => {
    const newUsers = [...editUser];
    newUsers[index][fieldId] = value;
    setEditUser(newUsers);
  };

  const handleEdit = () => {
    const updatedUsers = [...users];
    updatedUsers[userIndex] = editUser[0];
    setUsers(updatedUsers);
    closeModal();
  };

  return (
    <>
      <Modal.Body>
        <Wrapper>
          {editUser.map((user, index) => (
            <Body key={index}>
              {inputFields.map((field) => (
                <ContainerFields key={field.id}>
                  <InputField
                    label={field.label}
                    id={`${field.id}-${index}`}
                    type={field.type}
                    required={field.required}
                    placeholder={field.placeholder}
                    value={user[field.id]}
                    onChange={(event) =>
                      handleInputChange(index, field.id, event.target.value)
                    }
                  />
                </ContainerFields>
              ))}
              <ContainerFields>
                <DropdownUser
                  title={t("user-type")}
                  value={user.role}
                  handleChange={(event) =>
                    handleInputChange(index, "role", event.target.value)
                  }
                />
              </ContainerFields>
            </Body>
          ))}
        </Wrapper>
      </Modal.Body>
      <Container>
        <DXButton type="button" children="Edit" onClick={handleEdit} />
      </Container>
    </>
  );
}
