import { MenuItem, Select } from "@mui/material";
import styled from "styled-components";

import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Dropdown = styled(Select)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  min-height: unset;
  height: 38px;

  &.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-width: 0px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: transparent;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: 0px 0px 0px 4px #e2ccfd, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .MuiSelect-placeholder {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .MuiSelect-select {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #344054;
  }
`;

export const Container = muiStyled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
`;

export const TextForm = muiStyled(Box)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const MenuItemStyle = styled(MenuItem)`
  display: flex;
  padding: 10px 10px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  flex: 1 0 0;

  &.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    align-items: flex-start;
    padding: 10px 10px 10px 8px;
  }

  &.css-9iuan1-MuiButtonBase-root-MuiMenuItem-root {
    align-items: flex-start;
    padding: 10px 10px 10px 8px;
  }

  &.css-9iuan1-MuiButtonBase-root-MuiMenuItem-root {
    align-items: flex-start;
    padding: 10px 10px 10px 8px;
  }

  &.Mui-selected {
    background: #f2f4f7;
    border-radius: 6px;
    margin: 6px;
  }

  &.MuiMenuItem-root {
    :hover {
      border-radius: 6px;
      background: var(--gray-100, #f2f4f7);
      margin: 6px;
    }
  }

  &.organization {
    display: flex;
    padding: 10px 10px 10px 8px;
    align-items: flex-start;
    gap: 8px;
    margin: 6px;
  }
`;

export const Option = muiStyled(Box)`
  width: 100%;
`;

export const Text = muiStyled(Box)`
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  &.subtitle {
    font-size: 12px;
    line-height: 16px;
    align-items: right;
  }

  &.italic {
    font-style: italic;
    color: #475467;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  &.information {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-wrap: wrap;
  }

  &.qboRequired {
    font-style: italic;
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-wrap: wrap;
  }
`;

export const Row = muiStyled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  &.account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  &.text {
    gap: 3px;
  }

  &.dropdownOption {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    }

  &.editUser {
  justify-content: space-between;
  }
`;

export const LeftGroup = muiStyled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Column = muiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LabelContainer = muiStyled(Box)`
  display: flex;
  padding: 3px 3px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;

  &.full {
    border: 1px solid #c3fae5;
    background: #ecfff8;
  }

  &.limited {
    border: 1px solid #fedf89;
    background: #fffaeb;
  }

  &.noAccess {
    border: 1px solid #fecdca;
    background: #fef3f2;
  }
`;

export const Divider = muiStyled(Box)`
  background: #eaecf0;
  height: 1px;
`;