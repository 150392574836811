import React from "react";
import { useHistory } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { Breakpoint } from "@mui/material/styles";
import { Box } from "@mui/material";
import { minWidth } from '@mui/system';

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: any;
  setModalSize: any;
  size: string;
  className?: string;
}

const DialogModalTitle = (props: DialogTitleProps) => {
  const { children, onClose, setModalSize, size, className, ...other } = props;

  const history = useHistory();

  const handleClose = () => {
    onClose();
    const updatedURL = `${window.location.pathname}${window.location.hash}`;
    history.replace(updatedURL);
  };

  return (
    <DialogTitle sx={{ m: 0, pt: 8, pb: 1 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: (theme) => theme.palette.grey[700],
            "&.MuiIconButton-root": {
              "&:hover": {
                borderRadius: "20%",
              },
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Box sx={{
        textAlign: "center",
        fontSize: className === 'clientAccount' ? "30px" : "20px",
        fontWeight: "600",
        lineHeight: className === 'clientAccount' ? "38px" : null,
      }}>
        {children}
      </Box>
    </DialogTitle>
  );
};

interface IDialogModal extends DialogProps {
  title: string;
  contentPadding?: number;
  children?: any;
  customWidth?: number;
  backgroundColor?: string;
  isSignUpModal?: boolean;
  className?: string;
}

const DXModal = ({
  title,
  contentPadding,
  onClose,
  children,
  customWidth,
  backgroundColor,
  isSignUpModal,
  className,
  ...props
}: IDialogModal) => {
  const [modalSize, setModalSize] = React.useState<Breakpoint>("sm");
  return (
    <Dialog
      fullWidth={true}
      maxWidth={customWidth ? false : props.maxWidth || modalSize}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          minWidth: className === 'clientAccount' ? '616px' : null,
          minHeight: className === 'clientAccount' ? '376px' : null,
          backgroundColor: backgroundColor,
          width: customWidth,
        },
      }}
      {...props}
    >
      <Box>
        <DialogModalTitle
          onClose={onClose}
          setModalSize={setModalSize}
          size={modalSize}
          className={className}
        >
          {title}
        </DialogModalTitle>
      </Box>
      <DialogContent
        sx={{
          p: 2,
          paddingRight: "32px",
          paddingLeft: "32px",
          paddingBottom: isSignUpModal ? "8px" : "32px",
        }}
      >
        {React.Children.count(children) > 1 ? children[0] : children}
      </DialogContent>
      {React.Children.count(children) > 1 && (
        <DialogActions>{children[1]}</DialogActions>
      )}
    </Dialog>
  );
};

export default DXModal;
