import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { Icon } from "../Icon";

export const ReportContainer = muiStyled(Box)`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  justify-content: space-between;
  border: 1px solid #fda29b;
  background: #fffbfa;

  &.isSuccess {
    border: 1px solid #6ce9a6;
    background: #f6fef9;
  }
  &.isTemplateSuccess {
    border: 1px solid #6ce9a6;
    background: #f6fef9;
  }
`;

export const ContainerLeft = muiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const IconFile = styled(Icon)`
  width: 42.656px;
  height: 56px;
`;

export const Text = muiStyled(Box)`
  color: #475467;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const ButtonQuickbook = styled.button`
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #d1fadf;
  background: #ecfdf3;
  color: #027a48;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
`;

export const ContainerRight = muiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 260px;
`;

export const ButtonContainer = muiStyled(Box)``;
