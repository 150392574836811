import DXButton from "features/common/components/DXButton";
import DXInputField from "features/common/components/DXInputField/DXInputField";
import useCurrentUser from "hooks/useCurrentUser";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { getData, post } from "services/api";
import { quantityLicensesCompanies } from "utils/quantityLicensesAndUsers";
import * as Styled from "./Form.styles";
import { Box, FormControlLabel, Radio } from "@mui/material";
import DXModal from "features/common/components/DXModal";
import { Input, QBStep } from "./Form.styles";
import DXIcon from "features/common/components/DXIcon";
import QBButton from "features/common/components/QBButton";
import useConnectQuickbooks from "hooks/useConnectQuickbooks";
import QBLogoutButton from "features/common/components/QBButton/QBLogoutButton";
import { RootState } from "redux/reducers";

export const Form = ({ onClose }) => {
  const { currentCompany } = useSelector((state: RootState) => state);
  const { dxUserData, userData } = useCurrentUser();
  const [companyId, setCompanyId] = useState("")
  const { QBConnect } = useConnectQuickbooks();
  const dispatch = useDispatch();
  const [isCheckedAccounting, setIsChecked] = useState(false);
  const [isOpenModalQB, setIsOpenModalQB] = useState(false);
  const [lincensesLeft, setLincensesLeft] = useState<number>();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    account_id: dxUserData.accounts?.[0].id || "",
    logo: "",
    color: "",
  });

  const params = new URLSearchParams(window.location.search);
  const isReconnect = params.get("connected") === "false";

  useEffect(() => {
    setLincensesLeft(quantityLicensesCompanies(dxUserData, currentCompany?.account))
  }, [dxUserData])

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      account_id: currentCompany?.account.id,
    }));
  }, [dxUserData]);

  const handleChange = (name: string, value: string) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleFile = async (file: File) => {
  //   try {
  //     const response = await post("/files/company/upload-logo", {
  //       original_filename: file.name,
  //       metadata: {},
  //       origin: "web",
  //       content_type: file.type,
  //       membership_account_id: localStorage.getItem("account_id"),
  //     });

  //     if (response.uploadUrl) {
  //       await axios.put(response.uploadUrl, file, {
  //         headers: {
  //           "Content-Type": file.type,
  //         },
  //       });

  //       setPayload((prev) => ({
  //         ...prev,
  //         logo: process.env.REACT_APP_S3_AMAZONAWS + response.location,
  //       }));
  //     } else {
  //       console.error("Failed to upload file");
  //     }
  //   } catch (error) {
  //     console.error("Failed to upload file", error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const response = await post("/memberships/company", {
        ...payload,
      });

      console.log(response)

      setCompanyId(response.id)

      setIsOpenModalQB(true)

      if (response.id) {
        const resp = await getData(`/memberships/user/profile`);
        dispatch(setDXUserData(resp));
        const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
        const rbac = resp.rbac;

        if (currentCompany?.company) {
          const company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
          dispatch(setCurrentCompany({ account, company, rbac }))
        } else {
          const company = resp.accounts[0].companies[0]
          dispatch(setCurrentCompany({ account, company, rbac }))
        }
        setLincensesLeft((state) => state - 1);
        toast.success("Company created successfully!");

        // setTimeout(() => window.location.href = "/settings?tabValue=1")
        // onClose(false);
      }
    } catch (error) {
      console.error("Failed to create client account");
    }
  };

  // const handleUpload = (dataTransfer) => {
  //   const files = dataTransfer.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     handleFile(file);
  //   }
  // };

  return (
    <Styled.FormContainer>
      <Styled.StyledForm>
        <Styled.ColumnContainer>
          <DXInputField
            label={"Company Name*"}
            id={"name"}
            placeholder={"Enter company name"}
            value={payload.name || ""}
            onChange={({ target }) => handleChange(target.id, target.value)}
          />
        </Styled.ColumnContainer>

        <DXModal
          title="Add a QuickBooks Account"
          open={isOpenModalQB}
          onClose={() => setIsOpenModalQB(!isOpenModalQB)}
          customWidth={700}
        >

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
            width: "100%",
            paddingTop: "32px",
          }}>

            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              width: "100%",
            }}>

              <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
                <QBStep>Step 1</QBStep>
              </Box>

              <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}>

                <Box sx={{ width: "220px" }}>
                  <QBLogoutButton
                    children={"Confirm you are logged out"}
                    onClick={() => window.open("https://accounts.intuit.com/", '_blank')}
                    sx={{ width: "220px", fontSize: "13px", backgroundColor: "#6938EF" }}
                  />
                </Box>

                <Input style={{ textAlign: "justify", marginTop: "20px" }}>
                  Please click the button above to verify. If an existing QuickBooks workspace opens,  please sign out of that workspace before continuing to Step 2 on this screen. If instead you are prompted to sign in to QuickBooks, this confirms you are already successfully logged out and can proceed to Step 2. Please wait to sign in until Step 3 as you need to verify the type of account first.
                </Input>
              </Box>
            </Box>

            <Box sx={{
              display: "flex",
              gap: 2,
            }}>

              <Box sx={{ minWidth: "60px" }}>
                <QBStep>Step 2</QBStep>
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}>

                <Box sx={{ fontWeight: "bold" }}>
                  <Input>Select type of account</Input>
                </Box>

                <Box>
                  <Input style={{ color: "#6938EF", margin: 0, cursor: "pointer" }}
                    onClick={() => setOpenInfoModal(true)}
                  >
                    *How do I know if I have access to the QuickBooks Online Payments module? </Input>
                </Box>

                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                }}>
                  <FormControlLabel
                    sx={{ width: "50%", margin: 0, }}
                    control={<Radio />}
                    label="QuickBooks Online only"
                    onChange={() => setIsChecked(false)}
                    checked={!isCheckedAccounting}
                  />

                  <FormControlLabel
                    sx={{ width: "50%", margin: 0, fontFamily: "Avenir Next" }}
                    control={<Radio />}
                    label={"QuickBooks Online and \n QuickBooks Online Payments"}
                    onChange={() => setIsChecked(true)}
                    checked={isCheckedAccounting}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{
              display: "flex",
              gap: 2,
            }}>

              <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
                <QBStep>Step 3</QBStep>
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "column",
              }}>

                <Box sx={{ width: "220px" }}>
                  <QBButton
                    children={isReconnect ? "Reconnect" : "Connect to QuickBooks"}
                    endIcon={<DXIcon name="ConnectQuickbook" />}
                    onClick={() => QBConnect(dxUserData.accounts[0].id, userData.sub, isCheckedAccounting, companyId, true)}
                    sx={{ width: "220px", fontSize: "13px", }}

                  />
                </Box>

                <Input style={{ marginTop: "20px" }}>Once connected, you can seamlessly toggle across workstreams directly in DatumXpress.</Input>
              </Box>
            </Box>
          </Box>

        </DXModal>

        <DXModal title={""} open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
          <Input>The QuickBooks Online Payments module is an add-on to standard QuickBooks Online plans that enables features related to the remittance of payments. To upgrade your QuickBooks plan to include payments please <a href="https://quickbooks.intuit.com/money/see-plans/" target="_blank">click here</a>.<br /><br />

            To confirm if your account has Payments' access, please check your product subscription tab or Billing tab within QuickBooks which will indicate "QuickBooks Online Payments."<br /><br />

            Within DatumXpress, this module allows you to access templates associates with Bill Payments, Credit Card Charges, and Transfers which are otherwise disabled. </Input>
        </DXModal>

        <DXButton
          variant="contained"
          color="info"
          size="large"
          sx={{ maxWidth: "220px", margin: "0 auto" }}
          onClick={() => handleSubmit()}
          disabled={lincensesLeft <= 0 || !payload.name}
        >
          Create company
        </DXButton>
      </Styled.StyledForm>
    </Styled.FormContainer>
  );
};
