import styled from "styled-components";

export const StepContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.06),
    0px 2px 2px 0px rgba(16, 24, 40, 0.03);
`;

export const QBStep = styled.div`
  color: #6938EF;
  font-family: Inter;
  background-color: rgb(240, 235, 253) !important;
  border: 1px solid rgba(200, 200, 200, 0.20);
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 4px 8px;
`;

export const Title = styled.div`
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0 auto;
`;

export const DragAndDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  padding: 0px 32px 0px 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 32px;
`;

export const ButtonQuickbooks = styled.button`
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-family: Avenir Next;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const ContainerActivity = styled.div`
  display: flex;
  padding: 32px 32px 0px 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ContainerReports = styled.div`
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 32px;
  max-height: 1000px;
  overflow: auto;
`;

export const Input = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.spacing {
    margin-right: 3px;
  }

  &.bold {
    font-weight: 600;
  }

  &.license {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-weight: 600;
  }

  &.email {
    @media screen and (max-width: 730px) {
      width: 109px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
