export const removeDuplicatedAddedCompanies = (arr) => {
    let result = [];
    let present = {};
    let order = {
        'FINANCIAL': 0,
        'USER': 1,
        'ADMIN': 2
    }
    arr.forEach((item) => {
        if (!present[item.company] || order[item.role] < order[present[item.company].role]) {
            present[item.company] = item;
        }
    });
    for (let key in present) {
        result.push(present[key]);
    }
    return result;
}
