import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const Row = styled.div<{ gap?: string }>`
  display: flex;
  gap: ${({ gap }) => gap};

  &.space-between {
    justify-content: space-between;
    width: 100%;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
`;

export const Column = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ gap }) => gap};
`;
