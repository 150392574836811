import { StepProps } from "./types";

import { FileTitle, IconStep, Title } from "./StepTitle.styles";

export const StepTitle = ({ title, number, color, colorTitle }: StepProps) => {
  return (
    <FileTitle>
      <IconStep color={color} colorTitle={colorTitle}>
        {number}
      </IconStep>
      <Title>{title}</Title>
    </FileTitle>
  );
};
