import React from "react";

import { Header } from "features/Header";
import { Wrapper } from "features/common/components/Wrapper";
import { Steps } from "features/Steps";

import GlobalStyle from "styles/globalStyles";

const SignUp: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Header />
      <Steps />
    </Wrapper>
  );
};
export default SignUp;
