import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.06),
    0px 2px 2px 0px rgba(16, 24, 40, 0.03);
  flex-direction: column;
`;

export const StepIcon = styled.div`
  display: flex;
  width: 92px;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px 40px 40px 8px;
  background: ${({ theme }) => theme.colors.purple600};
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TextInformation = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const ButtonContainer = styled.div`
  margin-right: 24px;
`;
