import React from "react";

import { Wrapper } from "features/common/components/Wrapper";
import { Settings } from "features/Settings/Settings";

import GlobalStyle from "styles/globalStyles";

const SettingsPage: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Settings />
    </Wrapper>
  );
};
export default SettingsPage;
