import api from "services/api";

export const createOrganization = async ({
  name,
  organization_size,
  description,
  logo,
  color,
}: {
  name: string;
  organization_size: string;
  description: string;
  logo: any;
  color: string;
}) => {
  try {
    const response = await api.post("/memberships/account", {
      name,
      organization_size,
      description,
      logo,
      color,
    });

    return response;
  } catch (error) {
    console.error("Error in createOrganization:", error);
  }
};
export const createUser = ({
  email,
  name,
  account_role,
  account_id,
  companies,
}: {
  email: string;
  name: string;
  account_role: string;
  account_id: string;
  companies: [];
}) =>
  api.post("memberships/user", {
    email,
    account_role,
    name,
    account_id,
    companies,
  });
