import { Box } from "@mui/system";
import DXModal from "../DXModal";
import * as Styled from "./QBLogoutModal.styles"
import DXButton from "../DXButton";
import { TextModal } from "features/Steps/Steps/ClientsAccount/ClientAccount.styles";
import DXIcon from "../DXIcon";


export const QBLogoutModal = ({ openModal, onClose, logoutConfirmed, className }) => {
    return (
        <DXModal
            title="Confirm You are Logged Out"
            onClose={onClose}
            open={openModal}
            className={"clientAccount"}
            isSignUpModal={true}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    py: 4,
                    width: "100%",
                    gap: 4,
                    paddingTop: '80px',
                    paddingBottom: 0
                }}
                style={{ padding: "0px 32px" }}
            >
                <Styled.ContainerBottom>
                    <DXButton
                        style={{
                            backgroundColor: "#6938EF",
                            fontSize: className === 'clientAccount' ? "16px" : null,
                            width: className === 'clientAccount' ? "100%" : null,
                            textAlign: className === 'clientAccount' ? "center" : null,
                        }}
                        variant="contained"
                        color="info"
                        size="large"
                        sx={{ maxWidth: "212px", width: "100%" }}
                        onClick={() => window.open("https://accounts.intuit.com/", '_blank')}
                    >
                        Click Here To Confirm
                    </DXButton>
                </Styled.ContainerBottom>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    alignItems: 'center',
                }}>
                <TextModal
                    className={"clientAccount"}>If an existing QuickBooks workspace opens, please log out of that session before adding a new account. If you are prompted to sign-in, you are already logged out and can continue.
                </TextModal>
                <DXButton
                    variant="contained"
                    color="info"
                    size="large"
                    sx={{ maxWidth: "212px", padding: '10px, 18px, 10px, 18px', marginBottom: '32px' }}
                    onClick={logoutConfirmed}
                    endIcon={<DXIcon name="ArrowRight" />}
                >
                    I Am Logged Out
                </DXButton>
            </Box>
        </DXModal>
    )
};