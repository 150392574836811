import { useTranslation } from "react-i18next";

import { Container, IconLock, Text } from "./SecureData.styles";

export const SecureData = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <IconLock iconName="icon-lock" />
      <Text>{t("secureData")}</Text>
    </Container>
  );
};
