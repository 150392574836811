import { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";

import { deleteData } from "services/api";
import DXButton from "features/common/components/DXButton";
import DXModal from "features/common/components/DXModal/DXModal";

export interface DeleteAccountProps {
  isOpenModalDelete: boolean;
  setIsOpenModalDelete: Dispatch<SetStateAction<boolean>>;
  handleDelete?: (companyId: string) => void;
  id?: string;
  text: string;
  confirmLabel: string;
  title: string;
}

export const ModalDeleteCompany = ({
  setIsOpenModalDelete,
  isOpenModalDelete,
  handleDelete,
  id,
  text,
  confirmLabel,
  title,
}: DeleteAccountProps) => {
  return (
    <DXModal
      title={title}
      open={isOpenModalDelete}
      onClose={() => setIsOpenModalDelete(false)}
      children={
        <>
          <Box sx={{ textAlign: "center", fontSize: "20px" }}>{text}</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: 4,
            }}
          >
            <DXButton
              color="secondary"
              variant="contained"
              sx={{ minWidth: "140px" }}
              onClick={() => setIsOpenModalDelete(false)}
            >
              Cancel
            </DXButton>
            <DXButton
              variant="contained"
              sx={{ minWidth: "140px" }}
              onClick={() => handleDelete && handleDelete(id)}
              color="error"
            >
              {confirmLabel}
            </DXButton>
          </Box>
        </>
      }
    />
  );
};
