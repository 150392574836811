import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DXButton from "features/common/components/DXButton";
import DXIcon from "features/common/components/DXIcon";
import { getData, post } from "services/api";
import { handleLink } from "utils/handleLink";
import { StepProps } from "../types";
import { ModalPlan } from "./ModalPlan";

import * as StyledStep from "features/Steps/Steps.styles";
import useCurrentUser from "hooks/useCurrentUser";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setLicenses } from "redux/actions";

export const Plan = ({ setCurrentStep, selectedCard }: StepProps) => {

  const setDefaultValues = (selectedCardAccount: string) => {

    if (selectedCardAccount === "Company") {
      return {
        companyNumber: 1,
        userNumber: 2
      }
    }

    if (selectedCardAccount === "Startup") {
      return {
        companyNumber: 1,
        userNumber: 1
      }
    }

    if (selectedCardAccount === "Accountant" || selectedCardAccount === "Firm") {
      return {
        companyNumber: 2,
        userNumber: 2
      }
    }

    return {
      companyNumber: 1,
      userNumber: 1
    }
  }

  const defaultValue = setDefaultValues(selectedCard)

  const { t } = useTranslation();
  const [numUsers, setNumUsers] = useState(defaultValue.userNumber);
  const [numQuickBooks, setNumQuickBooks] = useState(defaultValue.companyNumber);
  const [selectedCardOption, setSelectedCardOption] = useState(null);
  const [plans, setPlans] = useState([
    {
      title: "Billed Monthly",
      price: 0,
      description: "Per month",
      option: "month",
    },
    {
      title: "Billed Annually",
      price: 0,
      description: "Per year",
      option: "year",
    },
  ]);
  const [companyRecurring, setCompanyRecurring] = useState({
    yearId: "",
    monthId: "",
  });
  const [userRecurring, setUserRecurring] = useState({
    yearId: "",
    monthId: "",
  });
  const { userData } = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    setNumUsers(setDefaultValues(selectedCard).userNumber)
    setNumQuickBooks(setDefaultValues(selectedCard).companyNumber)
  }, [selectedCard])

  const { data: products, isLoading } = useQuery(`getPrices`, () =>
    getData(`/sales/product-mvp`)
  );

  useEffect(() => {
    if (!isLoading) {
      products.items.map((item) => {
        if (item.type === "COMPANY" && numQuickBooks) {
          item.strip.map((strip) => {
            let companyPrice = strip.price.find((price) => {
              return (
                price.minimum <= numQuickBooks && price.maximum >= numQuickBooks
              );
            });
            const amount = companyPrice.amount * numQuickBooks;
            if (strip.recurring === "year") {
              setPlans((state) => {
                return [
                  state[0],
                  {
                    title: "Billed Annually",
                    price: amount + companyPrice.fee,
                    description: "Per year",
                    option: "year",
                  },
                ];
              });
            } else {
              setPlans((state) => {
                return [
                  {
                    title: "Billed Monthly",
                    price: amount + companyPrice.fee,
                    description: "Per month",
                    option: "month",
                  },
                  state[1],
                ];
              });
            }
          });
        }
        if (item.type === "USER" && numUsers) {
          item.strip.map((strip) => {
            let userPrice = strip.price.find((price) => {
              return price.minimum <= numUsers && price.maximum >= numUsers;
            });
            const amount = userPrice.amount * numUsers;
            if (strip.recurring === "year") {
              setPlans((state) => {
                return [
                  state[0],
                  {
                    title: "Billed Annually",
                    price: state[1].price + amount + userPrice.fee,
                    description: "Per year",
                    option: "year",
                  },
                ];
              });
            } else {
              setPlans((state) => {
                return [
                  {
                    title: "Billed Monthly",
                    price: state[0].price + amount + userPrice.fee,
                    description: "Per month",
                    option: "month",
                  },
                  state[1],
                ];
              });
            }
          });
        }
      });
    }
  }, [isLoading, numQuickBooks, numUsers]);

  useEffect(() => {
    if (!isLoading) {
      products.items.map((item) => {
        if (item.type === "COMPANY") {
          for (const strip of item.strip) {
            if (strip.recurring === "year") {
              setCompanyRecurring((state) => {
                return { ...state, yearId: strip.stripe_id };
              });
            } else {
              setCompanyRecurring((state) => {
                return { ...state, monthId: strip.stripe_id };
              });
            }
          }
        }
        if (item.type === "USER") {
          for (const strip of item.strip) {
            if (strip.recurring === "year") {
              setUserRecurring((state) => {
                return { ...state, yearId: strip.stripe_id };
              });
            } else {
              setUserRecurring((state) => {
                return { ...state, monthId: strip.stripe_id };
              });
            }
          }
        }
      });
    }
  }, [isLoading]);

  const handleContinue = async () => {
    let line_items = [];

    if (selectedCardOption === "month") {
      line_items = [
        {
          price: companyRecurring.monthId,
          quantity: numQuickBooks,
        },
        {
          price: userRecurring.monthId,
          quantity: numUsers,
        },
      ];
    } else if (selectedCardOption === "year") {
      line_items = [
        {
          price: companyRecurring.yearId,
          quantity: numQuickBooks,
        },
        {
          price: userRecurring.yearId,
          quantity: numUsers,
        },
      ];
    }

    const response = await post("sales/createCheckoutSession", {
      line_items,
      cognito_id: userData.sub,
      email: userData.email,
    });

    if (response) {
      dispatch(setLicenses({ licenses: line_items[0].quantity }));
      window.location.href = response.checkout_url;
    } else {
      // Handle error
      console.error("Failed to create checkout session");
    }
  };

  return (
    <StyledStep.PageContainer>
      <StyledStep.StepContainer>
        <StyledStep.Content>
          <StyledStep.Title>
            <StyledStep.StepTitle>{t("step-Plan-Title")}</StyledStep.StepTitle>
            <StyledStep.StepSubtitle>
              {t("step-Plan-Subtitle")}
            </StyledStep.StepSubtitle>
          </StyledStep.Title>
          <ModalPlan
            selectedCardAccount={selectedCard}
            setNumUsers={setNumUsers}
            setNumQuickBooks={setNumQuickBooks}
            selectedCardOption={selectedCardOption}
            setSelectedCardOption={setSelectedCardOption}
            products={plans}
            numQuickBooks={numQuickBooks}
            numUsers={numUsers}
          />
          <StyledStep.ButtonsContainer>
            <DXButton
              color="secondary"
              variant="contained"
              startIcon={<DXIcon name="ArrowLeft" />}
              onClick={() => {
                setCurrentStep((prevStep) => prevStep - 1);
                handleLink("#2");
              }}
            >
              {t("go-back")}
            </DXButton>
            <DXButton
              color="primary"
              variant="contained"
              disabled={!numQuickBooks || !numUsers || !selectedCardOption}
              endIcon={<DXIcon name="ArrowRight" />}
              onClick={() => {
                handleContinue();
              }}
            >
              {t("continue")}
            </DXButton>
          </StyledStep.ButtonsContainer>
        </StyledStep.Content>
      </StyledStep.StepContainer>
    </StyledStep.PageContainer>
  );
};
