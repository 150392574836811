import { useEffect } from "react";
import { Header } from "./Header";
import { Body } from "./Body";
import { ActionButtons } from "./ActionButtons";
import { ModalProps } from "./types";
import { ESCAPE_KEY } from "./constants";

import { Container, ModalContent, Overlay } from "./Modal.styles";

const Modal = ({ children, className, onClose }: ModalProps) => {
  function handKeyDown(event: { key: string }) {
    if (event.key === ESCAPE_KEY && onClose) {
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handKeyDown);
    return () => {
      document.removeEventListener("keydown", handKeyDown);
    };
  });

  return (
    <>
      <Overlay />
      <Container>
        <ModalContent role='dialog' aria-modal='true' className={className}>
          {children}
        </ModalContent>
      </Container>
    </>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.ActionButtons = ActionButtons;

export { Modal };
