import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  margin-top: 24px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const DownloadButton = styled.div`
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--purple-100, #ebe9fe);
  background: var(--purple-50, #f4f3ff);
`;

export const BoldText = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const Text = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Input = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  &.spacing {
    margin-right: 3px;
  }

  &.bold {
    font-weight: 600;
  }
`;

export const ContainerText = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &.password {
    justify-content: flex-end;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.email {
    gap: 4px;
  }
`;

export const ContainerBoxes = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Line = styled.div`
  width: 1px;
  height: 72px;
  background: #eaecf0;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const WrapperModal = styled.div``;

export const WrapperAlert = styled.div`
  margin-top: 10px;
  width: 100%;

  &.password {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const RowText = styled.div`
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
