import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

interface QBButtonProps extends ButtonProps {
  disabled?: boolean;
  logout?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  connected?: boolean | null;
}

const QBButton: React.FC<QBButtonProps> = (props) => {
  const theme = useTheme();

  let buttonText = "Connect to QuickBooks";

  if (props.connected === true) {
    buttonText = "Connected to QuickBooks";
  } else if (props.connected === false) {
    buttonText = "Failed: Reconnect QuickBooks";
  }

  return (
    <>
      <Button
        variant={props.logout ? "outlined" : "contained"}
        color={props.logout || props.connected === false ? "error" : "success"}
        onClick={props.onClick}
        disabled={props.disabled}
        sx={{
          backgroundColor: props.logout || props.connected === false ? "white" : "#00892e",
          color: props.logout || props.connected === false ? "black" : "white",
          borderColor: props.logout ? "#ccc" : "none",
          fontFamily: "Avenir Next",
          fontSize: "12px",
          "&:hover": {
            backgroundColor: props.logout
              ? theme.palette.error.main
              : "#006C23",
            color: theme.palette.common.white,
          },
        }}
        {...props}
      >
        {props.children ? props.children : buttonText}
      </Button>
    </>
  );
};

export default QBButton;
