import { useTranslation } from "react-i18next";

import { Files } from "features/common/components/Files/Files";
import { StepTitle } from "features/common/components/StepTitle/StepTitle";
import { step1Information } from "./utils";
import { common } from "theme";

import { FilesContainer } from "./Step1.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export const FilesStep1 = () => {
  const { t } = useTranslation();
  const currentCompany = useSelector((state: RootState) => state.currentCompany);

  return (
    <>
      <StepTitle
        title={t("template-Step1.1-title")}
        number={"Step 1"}
        color={common.colors.purple50}
        colorTitle={common.colors.purple600}
      />
      <Files
        title={"Chart of Accounts"}
        color={common.colors.purple50}
        iconDownload="downloadPurple"
        icon="chartOdAccounts"
        url={
          "https://neutech-s3-bucket.s3.amazonaws.com/templates/account.xlsx#"
        }
      />
      <StepTitle
        title={t("template-Step1.2-title")}
        number={"Step 2"}
        colorTitle={common.colors.purple600}
        color={common.colors.purple50}
      />
      <FilesContainer>
        {step1Information.map(({ text, icon, url }) => (
          <Files
            title={text}
            color={common.colors.purple50}
            iconDownload="downloadPurple"
            icon={icon}
            url={url}
          />
        ))}
      </FilesContainer>
      <StepTitle
        title={t("template-Step1.3-title")}
        number={"Step 3"}
        color={common.colors.purple50}
        colorTitle={common.colors.purple600}
      />
    </>
  );
};
