import React from "react";
import Tab from "@mui/material/Tab";
import Tabs, { TabsProps } from "@mui/material/Tabs";

export interface ITab {
  label: string;
  index: number;
  disabled: boolean;
}

interface IDXTabs extends TabsProps {
  tabs: ITab[];
  onChange: (e: React.SyntheticEvent, v: number) => void;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const DXTabs: React.FC<IDXTabs> = ({ tabs, ...props }) => {
  return (
    <Tabs
      {...props}
      sx={{
        "& .MuiTab-root": {
          fontSize: 16,
        },
        "& .MuiTabs-indicator": {
          background:
            "linear-gradient(90deg, rgba(40,174,225,1) 0%, rgba(151,71,255,1) 100%)",
        },
        "& .Mui-selected": {
          color: "#0040C1 !important",
        },
        "& .MuiTabs-scroller": {
          overflow: "auto !important",
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.index}
            tabIndex={tab.index}
            label={tab.label}
            {...a11yProps(tab.index)}
            disableRipple
            sx={{ opacity: tab.disabled ? 0.5 : 1, textTransform: "none", cursor: tab.disabled ? 'not-allowed' : 'pointer' }}
          />
        );
      })}
    </Tabs>
  );
};

export default DXTabs;
