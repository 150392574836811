export const inputFields = [
  {
    label: "First Name*",
    id: "firstName",
    type: "text",
    required: true,
    placeholder: "Enter first name",
  },
  {
    label: "Last Name*",
    id: "lastName",
    type: "text",
    required: true,
    placeholder: "Enter last name",
  },
  {
    label: "Email*",
    id: "email",
    type: "email",
    required: true,
    placeholder: "Enter email address",
  },
];
