import { CardProps } from "./utils";
import { Icon } from "features/common/components/Icon";

import {
  AccountName,
  CardContainer,
  Checkbox,
  Column,
  Information,
  Tag,
  TextInfo,
  TextTag,
  TextType,
} from "./Card.styles";

const DXCard = ({
  name,
  icon,
  quickbookInfo,
  userInfo,
  className,
  isSelected,
  price,
  title,
  description,
  isPlan,
  isSettings,
  onClick,
}: CardProps) => {
  return (
    <CardContainer
      className={
        isSelected
          ? !isPlan
            ? `${className} select account`
            : `${className} select`
          : !isPlan
          ? `${className} account`
          : className
      }
      onClick={onClick}
    >
      <Checkbox type="checkbox" checked={isSelected} onClick={onClick} />
      <Column>
        <AccountName>
          {icon && <Icon
           iconName={icon}
           />}
          <TextType
          className={className}
          >{isPlan ? title : name} </TextType>
          {isPlan && title === "Billed Annually" && (
            <Tag>
              <TextTag> Save 15%</TextTag>
            </Tag>
          )}
        </AccountName>
        <Information>
          <TextInfo>{isPlan ? price : quickbookInfo}</TextInfo>
          <TextInfo>{isPlan ? description : userInfo}</TextInfo>
        </Information>
      </Column>
    </CardContainer>
  );
};

export default DXCard;
