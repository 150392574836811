import React from "react";
import Box from "@mui/material/Box";

interface ITabPanel {
  value: number;
  index: number;
  children?: React.ReactNode;
}

const DXTabPanel = (props: ITabPanel) => {
  const { children, value, index, ...rest } = props;
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ width: "100%" }}
      {...rest}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

export default DXTabPanel;
