import { useTranslation } from "react-i18next";

import { handleLink } from "utils/handleLink";
import { AccountType } from "./AccountType/AccountType";
import { StepProps } from "../types";
import DXButton from "features/common/components/DXButton";
import DXIcon from "features/common/components/DXIcon";

import * as StyledStep from "features/Steps/Steps.styles";
import { useDispatch } from "react-redux";
import { setAccountType } from "redux/actions";

export const Account = ({
  setCurrentStep,
  setSelectedCard,
  selectedCard,
}: StepProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(setAccountType({ accountType: selectedCard }));
    setCurrentStep((prevStep) => prevStep + 1);
    handleLink("#3");
  };

  return (
    <StyledStep.PageContainer>

      <StyledStep.StepContainer>

        <StyledStep.Content>

          <StyledStep.Title>

            <StyledStep.StepTitle>
              {t("step-Account-Title")}
            </StyledStep.StepTitle>

            <StyledStep.StepSubtitle>
              {t("step-Account-Subtitle")}
            </StyledStep.StepSubtitle>

          </StyledStep.Title>

          <AccountType
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />

          <StyledStep.ButtonsContainer className="account">
            <DXButton
              color="primary"
              variant="contained"
              endIcon={<DXIcon name="ArrowRight" />}
              disabled={!selectedCard || selectedCard === "Firm - Coming Soon"}
              onClick={() => handleContinue()}
            >
              {t("continue")}
            </DXButton>
          </StyledStep.ButtonsContainer>

        </StyledStep.Content>

      </StyledStep.StepContainer>

    </StyledStep.PageContainer>
  );
};
