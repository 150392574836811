export const inputFields = [
  {
    label: "First Name*",
    id: "firstName",
    type: "text",
    required: true,
    placeholder: "Enter first name",
  },
  {
    label: "Last Name*",
    id: "lastName",
    type: "text",
    required: true,
    placeholder: "Enter last name",
  },
];

export const inputPasswordFields = [
  {
    label: "Password*",
    id: "password",
    type: "password",
    required: true,
    placeholder: "Enter password",
  },
  {
    label: "Confirm Password*",
    id: "confirmPassword",
    type: "password",
    required: true,
    placeholder: "Confirm password",
  },
];
