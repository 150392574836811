import styled from "styled-components";
import { Icon } from "features/common/components/Icon";

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const StepsContainer = styled.div`
  width: 464px;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 66px 16px 0px 48px;
`;

export const StepItem = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  min-height: 78px;
`;

export const Item = styled.div`
  display: flex;
  width: 400px;
  padding: 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.gray700};

  &.active {
    color: ${({ theme }) => theme.colors.purple700};
  }
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray700};

  &.active {
    color: ${({ theme }) => theme.colors.purple800};
  }
`;

export const TextContainer = styled.div``;

export const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  gap: 20px;
`;

export const Information = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray600};
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StepTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const StepSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray700};
  text-align: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const PageContainer = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: 100vh;
  overflow: scroll;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 600px;
  width: 64%;
  margin-bottom: 20px;
`;

export const IconStep = styled(Icon)`
  &.done {
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.purple600};
  }

  &.active {
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.purple50};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.purple50};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlg}) {
    flex-direction: column;
    gap: 20px;
  }

  &.create {
    justify-content: center;
  }

  &.account {
    justify-content: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlg}) {
      position: absolute;
      top: 1030px;
      flex-direction: column;
      gap: 20px;
    }
  }
`;
