import { useState } from "react";
import { useTranslation } from "react-i18next";

import DXButton from "features/common/components/DXButton";
import { Icon } from "features/common/components/Icon";
import { InputField } from "features/common/components/InputField/InputField";
import { Modal } from "features/common/components/Modal/Modal";
import { DropdownUser } from "features/common/components/Users/UserModals/ModalAddUser/DropdownUser";
import { inputFields } from "features/common/components/Users/UserModals/ModalAddUser/utils";
import { ModalProps } from "features/common/components/Users/UserModals/types";
import { DropdownCompanies } from "./DropdownCompanies/DropdownCompanies";
import { removeDuplicatedAddedCompanies } from "utils/removeDuplicatedAddedCompanies";

import {
  Information,
  Row,
  Text,
} from "features/common/components/Users/AddUsers.styles";
import { queryClient } from "index";
import { getData, post } from "services/api";
import {
  Body,
  Container,
  ContainerFields,
  DropdownBackground,
  InnerContainer,
  Wrapper,
} from "./styles";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "redux/reducers";
import { DropdownCompanyRoles } from "features/common/components/DropdownCompanyRoles/DropdownCompanyRoles";

export interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  accountId: string;
  accountRole: string;
  companies: {
    company: string;
    role: string;
  }[];
}

const defaultValue = {
  firstName: "",
  lastName: "",
  email: "",
  accountId: "",
  accountRole: "ORG_USER",
  companies: [{ company: "", role: "" }],
};

export default function ModalAddUsers({
  isOpen,
  closeModal,
  userLeft,
  className,
  companies,
  accountId,
}: ModalProps) {
  const { t } = useTranslation();
  const [user, setUser] = useState<ICreateUser>(defaultValue);
  const [availableCompanies, setAvailableCompanies] = useState(companies ? companies : []);
  const [companiesQty, setCompaniesQty] = useState(companies?.length);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector((state: RootState) => state);

  const addCompany = () => {
    setUser({
      ...user,
      companies: [...user.companies, { company: "", role: "" }],
    });

    setCompaniesQty(companiesQty - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {


      const sortedCompanies = removeDuplicatedAddedCompanies(user.companies);
      const current = {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        account_id: accountId,
        account_role: user.accountRole,
        companies: sortedCompanies?.map((company) => ({
          company_id: company.company,
          company_role: company.role,
        })),
      }

      const response = await post("/memberships/user", current)
        .then(res => console.log(res))
        .catch(e => console.log(e))
        .then(() => queryClient.invalidateQueries(`getUsers`));

      const resp = await getData(`/memberships/user/profile`);
      const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
      const company = account?.companies?.find((comp) => comp?.id === currentCompany?.company?.id);
      const rbac = resp.rbac;
      dispatch(setDXUserData(resp));
      dispatch(setCurrentCompany({ account, company, rbac }))
      toast.success("User added successfully");

    } catch (error) {
      // Handle error
      console.error(error);
    }
    handleCloseModal();
  };

  const handleInputChange = (fieldId, value, index?: number) => {
    const newUser = { ...user };
    if (index !== undefined) newUser.companies[index][fieldId] = value;
    else newUser[fieldId] = value;
    setUser(newUser);
  };

  const handleCloseModal = () => {
    setUser(defaultValue);
    setAvailableCompanies(companies ? companies : []);
    setCompaniesQty(companies?.length);
    closeModal();
  };

  return isOpen &&
    <Modal className={className} onClose={handleCloseModal}>
      <InnerContainer>
        <Modal.Header onClose={handleCloseModal}>{t("add-user")}</Modal.Header>
        <Row className="justify-center">
          <Information className="user">
            <Row>
              <Icon iconName="user-02" size="M" />
              <Text className="information">
                You have {userLeft} user licenses left.
              </Text>
            </Row>
          </Information>
        </Row>
        <Modal.Body>
          <Wrapper>
            <Body>
              {inputFields.map((field) => (
                <ContainerFields key={field.id}>
                  <InputField
                    label={field.label}
                    id={`${field.id}`}
                    type={field.type}
                    required={field.required}
                    placeholder={field.placeholder}
                    value={user[field.id]}
                    onChange={(event) =>
                      handleInputChange(field.id, event.target.value)
                    }
                  />
                </ContainerFields>
              ))}
              <DropdownBackground>
                {user?.companies?.map(({ company, role }, index) => (
                  <Row className="gap">
                    <ContainerFields>
                      <DropdownCompanies
                        companies={availableCompanies}
                        title="Company name*"
                        value={company}
                        handleChange={(event) =>
                          handleInputChange(
                            "company",
                            event.target.value,
                            index
                          )
                        }
                      />
                    </ContainerFields>
                    <ContainerFields>
                      <DropdownCompanyRoles
                        className="small"
                        title={t("user-type")}
                        value={role}
                        handleChange={(event) =>
                          handleInputChange("role", event.target.value, index)
                        }
                      />
                    </ContainerFields>
                  </Row>
                ))}
                <DXButton
                  type="button"
                  children="Add Another Company/User Type"
                  onClick={addCompany}
                  style={{ width: "fit-content" }}
                  disabled={companiesQty <= 1}
                />
              </DropdownBackground>
            </Body>
          </Wrapper>
        </Modal.Body>
        <Modal.ActionButtons>
          <Container>
            <DXButton
              type="button"
              children="Add User(s)"
              onClick={handleSubmit}
              disabled={userLeft <= 0}
            />
          </Container>
        </Modal.ActionButtons>
      </InnerContainer>
    </Modal>
}
