import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  resetCredentials,
  resetCurrentCompany,
  resetDXUserData,
  resetSignupFlow,
} from "redux/actions";

import MenuIcon from "@mui/icons-material/Menu";
import { Icon } from "../../Icon";

import useMobileView from "hooks/useMobileView";
import {
  Column,
  Container,
  ContainerOptions,
  Hamburger,
  IconDX,
  Line,
  Row,
  Text,
  Wrapper
} from "./Sidebar.styles";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { isRoleDisabled } from "utils/isRoleDisabled";

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useMobileView();
  const [isOpen, setIsOpen] = useState(false);
  const { currentCompany } = useSelector((state: RootState) => state);

  //TODO: add paths when they are ready
  const menuOptions = [
    { name: "Upload Your Files", icon: "UploadIcon", path: "/uploadFiles" },
    {
      name: "Template Library",
      icon: "TemplateIcon",
      path: "/templateLibrary",
    },
    //{ name: "Integrations", icon: "IntegrationIcon", path: "/integrations" },
    // { name: "Activity History", icon: "ActivityHistory", path: "" },
    {
      name: "FAQs",
      icon: "FAQs",
      path: "FAQs",
    },
    // { name: "Company", icon: "Company", path: "" },
    // { name: "Account", icon: "Account", path: "" },
  ];

  const { pathname } = useLocation();
  const history = useHistory();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem("DXUSER");
      localStorage.clear();
      dispatch(resetCredentials());
      dispatch(resetDXUserData());
      dispatch(resetCurrentCompany());
      dispatch(resetSignupFlow());
      history.push("/sign-in");
    } catch (error) {}
  };

  const handleOptionClick = (path) => {
    if (path === "FAQs") {
      window.open("https://intercom.help/datumexpress/en");
    } else {
      history.push(path);
    }
  };

 const compRole = currentCompany?.company?.role;
 const orgRole = currentCompany?.account?.role;
 const rbac = currentCompany?.rbac;
 const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);


  return (
    <Container isOpen={isOpen}>
      {isMobile && (
        <Hamburger as={MenuIcon} onClick={() => setIsOpen((prev) => !prev)} />
      )}
      {(!isMobile || isOpen) && (
        <>
          <IconDX iconName="logoDX" />
          <Wrapper>
            <ContainerOptions>
              {menuOptions.map(({ name, icon, path }) => (
                <Column key={name}>
                  <Row
                    className={`${pathname === path ? "isActive" : ""} ${isRoleDisabled(name, permissions) ? "disabled" : ""}`}
                    onClick={() => handleOptionClick(path)}
                    disabled={isRoleDisabled(name, permissions)}
                  >
                    <Icon iconName={icon} />
                    <Text>{name}</Text>
                  </Row>
                </Column>
              ))}
            </ContainerOptions>
          </Wrapper>
          <ContainerOptions>
            <Column>
              <Line />
              {permissions?.main_dashboard?.side_bar?.settings === "enable" && (
              <Row>
                <Icon iconName="Settings" />
                {/* <Text onClick={handlePopoverOpen}>Settings</Text> */}
                <Text onClick={() => history.push("/settings")}>Settings</Text>
              </Row>
              )}
            </Column>
            <Column>
              <Row>
                <Icon iconName="Logout" />
                <Text onClick={() => handleSignOut()}>Log Out</Text>
              </Row>
            </Column>
          </ContainerOptions>
        </>
      )}
    </Container>
  );
};
