import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.purple50};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray900};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  &.subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &.information {
    color: ${({ theme }) => theme.colors.gray700};
    text-align: right;
    line-height: 22px;
  }

  &.needHelp {
    color: ${({ theme }) => theme.colors.gray700};
    text-align: right;
    line-height: 22px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Information = styled.div`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
  width: fit-content;

  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  background: ${({ theme }) => theme.colors.white};

  &.user {
    border: 1px solid ${({ theme }) => theme.colors.purple100};
    background: ${({ theme }) => theme.colors.purple50};
  }

  &.needHelp {
    border: 1px solid ${({ theme }) => theme.colors.gray100};
    background: rgb(231, 247, 240);
}
`;

export const Row = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  &.gap {
    gap: 16px;
  }
  &.justify-center {
    justify-content: center;
  }
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  gap: 16px;
  flex-direction: column;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const BodyContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-width: 200px;
`;

export const ContainerFields = styled.div`
  width: 100%;
`;

export const DropdownBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f4f3ff;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 750px;
  overflow: scroll;
  justify-content: flex-start;
`;


export const Input = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.spacing {
    margin-right: 3px;
  }

  &.bold {
    font-weight: 600;
  }

  &.license {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-weight: 600;
  }

  &.email {
    @media screen and (max-width: 730px) {
      width: 109px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
