import DxTable from "features/common/components/DXTable/DXTable";

import { Box, FormControlLabel, Radio } from "@mui/material";
import DXIcon from "features/common/components/DXIcon";
import DXModal from "features/common/components/DXModal/DXModal";
import { Icon } from "features/common/components/Icon";
import QBButton from "features/common/components/QBButton/QBButton";
import QBLogoutButton from "features/common/components/QBButton/QBLogoutButton";
import useConnectQuickbooks from "hooks/useConnectQuickbooks";
import useCurrentUser from "hooks/useCurrentUser";
import { useEffect, useState } from "react";
import { Account } from "redux/types";
import { deleteData, getData, post } from "services/api";
import {
  ComponentTitle,
  Container,
  IconAction,
  Input,
  QBStep,
  Subtitle,
  Title,
  Wrapper,
  WrapperTable,
} from "./TableProfile.styles";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { ModalDeleteCompany } from "../ManageCompanies/ModalDeleteCompany";


interface TableProfileProps {
  accounts: Account[];
}

export const TableProfile = ({ accounts }: TableProfileProps) => {
  const { QBConnect } = useConnectQuickbooks();
  const [isCheckedAccounting, setIsChecked] = useState(false);
  const [isOpenModalQB, setIsOpenModalQB] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const isReconnect = params.get("connected") === "false";
  const companyIdParam = params.get("id");
  const { currentCompany } = useSelector((state: RootState) => state);
  const [orgCompanies, setOrgCompanies] = useState(currentCompany?.account?.companies || []);
  const { userData } = useCurrentUser();
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null);

  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  useEffect(() => {
    setOrgCompanies(currentCompany?.account?.companies || []);
  }, [currentCompany]);

  const [tableData, setTableData] = useState(
    orgCompanies.map(company => {
      let role = company.role.toLowerCase();
      role = role.charAt(0).toUpperCase() + role.slice(1);
      return {
        id: company.id,
        userName: company.name,
        numberOfUsers: company?.number_of_users,
        role,
      };
    }) || []
  );



  useEffect(() => {
    if (accounts && accounts[0]?.companies) {
      setTableData(
        orgCompanies.map((company) => {
          let role = company.role.toLowerCase();
          role = role.charAt(0).toUpperCase() + role.slice(1);
          return {
            id: company.id,
            userName: company.name,
            numberOfUsers: company?.number_of_users,
            role,
          };
        })
      );
    }
  }, [orgCompanies, accounts]);
  console.log({companyIdToDelete})

  const handleDelete = async (companyId) => {
    deleteData(`memberships/company/${companyId}`)
      .then(async () => {
        setTableData((prevData) =>
          prevData.filter((row) => row.id !== companyId)
        );
        const resp = await getData(`/memberships/user/profile`);
        const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
        let company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
        const rbac = resp.rbac;
        if (!company) {
          company = account.companies[0];
        }
        dispatch(setDXUserData(resp));
        dispatch(setCurrentCompany({ account, company, rbac }));
        toast.success("Company deleted successfully!");
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const handleLogout = async (companyId) => {
    try {
      await post("quickbooks/revoke-credentials", {
        membership_company_id: companyId,
      });
      toast.success("Logged out of QuickBooks successfully");
    } catch (error) {
      console.error("Error logging out of QuickBooks:", error);
      toast.error("Error logging out of QuickBooks");
    }
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false); // Close the modal
    setCompanyIdToDelete(null); // Reset companyIdToDelete to null
  };
  

  const columns = [
    {
      field: "userName",
      headerName: "Name",
      flex: 2,
      disableColumnMenu: true,
      align: "center",
      type: "number",
    },
    {
      field: "numberOfUsers",
      headerName: "Number of Users",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },

    {
      field: "role",
      headerName: "Role",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
    },
    {
      field: "QuickBooksOnlineStatus",
      headerName: "QuickBooks Online Status",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const company = orgCompanies.find(company => company.id === params.row.id);
        if (company?.credentials_id && company.credentials_integration) {
          return (
            <QBButton
              children="Log out"
              endIcon={<DXIcon name="LogoutQuickbook" />}
              logout={true}
              onClick={() => handleLogout(company.id)}
            />
          );
        } else {
          return (
            <QBButton
              children={isReconnect && companyIdParam === company.id ? "Reconnect" : "Connect"}
              endIcon={<DXIcon name="ConnectQuickbook" />}
              onClick={() => {
                setIsOpenModalQB(true);
                setCompanyId(company.id);
              }}
            />
          );
        }
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <IconAction 
        onClick={() => {
          if (permissions.my_profile.my_client_account.manage_users === 'enable') {
            setIsOpenDeleteModal(true);
            setCompanyIdToDelete(params.row.id);
          } else {
            return null;
          }
        }}
        style={{ 
          cursor: permissions?.my_profile.my_client_account.manage_users === 'enable' ? 'pointer' : 'not-allowed',
          opacity: permissions?.my_profile.my_client_account.manage_users === 'enable' ? 1 : 0.5,
        }}
        >
          <Icon iconName="trash-01"></Icon>
          Delete
        </IconAction>
      ),
    },
  ];

  return (
    <>
    <WrapperTable>
      <ComponentTitle>
        <Wrapper>
          <Container>
            <Title>My Client Companies</Title>
            <Subtitle> {orgCompanies.length} Company(ies)</Subtitle>
          </Container>
        </Wrapper>
      </ComponentTitle>
      <DxTable
        checkboxField={false}
        rows={tableData}
        columns={columns}
        className="profile"
      />
      <DXModal
        title="Add a QuickBooks Account"
        open={isOpenModalQB}
        onClose={() => setIsOpenModalQB(!isOpenModalQB)}
        customWidth={700}
      >

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
          width: "100%",
          paddingTop: "32px",
        }}>

          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}>

            <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
              <QBStep>Step 1</QBStep>
            </Box>

            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>

              <Box sx={{ width: "220px" }}>
                <QBLogoutButton
                  children={"Confirm you are logged out"}
                  onClick={() => window.open("https://accounts.intuit.com/", '_blank')}
                  sx={{ width: "220px", fontSize: "13px", backgroundColor: "#6938EF" }}
                />
              </Box>

              <Input style={{ textAlign: "justify", marginTop: "20px" }}>
                Please click the button above to verify. If an existing QuickBooks workspace opens,  please sign out of that workspace before continuing to Step 2 on this screen. If instead you are prompted to sign in to QuickBooks, this confirms you are already successfully logged out and can proceed to Step 2. Please wait to sign in until Step 3 as you need to verify the type of account first.
              </Input>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            gap: 2,
          }}>

            <Box sx={{ minWidth: "60px" }}>
              <QBStep>Step 2</QBStep>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>

              <Box sx={{ fontWeight: "bold" }}>
                <Input>Select type of account</Input>
              </Box>

              <Box>
                <Input style={{ color: "#6938EF", margin: 0, cursor: "pointer" }}
                  onClick={() => setOpenInfoModal(true)}
                >
                  *How do I know if I have access to the QuickBooks Online Payments module? </Input>
              </Box>

              <Box sx={{
                display: "flex",
                justifyContent: "center",
              }}>
                <FormControlLabel
                  sx={{ width: "50%", margin: 0, }}
                  control={<Radio />}
                  label="QuickBooks Online only"
                  onChange={() => setIsChecked(false)}
                  checked={!isCheckedAccounting}
                />

                <FormControlLabel
                  sx={{ width: "50%", margin: 0, fontFamily: "Avenir Next" }}
                  control={<Radio />}
                  label={"QuickBooks Online and \n QuickBooks Online Payments"}
                  onChange={() => setIsChecked(true)}
                  checked={isCheckedAccounting}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            gap: 2,
          }}>

            <Box sx={{ minWidth: "60px", marginTop: "7px" }}>
              <QBStep>Step 3</QBStep>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
            }}>

              <Box sx={{ width: "220px" }}>
                <QBButton
                  children={isReconnect ? "Reconnect" : "Connect to QuickBooks"}
                  endIcon={<DXIcon name="ConnectQuickbook" />}
                  onClick={() => QBConnect(accounts[0].id, userData.sub, isCheckedAccounting, companyId, true)}
                  sx={{ width: "220px", fontSize: "13px", }}

                />
              </Box>

              <Input style={{ marginTop: "20px" }}>Once connected, you can seamlessly toggle across workstreams directly in DatumXpress.</Input>
            </Box>
          </Box>
        </Box>

      </DXModal>

      <DXModal title={""} open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
        <Input>The QuickBooks Online Payments module is an add-on to standard QuickBooks Online plans that enables features related to the remittance of payments. To upgrade your QuickBooks plan to include payments please <a href="https://quickbooks.intuit.com/money/see-plans/" target="_blank">click here</a>.<br /><br />

          To confirm if your account has Payments' access, please check your product subscription tab or Billing tab within QuickBooks which will indicate "QuickBooks Online Payments."<br /><br />

          Within DatumXpress, this module allows you to access templates associates with Bill Payments, Credit Card Charges, and Transfers which are otherwise disabled. </Input>
      </DXModal>
    </WrapperTable >
    {isOpenDeleteModal && (
      <ModalDeleteCompany
        isOpenModalDelete={isOpenDeleteModal}
        setIsOpenModalDelete={handleCloseDeleteModal}
        handleDelete={handleDelete}
        id={companyIdToDelete}
        text="Are you sure you want to delete this company?"
        confirmLabel="Yes, delete"
        title="Delete"
      />
    )}
    </>
  );
};
