import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const ContainerMenu = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: #0f003f;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
  padding-bottom: 16px;
`;

export const ContainerTabs = styled.div`
  width: 100%;
  background: white;
  padding: 24px 24px 0px 24px;
  box-shadow: 0px -2px 0px 0px #f2f4f7 inset;
`;

export const Wrapper = styled.div`
  padding: 24px;
  width: 100%;
`;
