import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Step } from "features/common/components/Step/Step";
import { common } from "theme";
import { FilesStep3 } from "./FilesStep3";
import { StepContainer } from "../../TemplateLibrary.styles";

import { Container } from "../Step1/Step1.styles";

export const Step3 = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <StepContainer>
      <Step
        title="Type 3"
        color={common.colors.green2}
        text="Accrual Automations"
        subtitle={t("template-subtitle-3")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <Container>
          <FilesStep3 />
        </Container>
      )}
    </StepContainer>
  );
};
