import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface QBButtonProps extends ButtonProps {
  disabled?: boolean;
  logout?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  connected?: boolean | null;
}

const QBButton: React.FC<QBButtonProps> = (props) => {
  const theme = useTheme();
  return (
    <>
      <Button
        variant={"contained"}
        color={props.logout || props.connected === false ? "error" : "success"}
        onClick={props.onClick}
        disabled={props.disabled}
        sx={{
          backgroundColor: "#6938EF",
          color: "white",
          borderColor: props.logout ? "#ccc" : "none",
          fontFamily: "Avenir Next",
          fontSize: "12px",
          "&:hover": {
            backgroundColor: "#5a30ce",
            color: theme.palette.common.white,
          },
        }}
        {...props}
      >
        {props.children}
      </Button>
    </>
  );
};

export default QBButton;
