import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "./Dropdown.styles";
import { minWidth } from "@mui/system";

interface DropdownProps {
  title?: string;
  options?: string | string[];
  setOrganizationUser?: Dispatch<SetStateAction<string>>;
  organizationSize?: string;
  handleChange?: any;
  value?: string;
  className?: string;
}

export const DropdownUser = ({
  title,
  options,
  handleChange,
  value,
  className,
}: DropdownProps) => {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Styled.TextForm>{title}</Styled.TextForm>
      <Styled.Dropdown
        displayEmpty
        defaultValue=""
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          MenuListProps: {
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
              maxWidth: className === "small" ? 600 : 718,
              minWidth: className === "small" ? 600 : 718,
            },
          },
        }}
        onChange={handleChange}
        value={value}
        renderValue={(selected) => {
          switch (selected) {
            case "COMP_MANAGER":
              return t("dropdown-Account-Manager");
            case "COMP_USER":
              return t("dropdown-User");
            case "COMP_ADMIN":
              return t("dropdown-Administrator");
            default:
              return "";
          }
        }}
      >
        <Styled.MenuItemStyle className="organization" value={"COMP_MANAGER"}>
          <Styled.Option>
            <Styled.Column>
              <Styled.Row className="editUser">
                <Styled.Text>
                  {t("dropdown-Account-Manager")}
                </Styled.Text>
                <Styled.LabelContainer>
                  <Styled.Text className="qboRequired">
                    {t("dropdown-Account-QuickBooks")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
              <Styled.Row className="editUser">
                <Styled.Text className="information">
                  {t("dropdown-Client-Workspace")}
                </Styled.Text>
                <Styled.LabelContainer className="full">
                  <Styled.Text className="subtitle">
                    {t("dropdown-Full-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
              <Styled.Row className="dropdownOption">
                <Styled.LeftGroup>
                  <Styled.Text className="information">
                    {t("dropdown-Admin-Panel")}
                  </Styled.Text>
                  <Styled.LabelContainer>
                    <Styled.Text className="italic"> {t("dropdown-Admin-Panel-Options")}</Styled.Text>
                  </Styled.LabelContainer>
                </Styled.LeftGroup>
                <Styled.LabelContainer className="full">
                  <Styled.Text className="subtitle">
                    {t("dropdown-Full-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
            </Styled.Column>
          </Styled.Option>
        </Styled.MenuItemStyle>
        <Styled.Divider />
        <Styled.MenuItemStyle className="organization" value={"COMP_USER"}>
          <Styled.Option>
            <Styled.Column>
              <Styled.Row className="editUser">
                <Styled.Text>{t("dropdown-User")}</Styled.Text>
                <Styled.LabelContainer>
                  <Styled.Text className="qboRequired">
                    {t("dropdown-Account-QuickBooks")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
              <Styled.Row className="editUser">
                <Styled.Text className="information">
                  {t("dropdown-Client-Workspace")}
                </Styled.Text>
                <Styled.LabelContainer className="full">
                  <Styled.Text className="subtitle">
                    {t("dropdown-Full-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
              <Styled.Row className="dropdownOption">
                <Styled.LeftGroup>
                  <Styled.Text className="information">
                    {t("dropdown-Admin-Panel")}
                  </Styled.Text>
                  <Styled.LabelContainer>
                    <Styled.Text className="italic"> {t("dropdown-User-Information")}</Styled.Text>
                  </Styled.LabelContainer>
                </Styled.LeftGroup>
                <Styled.LabelContainer className="limited">
                  <Styled.Text className="subtitle">
                    {t("dropdown-Limited-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
            </Styled.Column>
          </Styled.Option>
        </Styled.MenuItemStyle>
        <Styled.Divider />
        <Styled.MenuItemStyle className="organization" value={"COMP_ADMIN"}>
          <Styled.Option>
            <Styled.Column>
              <Styled.Row>
                <Styled.Text>
                  {t("dropdown-Administrator")}
                </Styled.Text>
              </Styled.Row>
              <Styled.Row className="editUser">
                <Styled.Text className="information">
                  {t("dropdown-Client-Workspace")}
                </Styled.Text>
                <Styled.LabelContainer className="noAccess">
                  <Styled.Text className="subtitle">
                    {t("dropdown-No-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
              <Styled.Row className="dropdownOption">
                <Styled.LeftGroup>
                  <Styled.Text className="information">
                    {t("dropdown-Admin-Panel")}
                  </Styled.Text>
                  <Styled.LabelContainer>
                    <Styled.Text className="italic"> {t("dropdown-Admin-Panel-Options")}</Styled.Text>
                  </Styled.LabelContainer>
                </Styled.LeftGroup>
                <Styled.LabelContainer className="full">
                  <Styled.Text className="subtitle">
                    {t("dropdown-Full-Access")}
                  </Styled.Text>
                </Styled.LabelContainer>
              </Styled.Row>
            </Styled.Column>
          </Styled.Option>
        </Styled.MenuItemStyle>
      </Styled.Dropdown>
    </Styled.Container>
  );
};
