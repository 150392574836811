import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetCurrentCompany, resetDXUserData, setCredentials, setCurrentCompany, setDXUserData } from "redux/actions";

import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Amplify, Auth } from "aws-amplify";
import amplifyConfig from "config/amplify-config";
import DXButton from "features/common/components/DXButton/DXButton";
import DXInputField from "features/common/components/DXInputField/DXInputField";
import useCurrentUser from "hooks/useCurrentUser";
import { setUserCredentials } from "utils/setUserCredentials";
import { ForgotPassword } from "./ForgotPassword";

import useMobileView from "hooks/useMobileView";
import {
  ColumnWrapper,
  Container,
  ContainerIcon,
  FormWrapper,
  IconDX,
  IconDXVector,
  IconDXVector2,
  PaperWrapper,
  Text,
} from "./SignInForm.styles";
import { getData } from "services/api";
import { RootState } from "redux/reducers";

Auth.configure(amplifyConfig);
Amplify.configure(amplifyConfig);

function SigInForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoggedIn, userData, dxUserData } = useCurrentUser();
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const dispatch = useDispatch();
  const [isCreated, setIsCreated] = useState(false);
  const [user, setUser] = useState<any | null>(null);
  const isMobile = useMobileView();
  const { currentCompany } = useSelector((state: RootState) => state);

  useEffect(() => {
    console.log("Resetting current company")
    dispatch(resetCurrentCompany());
    console.log("Resetting DX user data")
    dispatch(resetDXUserData())
  })

  const [verifyCode, setVerifyCode] = useState({
    code: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });

  function handleChange(name: string, value: string) {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleCode = async () => {
    try {
      setIsLoading(true);
      await Auth.confirmSignIn(user, verifyCode.code, "SOFTWARE_TOKEN_MFA");

      setUserCredentials({
        accessToken: user.signInUserSession.accessToken.jwtToken,
        idToken: user.signInUserSession.idToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token,
      });

      dispatch(
        setCredentials({
          accessToken: user.signInUserSession.accessToken.jwtToken,
          idToken: user.signInUserSession.idToken.jwtToken,
          refreshToken: user.signInUserSession.refreshToken.token,
        })
      );
      console.log("Getting profile information")
      const data = await getData("/memberships/user/profile");
      console.log("Data", data)
      if (data) {
        console.log("Setting current company")
        dispatch(setDXUserData(data));
        if (currentCompany?.account && currentCompany?.company) {
          console.log("Setting current company", currentCompany)
          const account = data.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
          const company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
          const rbac = data.rbac;
          dispatch(setCurrentCompany({ account, company, rbac }))
        } else {
          const account = data.accounts[0]
          const company = data.accounts[0].companies[0]
          const rbac = data.rbac;
          dispatch(setCurrentCompany({ account, company, rbac }))
        }
      }
      history.push("/uploadFiles");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false)
      toast.error(err.message);
    }
  }

  const handleSubmit = async () => {
    console.log("Payload", payload)

    try {
      setIsLoading(true);
      const response = await Auth.signIn({
        username: payload.email.toLowerCase(),
        password: payload.password,
      });

      setUser(response);
      setIsLoading(false);

      setIsCreated(true);
      dispatch(resetCurrentCompany());
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setDXUserData(dxUserData));
      history.push("/uploadFiles");
    }
  }, [userData]);

  async function forgotPassword(username: string) {
    try {
      if (!username) {
        toast.error("Please enter a valid email");
        return;
      }
      const data = await Auth.forgotPassword(username);
      setOpenModalPassword(true);
    } catch (err) {
      toast.error("Incorrect Code");
    }
  }

  const handleChangeVerifyCode = (value: string) => {
    setVerifyCode({
      code: value,
    });
  };

  return (
    <Container>
      <PaperWrapper>
        <Stack direction="row" sx={{ height: "100%" }}>
          <FormWrapper>
            <form onSubmit={() => handleSubmit()} style={{ width: "100%" }}>
              <Stack sx={{ width: "100%" }} gap={3}>
                <ContainerIcon>
                  <IconDX iconName="dx-logo-dark" />
                </ContainerIcon>

                <DXInputField
                  fullWidth
                  label="Email"
                  name="email"
                  type="text"
                  value={payload.email || ""}
                  onChange={({ target }) =>
                    handleChange(target.name, target.value)
                  }
                  disabled={isLoading}
                />
                <DXInputField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  value={payload.password || ""}
                  onChange={({ target }) =>
                    handleChange(target.name, target.value)
                  }
                  disabled={isLoading}
                />
                {isCreated && (
                  <DXInputField
                    label={t("confirmation-code")}
                    type={"number"}
                    placeholder="Enter the code sent to your authenticator app"
                    fullWidth
                    value={verifyCode.code || ""}
                    onChange={({ target }) =>
                      handleChangeVerifyCode(target.value)
                    }
                  />
                )}
                {!isCreated && (
                  <DXButton
                    disabled={isLoading}
                    variant="contained"
                    onClick={() => handleSubmit()}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress size={28} sx={{ color: "white" }} />
                    ) : (
                      t("login")
                    )}
                  </DXButton>
                )}
                {isCreated && (
                  <DXButton
                    disabled={isLoading || !verifyCode.code}
                    variant="contained"
                    onClick={() => handleCode()}
                  >
                    {isLoading ? (
                      <CircularProgress size={28} sx={{ color: "white" }} />
                    ) : (
                      "Confirm Code"
                    )}
                  </DXButton>
                )}
                <Text onClick={() => forgotPassword(payload.email)}>
                  Forgot Password?
                </Text>
                {isMobile && (
                  <Text onClick={() => history.push("/sign-up/#1")}>
                    Need an account? Sign up
                  </Text>
                )}
              </Stack>
            </form>
          </FormWrapper>

          {!isMobile && (
            <ColumnWrapper>
              <IconDXVector iconName="Vector" />
              <IconDXVector2 iconName="Vector2" />
              <div>{t("need-account")}</div>
              <DXButton
                color="info"
                variant="text"
                sx={{ minWidth: "150px" }}
                onClick={() => history.push("/sign-up/#1")}
              >
                {t("signup")}
              </DXButton>
            </ColumnWrapper>
          )}

          {openModalPassword && (
            <ForgotPassword
              userName={payload.email}
              isPasswordOpen={openModalPassword}
              setIsPassword={setOpenModalPassword}
            />
          )}
        </Stack>
      </PaperWrapper>
    </Container>
  );
}

export default SigInForm;
