import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Company } from "redux/types";

import DXButton from "features/common/components/DXButton/DXButton";
import DXIcon from "features/common/components/DXIcon";
import DxTable from "features/common/components/DXTable/DXTable";
import ModalAddUser from "features/common/components/Users/UserModals/ModalAddUser/ModalAddUser";
import ModalEditUser from "features/common/components/Users/UserModals/ModalEditUser/ModalEditUser";
import { queryClient } from "index";
import { deleteData, getData } from "services/api";
import { formatUserData } from "utils/formatUserData";
import { Cell } from "./Cell";
import { ModalDeleteCompany } from "./ModalDeleteCompany";
import { manageAccountColumns, manageUsersColumns } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

import DXModal from "features/common/components/DXModal";
import {
	ButtonContainer,
	ComponentTitle,
	Container,
	Icon,
	OrgComponent,
	Title,
	UserComponent,
	Wrapper,
} from "./TableManageCompany.styles";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import toast from "react-hot-toast";
import { RootState } from "redux/reducers";

interface TableProps {
	role: string;
	company: Company;
	maxUsers: number;
	isFromManageCompanies?: boolean;
	accountId?: string;
	companyRole?: string;
}

export const TableManageAccount = ({
	role,
	company,
	maxUsers,
	accountId,
	isFromManageCompanies = false,
	companyRole
}: TableProps) => {
	const [isOpenTable, setIsOpenTable] = useState(true);
	const [isOpenAddUser, setIsOpenUser] = useState(false);
	const [isOpenDeleteModal, setIsOpenDelete] = useState(false);
	const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false);
	const [isOpenEditUser, setIsOpenEditUser] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [userLeft, setUsersLeft] = useState(null);
	const dispatch = useDispatch();
	const { currentCompany } = useSelector((state: RootState) => state);

	const queryKey = `getUsers_${company.id}`;
	const { data: users, isLoading } = useQuery(queryKey, () =>
		getData(`memberships/company/${company.id}/user`),
	);

	const handleOpenDeleteUser = (userId) => {
		setSelectedUserId(userId);
		setIsOpenDeleteUser(true);
	};

	const handleOpenEditUser = (userId) => {
		setSelectedUserId(userId);
		setIsOpenEditUser(true);
	};

	const handleDeleteUser = (userId) => {
		setTableData((prevData) => prevData.filter((user) => user.id !== userId));

		deleteData(`memberships/user/${userId}`)
			.then(() => {
				setIsOpenDeleteUser(false);
				queryClient.invalidateQueries("getUsers");
			})
			.catch((error) => {
				console.error("Error deleting user:", error);
			});
	};

	const handleDelete = async (companyId) => {
		try {
			await deleteData(`memberships/company/${companyId}`)
			setIsOpenDelete(false);
			queryClient.invalidateQueries("getCompanies");
			const resp = await getData(`/memberships/user/profile`);
			const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
			let company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
			const rbac = resp.rbac

			if (!company) {
				company = account.companies[0];
			}
			dispatch(setDXUserData(resp));
			dispatch(setCurrentCompany({ account, company, rbac }));
			toast.success("Company deleted successfully!");
		} catch (error) {
			console.error("Error deleting company:", error);
		}
	};

	const orgRole = currentCompany?.account?.role;
	const compRole = currentCompany?.company?.role;
	const rbac = currentCompany?.rbac;
	const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

	const isEditDisabled = () => {
		return permissions?.manage_companies?.company_box?.edit_everyone === "disable";
	}
	const isDeleteDisabled = () => {
		return permissions?.manage_companies?.company_box?.delete_everyone === "disable";
	}
	const isEditHidden = () => {
		return permissions?.manage_companies?.company_box?.edit_everyone === "hide";
	}
	const isDeleteHidden = () => {
		return permissions?.manage_companies?.company_box?.delete_everyone === "hide";
	}

	const [tableData, setTableData] = useState([]);

	const actions = useMemo(() => ({
		field: "actions",
		headerName: "Actions",
		align: "center",
		flex: 2,
		disableColumnMenu: true,
		sortable: false,
		renderCell: (params) => (
			<Cell
				setSelectedUser={setSelectedUser}
				setIsOpenEditUser={setIsOpenEditUser}
				handleOpenDeleteUser={handleOpenDeleteUser}
				handleOpenEditUser={handleOpenEditUser}
				params={params}
				editDisabled={isEditDisabled()}
				deleteDisabled={isDeleteDisabled()}
				editHide={isEditHidden()}
				deleteHide={isDeleteHidden()}
			/>
		),
	}), [companyRole])

	const handleColumns = () =>
		isFromManageCompanies
			? [...manageAccountColumns, actions]
			: [...manageUsersColumns, actions];

	useEffect(() => {
		if (users) {
			setTableData(formatUserData(users));
		}
		setUsersLeft(maxUsers - users?.items?.length);
	}, [maxUsers, users]);

	const userLicensesLeft = currentCompany?.account?.license?.max_users - currentCompany?.account?.license?.used_licenses;

	return (
		<>
			<ComponentTitle className={isOpenTable ? "isOpen" : "isClose"}>
				<Wrapper>
					<Container>
						<Title>{company.name}</Title>
						<OrgComponent>
							{currentCompany?.account?.name}
						</OrgComponent>
						<UserComponent>
							<DXIcon name="UserPurple" />
							{users?.items?.length} users
						</UserComponent>
					</Container>
				</Wrapper>
				<ButtonContainer>
					{permissions?.manage_companies?.company_box?.add_user === 'enable' && (
						<>
							<DXButton
								variant="text"
								size="small"
								color="success"
								children="Add User"
								disabled={userLeft <= 0}
								startIcon={<DXIcon name="PlusGreen" cssClass="dark-icon" />}
								onClick={() => setIsOpenUser(true)}
								sx={{ color: "#027a48", fontFamily: "Inter" }}
							/>
							<DXButton
								variant="text"
								size="small"
								color="error"
								children="Delete Company"
								startIcon={<DXIcon name="Trash" cssClass="dark-icon" />}
								onClick={() => setIsOpenDelete(true)}
							/>
						</>
					)}
					<Icon
						onClick={() => setIsOpenTable((prev) => !prev)}
						className={isOpenTable ? "rotate" : ""}
					>
						<DXIcon name="ChevronUp" />
					</Icon>
				</ButtonContainer>
			</ComponentTitle>
			{isOpenTable && (
				<DxTable
					checkboxField={false}
					rows={tableData}
					columns={handleColumns()}
				/>
			)}
			<ModalAddUser
				isOpen={isOpenAddUser}
				closeModal={() => setIsOpenUser(false)}
				userLeft={userLicensesLeft}
				className="addUser"
				companyId={company.id}
				accountId={accountId}
			/>
			{isOpenDeleteModal && (
				<ModalDeleteCompany
					isOpenModalDelete={isOpenDeleteModal}
					setIsOpenModalDelete={setIsOpenDelete}
					handleDelete={handleDelete}
					id={company.id}
					text="Are you sure you want to delete this company?"
					confirmLabel="Yes, delete"
					title="Delete"
				/>
			)}
			{isOpenDeleteUser && (
				<ModalDeleteCompany
					isOpenModalDelete={isOpenDeleteUser}
					setIsOpenModalDelete={setIsOpenDeleteUser}
					handleDelete={handleDeleteUser}
					id={selectedUserId}
					text="Are you sure you want to delete this user?"
					confirmLabel="Yes, delete"
					title="Delete"
				/>
			)}

			{isOpenEditUser && isFromManageCompanies && (
				<DXModal
					title={"Edit User"}
					open={isOpenEditUser}
					onClose={() => setIsOpenEditUser(false)}
					customWidth={800}
					children={
						<ModalEditUser
							isOpen={isOpenEditUser}
							closeModal={() => setIsOpenEditUser(false)}
							companyId={company.id}
							userToEdit={selectedUserId}
							className="addUser"
							isEditing={true}
						/>
					}
				/>
			)}
		</>
	);
};
