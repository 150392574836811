import { FC, memo } from "react";
import { IconProps } from "./types";

import { IconElement } from "./Icon.styles";

const Icon: FC<IconProps> = ({ className, iconName, ...props }: IconProps) => (
  <IconElement
    aria-label={`${iconName} icon`}
    className={className}
    src={`/icons/${iconName}.svg`}
    {...props}
  />
);

const memorizedIcon: FC<IconProps> = memo(Icon);

export { memorizedIcon as Icon };
