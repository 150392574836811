import styled from "styled-components";
import { Icon } from "../../Icon";

export const Container = styled.div`
  display: flex;
  top: 0px;
  width: 100%;
  height: 96px;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 10px 0px ${({ theme }) => theme.colors.blackGray};
  margin-bottom: 4px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`;

export const ContainerText = styled.div`
  margin-left: 250px;

  @media screen and (max-width: 730px) {
    margin-left: 0px;
  }
`;

export const Settings = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconSettings = styled(Icon)`
  width: 24px;
  height: 24px;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const ButtonIcon = styled.button`
  background: transparent;
  border: none;
  width: 24px;
  height: 24px;
`;


export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
`;

export const LicensesLeftContainer = styled.div`
display: flex;
width: 100%;
margin-bottom: -30px;
`;
