import styled from "styled-components";

export const ComponentTitle = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: white;
`;

export const WrapperTable = styled.div`
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

export const Title = styled.div`
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  `;

export const QBStep = styled.div`
  color: #6938EF;
  font-family: Inter;
  background-color: rgb(240, 235, 253) !important;
  border: 1px solid rgba(200, 200, 200, 0.20);
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 4px 8px;
`;

export const Subtitle = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Input = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.spacing {
    margin-right: 3px;
  }

  &.bold {
    font-weight: 600;
  }

  &.license {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-weight: 600;
  }

  &.email {
    @media screen and (max-width: 730px) {
      width: 109px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const IconAction = styled.div`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: flex-end;
  font-weight: 500;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
