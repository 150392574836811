import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
`;

export const MainPanel = styled.main`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.white};
  right: 0;
  margin-left: 250px;

  @media screen and (max-width: 730px) {
    margin-left: 0px;
    position: static;
  }
`;

export const ContainerFooter = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  margin-left: 250px;
  flex-direction: column;

  @media screen and (max-width: 730px) {
    margin-left: 0px;
  }
`;
