import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Box from "@mui/material/Box";
import { SettingsIcon } from "assets/icons";
import { setCurrentCompany } from "redux/actions";
import { RootState } from "redux/reducers";
import DXButton from "../../DXButton/DXButton";
import DXInputField from "../../DXInputField";
import DXModal from "../../DXModal";

import MenuItem from "@mui/material/MenuItem";
import {
  ButtonIcon,
  Container,
  ContainerButton,
  ContainerText,
  Image,
  Settings,
  Text,
  LicensesLeftContainer
} from "./Topbar.styles";
import { quantityLicensesCompanies } from "utils/quantityLicensesAndUsers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

export const Topbar = () => {
  const [switchCompanyOpen, setSwitchCompanyOpen] = useState(false);
  const [accountName, setAccountName] = useState("");
  const history = useHistory();
  const { currentCompany, dxUserData } = useSelector((state: RootState) => state);
  const [payload, setPayload] = useState({
    accountId: "",
    companyId: "",
  });
  const [orgCompanies, setOrgCompanies] = useState(currentCompany?.account?.companies || []);
  const dispatch = useDispatch();

  const licensesLeft = quantityLicensesCompanies(dxUserData);

  useEffect(() => {
    if (currentCompany?.company) {
      setAccountName(currentCompany?.company.name);
    }
  }, [currentCompany]);

  useEffect(() => {
    setOrgCompanies(currentCompany?.account?.companies || []);
  }, [currentCompany]);

  useEffect(() => {
    if (switchCompanyOpen) {
      setPayload({
        accountId: currentCompany?.account?.id,
        companyId: currentCompany?.company?.id,
      });
    }
  }, [currentCompany?.account?.id, currentCompany?.company?.id, switchCompanyOpen]);

  const handleChange = (name: string, value: string) => {

    if (name === "accountId") {
      handleAccountChange(value);
    }
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDefaultCompany = () => {
    const account = dxUserData.accounts.find((acc) => acc?.id === payload.accountId);
    const company = account.companies.find((comp) => comp?.id === payload.companyId);
    dispatch(setCurrentCompany({ account, company, rbac: currentCompany?.rbac }));
    setSwitchCompanyOpen(false);
    window.location.reload();
    toast.success("Switched company successfully!");
  };

  const handleAccountChange = (accountId) => {
    const account = dxUserData.accounts.find((acc) => acc.id === accountId);
    setOrgCompanies(account?.companies);
  }

  const pilotMessage = {
    color: "#12B76A",
    fontSize: "14px",
    padding: "4px",
    borderRadius: "6px",
    backgroundColor: "rgb(231, 247, 240)"
  }

  const compRole = currentCompany?.company?.role || "";
  const orgRole = currentCompany?.account?.role || "";
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);


  return (
    <Container>
      <ContainerText>
        <Text>{accountName} </Text>
      </ContainerText>

      <p style={pilotMessage}>Thank you for joining our pilot! Issues? <a href="mailto:support@datumexpress.com" style={{ color: "#12B76A", fontSize: "14px", fontWeight: "500", textDecoration: "none" }}>Share here!</a></p>

      <Settings>
        <DXButton
          color="secondary"
          variant="contained"
          startIcon={<SyncAltIcon />}
          onClick={() => setSwitchCompanyOpen(!switchCompanyOpen)}
          disabled={!accountName}
        >
          Switch/Add Company
        </DXButton>
        {permissions?.main_dashboard?.top_bar?.settings === 'enable' && (
          <ButtonIcon type="button" onClick={() => history.push("/settings")}>
            <Image src={SettingsIcon} />
          </ButtonIcon>
        )}
      </Settings>
      {dxUserData.accounts ? (
        <DXModal
          title="Switch Account"
          onClose={() => setSwitchCompanyOpen(!switchCompanyOpen)}
          open={switchCompanyOpen}
          className={"clientAccount"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              alignItems: "center",
            }}
          >
            <DXInputField
              select
              defaultValue={currentCompany?.account?.id || ""}
              value={payload.accountId ?? ""}
              label="Select Organization"
              fullWidth
              name="accountId"
              onChange={({ target }) => handleChange(target.name, target.value)}
            >
              {dxUserData.accounts?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </DXInputField>
            <DXInputField
              select
              defaultValue={currentCompany?.company?.id || ""}
              value={payload?.companyId ?? ""}
              label="Select Company"
              fullWidth
              name="companyId"
              onChange={({ target }) => handleChange(target.name, target.value)}
            >
              {orgCompanies.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </DXInputField>
            {/* <LicensesLeftContainer>
              You have {licensesLeft} license(s) available.
            </LicensesLeftContainer> */}
            <ContainerButton>
              <DXButton
                variant="contained"
                color="secondary"
                sx={{ minWidth: "140px" }}
                onClick={() => {
                  history.push({
                    pathname: "/settings",
                    state: {
                      tabValue: 1,
                    },
                  });
                  setSwitchCompanyOpen(false);
                }}
                disabled={permissions?.main_dashboard?.top_bar?.add_company === 'disable'}
              >
                Add Company
              </DXButton>
            </ContainerButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: 4,
              width: "100%",
              gap: 4,
            }}
          >
            <DXButton
              variant="contained"
              color="secondary"
              sx={{ minWidth: "140px" }}
              onClick={() => setSwitchCompanyOpen(false)}
            >
              Cancel
            </DXButton>
            <DXButton
              variant="contained"
              sx={{ minWidth: "140px" }}
              onClick={() => handleDefaultCompany()}
              disabled={payload.companyId === currentCompany?.company?.id}
            >
              Confirm Changes
            </DXButton>
          </Box>
        </DXModal>
      ) : null}

    </Container>
  );
};
