import { Icon } from "features/common/components/Icon";
import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const StyledFormSignUp = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const TextForm = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const Input = styled.input`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray700};

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.purple300};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.lavender},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    outline: none;
  }
`;

export const InputPassword = styled.input`
  border: 0px;
  color: ${({ theme }) => theme.colors.gray700};
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &:focus {
    border: 0px;
    outline: none;
  }
`;

export const InputContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  justify-content: space-between;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.purple300};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.lavender},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`;

export const Row = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;

  &.name {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: column;
    }
  }

  &.column {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

export const IconPassword = styled(Icon)`
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
