import CreatableSelect from "react-select/creatable";

import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  gap: 16px;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const ContainerFields = styled.div`
  width: 100%;
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

export const CustomCreatableSelect = styled(CreatableSelect)`
  .control {
    height: 41px;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  font-family: Roboto;
  color: #344054;
  font-size: 15px;
`;
