export const downloadJSON = (jsonData: object, fileName: string) => {
  const jsonString = JSON.stringify(jsonData);
  const blob = new Blob([jsonString], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};
