import { UploadFiles } from "features/UploadFiles/UploadFiles";
import { Wrapper } from "features/common/components/Wrapper";
import GlobalStyle from "styles/globalStyles";

const UploadFilesPage: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <UploadFiles />
    </Wrapper>
  );
};
export default UploadFilesPage;
