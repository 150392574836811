import { TableUserProps } from "./types";

import {
  Row,
  TableContainer,
  Text,
  Grid,
  IconContainer,
  IconStyle,
} from "./TableUser.styles";

export default function TableUser({
  setIsEditModal,
  setIsDeleteModal,
  users,
  setUserIndex,
}: TableUserProps) {
  return (
    <TableContainer>
      {users.map(({ email, role }, index) => (
        <Row>
          <Grid>
            <Text className="ellipsis">{email}</Text>
            <Text>
              {role === "ADMIN"
                ? "Organization Superadmin"
                : "Organization Admin"}
            </Text>
          </Grid>
          <IconContainer>
            <IconStyle
              iconName="edit"
              onClick={() => {
                setIsEditModal(true);
                setUserIndex(index);
              }}
            />
            <IconStyle
              iconName="trash"
              onClick={() => {
                setIsDeleteModal(true);
                setUserIndex(index);
              }}
            />
          </IconContainer>
        </Row>
      ))}
    </TableContainer>
  );
}
