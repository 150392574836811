import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  gap: 16px;
  flex-direction: column;
`;

export const Button = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 90%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;
