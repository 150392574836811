import { Provider } from "react-redux";
import store, { persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { Routes } from "features/common/components/Routes/Routes";
import "./locals/i18n";
import { Toaster } from "react-hot-toast";

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
        <Toaster
          position='top-right'
          toastOptions={{
            duration: 3800,
            style: { padding: "16px" },
          }}
        />
      </PersistGate>
    </Provider>
  );
}

export default App;
