import styled from "styled-components";

import { Icon } from "../Icon";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(
    --dx-footer-gradient,
    linear-gradient(180deg, #3973d8 0%, #1e41b3 50%, #4945bf 100%)
  );
`;

export const Text = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;

export const IconLock = styled(Icon)`
  width: 22.158px;
  height: 32px;
`;
