import { SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import DXInputField from "features/common/components/DXInputField";
import { Dropdown } from "features/common/components/Dropdown/Dropdown";
import { AddUsers } from "features/common/components/Users/AddUsers";
import { DropdownUser } from "features/common/components/Users/UserModals/ModalAddUser/DropdownUser";

import * as Styled from "./Form.styles";

interface FormProps {
  setOrganizationName: Dispatch<SetStateAction<string>>;
  setOrganizationDescription: Dispatch<SetStateAction<string>>;
  setColor: Dispatch<SetStateAction<string>>;
  setOrganizationSize: Dispatch<SetStateAction<string>>;
  setOrganizationImage: Dispatch<SetStateAction<string>>;
  setOrganizationUser: Dispatch<SetStateAction<string>>;
  setUsers: Dispatch<SetStateAction<ICreateUser[]>>;
  organizationName: string;
  organizationUser: string;
  organizationDescription: string;
  organizationSize: string;
  color: string;
  selectedCard: string;
  users: ICreateUser[];
}

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export const Form = ({
  setOrganizationName,
  setOrganizationDescription,
  setColor,
  setOrganizationSize,
  setOrganizationImage,
  setOrganizationUser,
  setUsers,
  organizationUser,
  organizationName,
  organizationDescription,
  organizationSize,
  color,
  selectedCard,
  users,
}: FormProps) => {
  const { t } = useTranslation();

  // const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setColor(e.target.value);
  // };

  const handleChangeOrganizationSize = (event: SelectChangeEvent) => {
    setOrganizationSize(event.target.value.toString());
  };

  const handleChangeOrganizationUser = (event: SelectChangeEvent) => {
    setOrganizationUser(event.target.value.toString());
  };

  return (
    <Styled.FormContainer>
      <Styled.StyledForm>
        <Styled.ColumnContainer>
          <DXInputField
            key={"organization"}
            label={"Organization Name*"}
            id={"organization"}
            type={"text"}
            required
            placeholder={"Enter organization name"}
            value={organizationName || ""}
            onChange={({ target }) => setOrganizationName(target.value)}
          />
          <Dropdown
            title={t("step-Organization-Size")}
            setOrganizationSize={setOrganizationSize}
            organizationSize={organizationSize}
            options="size"
            handleChange={handleChangeOrganizationSize}
            value={organizationSize}
          />
          <DXInputField
            key={"description"}
            label={"Description (optional)"}
            id={"description"}
            type={"text"}
            required={false}
            placeholder={"Enter a description"}
            value={organizationDescription || ""}
            onChange={({ target }) => setOrganizationDescription(target.value)}
          />
          {/* <ImageOrganization setOrganizationImage={setOrganizationImage} /> */}
          {/* <DXColorScheme
            label={t("color-dot-options")}
            colorScheme={colorScheme}
            color={color}
            handleChangeColor={handleColor}
          /> */}
          {selectedCard === "Firm" && (
            <DropdownUser
              title="Your User Type"
              setOrganizationUser={setOrganizationUser}
              handleChange={handleChangeOrganizationUser}
              value={organizationUser}
            />
          )}
          {selectedCard === "Firm" && (
            <AddUsers users={users} setUsers={setUsers} isOrganization />
          )}
        </Styled.ColumnContainer>
      </Styled.StyledForm>
    </Styled.FormContainer>
  );
};
