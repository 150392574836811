import { Box } from "@mui/system";
import DXModal from "../DXModal";
import * as Styled from "./QBRegisteredAccount.styles"
import DXButton from "../DXButton";
import { TextModal } from "features/Steps/Steps/ClientsAccount/ClientAccount.styles";
import DXIcon from "../DXIcon";
import { useHistory } from "react-router-dom";
import { ROUTES } from "features/routes";
import { useState } from "react";
import ModalAddUser from '../Users/UserModals/ModalAddUser/ModalAddUser';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { ModalPlanSettings } from "features/Settings/ModalPlanSettings";
import useCurrentUser from "hooks/useCurrentUser";

export const QBRegisteredAccount = ({ openModal, onClose, userLicensesLeft, accountId, companyId, isFromSignUp }) => {
    const history = useHistory();
    const [addUserModal, setAddUserModal] = useState(false);
    const [openModalPlan, setOpenModalPlan] = useState(false);
    const { currentCompany } = useSelector((state: RootState) => state);
    userLicensesLeft = currentCompany.account ? currentCompany?.account?.license.max_users - currentCompany?.account?.license.used_licenses : userLicensesLeft;
    const orgRole = currentCompany?.account?.role;
    const compRole = currentCompany?.company?.role;
    const rbac = currentCompany?.rbac;
    const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

    const isUpgradePlanDisabled = () => {
        if (isFromSignUp) {
            return false
        }
        return permissions.manage_companies?.features?.upgrade_plan_button === 'disable';
    }
    const { dxUserData } = useCurrentUser();
    console.log({ dxUserData })
    return (
        <>
            <DXModal
                title="Congratulations!"
                onClose={onClose}
                open={openModal}
                className={"clientAccount"}
                isSignUpModal={true}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        py: 4,
                        width: "100%",
                        gap: 4,
                        paddingTop: '80px',
                        paddingBottom: 0
                    }}
                    style={{ padding: "0px 32px" }}
                >
                    <Styled.ContainerBottom>
                        <TextModal>You’ve successfully registered a new QuickBooks Online account. Would you like to add users to this workspace? </TextModal>
                        <DXButton
                            startIcon={<DXIcon name="PlusPurple" style={{ color: "#6938EF" }} />}
                            style={{ backgroundColor: "rgb(225, 215, 251)", color: "#6938EF", cursor: userLicensesLeft < 1 ? "not-allowed" : "pointer", opacity: userLicensesLeft < 1 ? 0.5 : 1 }}
                            variant="contained"
                            size="large"
                            sx={{ maxWidth: "220px" }}
                            onClick={() => userLicensesLeft < 1 ? null : setAddUserModal(true)}
                        >
                            Add User(s)
                        </DXButton>
                        <Styled.Information className="user" sx={{ background: "black" }}>
                            <Styled.Row
                                className="justify-center, upgradeLine"
                            >
                                <Styled.Text className="information">
                                    You have <span style={{
                                        color: 'blue'
                                    }}>{userLicensesLeft} user</span> licenses left.

                                </Styled.Text>
                                <Styled.Text className="information"
                                    onClick={() => {
                                        if (isFromSignUp) {
                                            setOpenModalPlan(true)
                                        } else {
                                            if (!isUpgradePlanDisabled()) {
                                                setOpenModalPlan(true)
                                            }
                                        }
                                    }}
                                    style={{
                                        cursor: isUpgradePlanDisabled() ? 'not-allowed' : 'pointer',
                                        opacity: isUpgradePlanDisabled() ? 0.5 : 1,
                                        color: isUpgradePlanDisabled() ? 'gray' : 'blue',
                                        marginLeft: '0',
                                        paddingLeft: '0'
                                    }}>Upgrade to add more.
                                </Styled.Text>
                            </Styled.Row>
                        </Styled.Information>
                        <DXButton
                            style={{ color: "white" }}
                            variant="contained"
                            color="info"
                            size="large"
                            sx={{ maxWidth: "300px" }}
                            onClick={() => history.push(`${ROUTES.UPLOAD_FILES_PATH}`)}
                        >
                            Continue to Dashboard
                        </DXButton>
                    </Styled.ContainerBottom>
                </Box>
                {addUserModal && (
                    <ModalAddUser
                        userLeft={userLicensesLeft}
                        isOpen={addUserModal}
                        closeModal={() => {
                            setAddUserModal(false);
                            history.push(`${ROUTES.SETTINGS_PATH}`);
                        }
                        }
                        accountId={accountId}
                        companyId={companyId}
                    />
                )}
            </DXModal>

            {openModalPlan && (
                <DXModal
                    title={"Change Plan"}
                    open={openModalPlan}
                    onClose={() => setOpenModalPlan(false)}
                    customWidth={700}
                    children={
                        <ModalPlanSettings
                            setOpenModalPlan={setOpenModalPlan}
                            dxUserData={dxUserData}
                        />
                    }
                />
            )}
        </>
    )
};