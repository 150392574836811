import { Dispatch, SetStateAction, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany, setDXUserData, setLicenses } from "redux/actions";

import DXButton from "features/common/components/DXButton";
import DXCard from "features/common/components/DXCard/Card";
import { getData, post } from "services/api";

import { IDXUserData } from "redux/types";
import { quantityMaxCompanies, quantityMaxUsers } from "utils/quantityLicensesAndUsers";
import {
  Container,
  ContainerModal,
  Information,
  Input,
  Text,
  TextFieldNumber
} from "./Plan.styles";
import { RootState } from "redux/reducers";

interface PlanProps {
  setOpenModalPlan: Dispatch<SetStateAction<boolean>>;
  dxUserData: IDXUserData;
}

export const ModalPlanSettings = ({
  setOpenModalPlan,
  dxUserData,
}: PlanProps) => {
  const [numUsers, setNumUsers] = useState(
    quantityMaxUsers(dxUserData)
  );

  const [numQuickBooks, setNumQuickBooks] = useState(
    quantityMaxCompanies(dxUserData)
  );

  const [selectedCardOption, setSelectedCardOption] = useState(null);
  const { t } = useTranslation();
  const { currentCompany } = useSelector((state: RootState) => state);

  const [plans, setPlans] = useState([
    {
      title: "Billed Monthly",
      price: 0,
      description: "Per month",
      option: "month",
    },
    {
      title: "Billed Annually",
      price: 0,
      description: "Per year",
      option: "year",
    },
  ]);
  const [companyRecurring, setCompanyRecurring] = useState({
    yearId: "",
    monthId: "",
  });
  const [userRecurring, setUserRecurring] = useState({
    yearId: "",
    monthId: "",
  });

  const dispatch = useDispatch();

  const { data: products, isLoading } = useQuery(`getPrices`, () =>
    getData(`/sales/product-mvp`)
  );
  
  useEffect(() => {
    if (!isLoading) {
      products.items.map((item) => {
        if (item.type === "COMPANY" && numQuickBooks) {
          item.strip.map((strip) => {
            let companyPrice = strip.price.find((price) => {
              return (
                price.minimum <= numQuickBooks && price.maximum >= numQuickBooks
              );
            });
            const amount = companyPrice.amount * numQuickBooks;
            if (strip.recurring === "year") {
              setPlans((state) => {
                return [
                  state[0],
                  {
                    title: "Billed Annually",
                    price: amount + companyPrice.fee,
                    description: "Per year",
                    option: "year",
                  },
                ];
              });
            } else {
              setPlans((state) => {
                return [
                  {
                    title: "Billed Monthly",
                    price: amount + companyPrice.fee,
                    description: "Per month",
                    option: "month",
                  },
                  state[1],
                ];
              });
            }
          });
        }
        if (item.type === "USER" && numUsers) {
          item.strip.map((strip) => {
            let userPrice = strip.price.find((price) => {
              return price.minimum <= numUsers && price.maximum >= numUsers;
            });
            const amount = userPrice.amount * numUsers;
            console.log(
              amount,
              "amount",
              plans[1].price,
              "plan[1].price",
              userPrice.fee,
              "fee",
              plans[1].price + amount + userPrice.fee,
              "todo"
            );
            if (strip.recurring === "year") {
              setPlans((state) => {
                return [
                  state[0],
                  {
                    title: "Billed Annually",
                    price: state[1].price + amount + userPrice.fee,
                    description: "Per year",
                    option: "year",
                  },
                ];
              });
            } else {
              setPlans((state) => {
                return [
                  {
                    title: "Billed Monthly",
                    price: state[0].price + amount + userPrice.fee,
                    description: "Per month",
                    option: "month",
                  },
                  state[1],
                ];
              });
            }
          });
        }
      });
    }
  }, [isLoading, numQuickBooks, numUsers]);

  useEffect(() => {
    if (!isLoading) {
      products.items.map((item) => {
        if (item.type === "COMPANY") {
          for (const strip of item.strip) {
            if (strip.recurring === "year") {
              setCompanyRecurring((state) => {
                return { ...state, yearId: strip.stripe_id };
              });
            } else {
              setCompanyRecurring((state) => {
                return { ...state, monthId: strip.stripe_id };
              });
            }
          }
        }
        if (item.type === "USER") {
          for (const strip of item.strip) {
            if (strip.recurring === "year") {
              setUserRecurring((state) => {
                return { ...state, yearId: strip.stripe_id };
              });
            } else {
              setUserRecurring((state) => {
                return { ...state, monthId: strip.stripe_id };
              });
            }
          }
        }
      });
    }
  }, [isLoading]);

  const handleUpgrade = async () => {

    const property = selectedCardOption === "month" ? "monthId" : "yearId"

    const body = {
      membership_account_id: dxUserData.accounts[0].id,
      company_price: companyRecurring[property],
      user_price: userRecurring[property],
      company_qty: numQuickBooks,
      user_qty: numUsers,
    }

    const response = await post("sales/subscription", body);

    if (response) {
      dispatch(setLicenses({ licenses: numQuickBooks }));
      setOpenModalPlan(false);

      toast.success("Plan updated successfully!");
      const resp = await getData(`/memberships/user/profile`);
      const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
      const company = account?.companies?.find((comp) => comp?.id === currentCompany?.company?.id);
      dispatch(setDXUserData(resp));
      dispatch(setCurrentCompany({ account, company }))
      setTimeout(() => window.location.reload(), 2000)

    } else {
      toast.error(`Plan could not be updated.`);
      console.error("Failed to create checkout session");
    }
  };

  const handleCardClick = (option) => {
    setSelectedCardOption(selectedCardOption === option ? null : option);
  };

  const handleChangeCompany = (value) => {
    if (value > 0) {
      setNumQuickBooks(value);
    } else {
      setNumQuickBooks(1);
    }
  };

  return (
    <Container>
      <Information>
        <Input>
          <Text>{t("step-Plan-Companies")}</Text>
          <TextFieldNumber
            type="number"
            label=""
            min={1}
            inputProps={{ min: 0 }}
            defaultValue={numQuickBooks}
            onChange={(event) => handleChangeCompany(event.target.value)}
          />
        </Input>
        <Input>
          <Text>{t("step-Plan-Users")}</Text>
          <TextFieldNumber
            type="number"
            label=""
            min={1}
            defaultValue={numUsers}
            inputProps={{ min: 0 }}
            onChange={(event) => setNumUsers(event.target.value)}
          />
        </Input>
      </Information>
      <ContainerModal>
        {plans.map(({ title, price, description, option }) => (
          <DXCard
            title={title}
            price={price === 0 ? "$" : `$${price}`}
            description={description}
            isSelected={selectedCardOption === option}
            onClick={() => handleCardClick(option)}
            isPlan
            className="plan"
            isSettings
          />
        ))}
      </ContainerModal>
      <DXButton
        onClick={() => handleUpgrade()}
        disabled={!numQuickBooks || !numUsers || !selectedCardOption}
        variant="contained"
        sx={{ minWidth: "140px" }}
      >
        Update Plan
      </DXButton>
    </Container>
  );
};
