import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Container = muiStyled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const Text = muiStyled(Box)`
  color: "#1d2939";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const StepContainer = muiStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.06),
    0px 2px 2px 0px rgba(16, 24, 40, 0.03);

    &.isUpload{
      box-shadow:  0px 0px 0px 0px transparent;
    }
`;

export interface StepProps {
  color: string;
}

export const StepIcon = styled.div<StepProps>`
  display: flex;
  width: 92px;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px 40px 40px 8px;
  color: white;
  background: ${({ color }) => color};
  font-weight: 500;
`;

export const Information = muiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TextInformation = muiStyled(Box)`
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  &.step3{
    color: black
  }
`;

export const ButtonContainer = muiStyled(Box)`
  margin-right: 24px;
`;

export const Subtitle = muiStyled(Box)`
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &.step3{
    color:black
  }
`;

export const ContainerText = muiStyled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div`
  border-radius: 6px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 24px;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const TextIcon = styled.div`
  display: flex;
`;
