import { StepProps } from "./types";

import {
  StepItem,
  Text,
  Subtitle,
  TextContainer,
  IconStep,
} from "./Steps.styles";

const stepIcons = {
  inactive: "step",
  active: "step-inProgress",
  done: "step-Done",
};

export const Step = ({ title, subtitle, index, currentStep }: StepProps) => {
  let status: "inactive" | "active" | "done" = "inactive";
  if (index + 1 < currentStep) status = "done";
  else if (index + 1 === currentStep) status = "active";

  return (
    <StepItem>
      <IconStep size="L" iconName={stepIcons[status]} className={status} />
      <TextContainer>
        <Text className={status}>{title}</Text>
        <Subtitle className={status}>{subtitle}</Subtitle>
      </TextContainer>
    </StepItem>
  );
};
