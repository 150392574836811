import { Dispatch, SetStateAction, useState } from "react";
import { Box } from "@mui/material";

import DXButton from "features/common/components/DXButton";
import DXModal from "features/common/components/DXModal/DXModal";

export interface CompanyModalProps {
  isOpen: boolean;
  setIsClose: Dispatch<SetStateAction<boolean>>;
  setTabValue: Dispatch<SetStateAction<number>>;
}
export const CompanyModal = ({
  isOpen,
  setIsClose,
  setTabValue,
}: CompanyModalProps) => {
  return (
    <DXModal
      title={"To add, remove, or manage additional users, please add a company to your workspace first."}
      open={isOpen}
      onClose={() => setIsClose(false)}
      children={
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingTop: 4,
            }}
          >
            <DXButton
              onClick={() => setTabValue(1)}
              variant="contained"
              sx={{ minWidth: "140px" }}
            >
              Add New Client Company
            </DXButton>
          </Box>
        </>
      }
    />
  );
};
