import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const DXTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className, tooltip: `${tooltipClasses.tooltip} customTooltip` }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
  [`& .customTooltip`]: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    boxShadow: theme.shadows[1],
    fontSize: '16px !important',
    maxWidth: "600px !important",
    backgroundColor: theme.palette.info.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    fontSize: 12,
    textAlign: "center",
    padding: "10px !important",
    maxWidth: "145px",
  },
}));

export default DXTooltip;