import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const FileTitle = muiStyled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const Title = muiStyled(Box)`
  color: #1d2939;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

interface IconProps {
  color: string;
  colorTitle: string;
}

export const IconStep = styled.div<IconProps>`
  width: 62px;
  height: 28px;
  background-color: ${({ color }) => color};
  color: ${({ colorTitle }) => colorTitle};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
`;
