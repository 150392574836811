import DXModal from "../DXModal";
import { Icon } from "../Icon";
import { Body, ContainerFields, Information, Row, Text, Wrapper, Input } from './ModalAddQBAccount.styles';
import { DropdownCompanyRoles } from "../DropdownCompanyRoles/DropdownCompanyRoles";
import { useTranslation } from "react-i18next";
import DXButton from "../DXButton";
import DXInputField from "../DXInputField";
import { useState } from "react";
import { DropdownQBAccountTypes } from "../DropdownQBAccountTypes/DropdownQBAccountTypes";
import useConnectQuickbooks from "hooks/useConnectQuickbooks";
import { useMutation } from "react-query";
import { createUser, createCompany } from "features/Steps/Steps/ClientsAccount/services";
import { post } from "services/api";

export default function ModalAddQBAccount({
  onClose,
  openModal,
  accountId,
  cognitoId,
  onCompanyIdChange,
  companyLicensesLeft
}) {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [qbAccountType, setQbAccountType] = useState("");
  const [userRole, setUserRole] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const { QBConnect } = useConnectQuickbooks();

  function useConnectQBO(accountId, cognitoId, qbAccountType) {
    const isCheckedAccounting = qbAccountType !== "QBO";
    console.log({
      message: "Creating a company"
    })
    const mutation = useMutation(() =>
      createCompany({
        name: companyName,
        account_id: accountId,
        color: "",
        logo: "",
        role: userRole
      })
      , {
        onSuccess: async (response) => {
          console.log({
            message: "Company created"
          })
          console.log({
            response: response.data
          })
          console.log(response.data.id);
          const companyId = response.data.id;
          setCompanyId(companyId);
          onCompanyIdChange(companyId);
          console.log({
            message: "Connecting do QuickBooks",
            accountId,
            cognitoId,
            isCheckedAccounting,
            companyId
          })
          await QBConnect(accountId, cognitoId, isCheckedAccounting, companyId, true);
        }
      });

    return mutation;
  }

  const handleSubmitUser = async (user, companyId) => {
    try {
      const response = await post("/memberships/user", {
        email: user.email,
        account_id: accountId,
        account_role: "ORG_ADMIN",
        companies: [
          {
            company_id: companyId,
            company_role: user.role,
          },
        ],
        name: `${user.firstName} ${user.lastName}`,
      })
    } catch (error) {
      console.log(error);
    }
  }

  const { mutate } = useConnectQBO(accountId, cognitoId, qbAccountType);

  const handleQBAccountTypeChange = (target) => {
    console.log({
      target: target.value
    })
    setQbAccountType(target.value)
  }

  const handleUserPermissionsChange = (target) => {
    console.log({
      userRole: target.value
    })
    setUserRole(target.value)
  }

  const handleCompanyNameChange = (target) => {
    console.log({ companyName: target })
    setCompanyName(target)
  }

  const handleConnect = () => {
    mutate(accountId, cognitoId);
  };


  return (
    <>
      <DXModal
        title="Add A New QuickBooks Account"
        onClose={onClose}
        open={openModal}
        className={"clientAccount"}
        isSignUpModal={true}
        style={{
          alignContent: "center",
          margin: "auto",
        }}
        sx={{
          margin: "100px",
        }}>
        <Row className="justify-center">
          <Information className="user">
            <Row>
              <Icon iconName="building" size="M" />
              <Text className="information">
                You have {companyLicensesLeft} company licenses left
              </Text>
            </Row>
          </Information>
        </Row>
        <Body>
          <Wrapper>
            <ContainerFields
              style={{ padding: "0px 32px" }}>
              <DXInputField
                label="QuickBooks Company Name*"
                id="organizationName"
                type="text"
                required
                placeholder="Enter company name"
                value={companyName}
                onChange={(event) => handleCompanyNameChange(event.target.value)}
              />
            </ContainerFields>
          </Wrapper>
          <Wrapper>
            <ContainerFields
              style={{ padding: "0px 32px" }}>
              <DropdownQBAccountTypes
                className="small"
                title={t("quickbooks-account-type")}
                handleChange={(event) => handleQBAccountTypeChange(event.target)}
              />
            </ContainerFields>
          </Wrapper>
          <Wrapper>
            <Wrapper>
              <ContainerFields
                style={{ padding: "0px 32px" }}>
                <DropdownCompanyRoles
                  className="small"
                  title={t("set-your-user-permissions")}
                  handleChange={(event) => handleUserPermissionsChange(event.target)}
                />
              </ContainerFields>
            </Wrapper>
          </Wrapper>
          <Row className="justify-center">
            <Information className="needHelp">
              <Row>
                <Text
                  className="needHelp"
                  onClick={() => setInfoModal(true)}
                  style={{ cursor: "pointer" }}>
                  Need help? How do I know which QuickBooks account type I have?
                </Text>
              </Row>
            </Information>
          </Row>
          <Row
            className="justify-center"
            style={{ marginTop: "20px" }}>
            <DXButton
              variant="contained"
              size="large"
              sx={{ minWidth: "200px", marginRight: "50px", background: "#099250", '&:hover': { background: '#099250' } }}
              onClick={() => handleConnect()}
            >
              Connect to QuickBooks
            </DXButton>
            <DXButton
              variant="contained"
              color="info"
              size="large"
              sx={{ minWidth: "220px", background: "#099250", '&:hover': { background: '#099250' } }}
              onClick={() => onClose()}
            >
              Connect later
            </DXButton>
          </Row>
        </Body>

      </DXModal>
      {infoModal && (
        <DXModal title={""} open={infoModal} onClose={() => setInfoModal(false)}>
          <Input>The QuickBooks Online Payments module is an add-on to standard QuickBooks Online plans that enables features related to the remittance of payments. To upgrade your QuickBooks plan to include payments please <a href="https://quickbooks.intuit.com/money/see-plans/" target="_blank">click here</a>.<br /><br />

            To confirm if your account has Payments access, please check your product subscription tab or Billing tab within QuickBooks which will indicate "QuickBooks Online Payments."<br /><br />

            Within DatumXpress, this module allows you to access templates associates with Bill Payments, Credit Card Charges, and Transfers which are otherwise disabled. </Input>
        </DXModal>
      )}
    </>
  )
};