import React from "react";

import DXButton from "features/common/components/DXButton";
import { InputField } from "features/common/components/InputField/InputField";
import { Trash } from "assets/icons";

import { Container, ResetPasswordButton, Text } from "./styles";
import { Row, Column } from "../styles";
import { ContainerFields } from "../../ModalAddUsers/styles";
import { IEditUser } from "../EditUser";
import { inputFields } from "../utils";

interface UserInfoType {
  user: IEditUser;
  handleInputChange: any;
  setIsOpenRemoveUserAllAcounts: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserInfo = ({
  user,
  handleInputChange,
  setIsOpenRemoveUserAllAcounts,
}: UserInfoType) => {
  return (
    <Container>
      <Column gap="16px">
        <Row className="space-between align-items-center">
          <Text>{user?.name}</Text>
          <DXButton
            children="Delete user"
            size="large"
            variant="contained"
            color="error"
            startIcon={<img src={Trash} />}
            onClick={() => setIsOpenRemoveUserAllAcounts(true)}
          />
        </Row>
        <Row className="space-between align-items-end" gap="16px">
          <Row className="space-between" gap="16px">
            {inputFields.map((field) => (
              <ContainerFields key={field.id}>
                <InputField
                  label={field.label}
                  id={`${field.id}`}
                  type={field.type}
                  required={field.required}
                  placeholder={field.placeholder}
                  value={user?.[field?.id]}
                  disabled={field.id === "email"}
                  onChange={(event) =>
                    handleInputChange(field.id, event.target.value)
                  }
                />
              </ContainerFields>
            ))}
          </Row>
          {/* <ResetPasswordButton>Reset password</ResetPasswordButton> */}
        </Row>
      </Column>
    </Container>
  );
};
