import styled from "styled-components";

import { DataGrid } from "@mui/x-data-grid";

export const DataGridTable = styled(DataGrid)`
  .MuiToolbar-root {
    color: var(--gray-700, #344054) !important;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 20px;
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    display: flex;
  }
  &.MuiDataGrid-root .MuiDataGrid-withBorderColor {
    height: 44px;
    min-height: 52px !important;
    max-height: 52px !important;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
  }
  &.MuiDataGrid-root .MuiDataGrid-cell--textRight {
    justify-content: center;
  }
  &.MuiDataGrid-root .MuiDataGrid-cell--textRight {
    justify-content: center;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell--textCenter {
    justify-content: center;

    :first-child {
      justify-content: flex-start;
    }
  }

  .MuiDataGrid-columnHeader--alignRight
    .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row !important;
    justify-content: flex-start !important;
  }

  &.MuiDataGrid-root {
    justify-content: center;
    display: flex;
    border-radius: 0px;
    overflow: hidden;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-width: 0px;
    border-top: 1px solid var(--gray-200, #eaecf0);
  }

  .MuiDataGrid-virtualScrollerContent {
    background: white;
  }

  .MuiDataGrid-virtualScroller {
    height: 100%;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiTablePagination-root {
    width: 100%;
  }

  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .MuiTablePagination-selectLabel {
    font-weight: 400 !important;
  }

  .MuiTablePagination-displayedRows {
    font-weight: 400 !important;
  }

  .MuiTablePagination-displayedRows {
    width: 78%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1500px) {
      width: 54%;
    }
  }

  .MuiDataGrid-selectedRowCount {
    display: none;
  }

  .css-194a1fa-MuiSelect-select-MuiInputBase-input {
    border-radius: 6px !important;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--gray-500, #667085);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiDataGrid-columnHeaders {
    background: var(--gray-25, #fcfcfd);
  }

  .MuiDataGrid-columnHeader {
    padding: 16px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    color: var(--gray-700, #344054);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .MuiDataGrid-cell {
    color: var(--gray-700, #344054);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    min-height: 72px !important;
    max-height: 72px !important;
    padding: 16px 16px 16px 16px !important;
  }

  &.notCheckbox {
    .MuiDataGrid-cellContent {
      padding: 7px;
    }
  }

  .MuiDataGrid-row {
    background-color: white;

    display: flex;
    align-items: center;
  }

  .MuiDataGrid-row:hover {
    background-color: var(--purple-50, #f4f3ff) !important;
  }

  .MuiSvgIcon-root {
    border-radius: 2px;
    width: 21px;
    height: 21px;
    right: 6px;
    top: 5px;
  }

  .MuiDataGrid-sortIcon {
    color: #475467;
  }

  .MuiDataGrid-cellCheckbox {
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
  }
`;
