import { Route, Switch, Redirect } from "react-router-dom";

import { ROUTES } from "features/routes";
import SignUpPage from "pages/SignUpPage";
import UploadFilesPage from "pages/UploadFilesPage";
import TemplateLibraryPage from "pages/TemplateLibraryPage";
import SettingsPage from "pages/SettingsPage";
import IntegrationsPage from "pages/IntegrationsPage";
import SignInPage from "pages/SignInPage";
import EditUserPage from "pages/EditUserPage";
// import ActivityHistory from "pages/ActivityHistoryPage";

export const UserRoutes = (): JSX.Element => {
  const {
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    ROOT_PATH,
    UPLOAD_FILES_PATH,
    TEMPLATE_PATH,
    // ACTIVITY_PATH,
    SETTINGS_PATH,
    INTEGRATIONS_PATH,
    EDIT_USER_PATH,
  } = ROUTES;

  return (
    <Switch>
      <Redirect exact from={ROOT_PATH} to={SIGN_IN_PATH} />
      <Route exact path={SIGN_IN_PATH}>
        <SignInPage />
      </Route>
      <Route exact path={SIGN_UP_PATH}>
        <SignUpPage />
      </Route>
      <Route exact path={UPLOAD_FILES_PATH}>
        <UploadFilesPage />
      </Route>
      <Route exact path={TEMPLATE_PATH}>
        <TemplateLibraryPage />
      </Route>
      {/* <Route exact path={ACTIVITY_PATH}>
        <ActivityHistory />
      </Route> */}
      <Route exact path={INTEGRATIONS_PATH}>
        <IntegrationsPage />
      </Route>
      <Route exact path={SETTINGS_PATH}>
        <SettingsPage />
      </Route>
      <Route exact path={EDIT_USER_PATH}>
        <EditUserPage />
      </Route>
    </Switch>
  );
};
