import * as StyledStep from "features/Steps/Steps.styles";
import DXChip from "features/common/components/DXChip/DXChip";
import useCurrentUser from "hooks/useCurrentUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { quantityLicensesCompanies, quantityLicensesUsers } from "utils/quantityLicensesAndUsers";
import { StepProps } from "../types";
import { Form } from "./Form/Form";

export const ClientsAccount = ({ setCurrentStep }: StepProps) => {

  const { t } = useTranslation();
  const { dxUserData } = useCurrentUser()

  const [licenses, setLicenses] = useState<number>()
  const [allUsers, setAllUsers] = useState<string[]>([])
  const [userLicensesLeft, setUserLicensesLeft] = useState<number>()

  useEffect(() => {
    setLicenses(quantityLicensesCompanies(dxUserData))
    setUserLicensesLeft(quantityLicensesUsers(dxUserData))
  }, [dxUserData])

  return (
    <StyledStep.PageContainer>
      <StyledStep.StepContainer>
        <StyledStep.Content>
          <StyledStep.Title>
            <StyledStep.StepTitle>
              {t("step-Client-Account-Title")}
            </StyledStep.StepTitle>
          </StyledStep.Title>

          <Form
            setCurrentStep={setCurrentStep}
            setLicenses={setLicenses}
            companiesLicenses={licenses}
            setAllUsers={setAllUsers}
            allUsers={allUsers}
            setUserLicensesLeft={setUserLicensesLeft}
            userLicensesLeft={userLicensesLeft}
          />
        </StyledStep.Content>
      </StyledStep.StepContainer>
    </StyledStep.PageContainer>
  );
};
