export const formatUserData = (users) =>
  users?.items?.map(({ id, name, email, role: roleName }, index) => {
    const userName = name?.charAt(0).toUpperCase() + name?.slice(1);
    let role = roleName.toLowerCase();
    role = role.charAt(0).toUpperCase() + role.slice(1);
    return {
      id,
      email,
      userName,
      role,
    };
  }) || [];

export const formatManageUserData = (users) =>
  users.map(({ id, name, email, number_of_workspaces: workspaces }, index) => {
    const userName = name.charAt(0).toUpperCase() + name.slice(1);
    return {
      id,
      email,
      workspaces,
      userName,
    };
  }) || [];
