import styled from "styled-components";

import { common } from "theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${common.colors.white};
  display: flex;
  padding: 24px;
  align-items: center;
  border-radius: 12px;
`;

export const Text = styled.div`
  color: ${common.colors.black};
  font-family: ${common.fonts.primary};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
`;

export const ResetPasswordButton = styled.div`
  border-radius: 6px;
  background: ${common.colors.gray100};
  display: flex;
  padding: 15px 18px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${common.colors.gray700};
  font-family: ${common.fonts.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  max-height: 54px;
  cursor: pointer;
`;
