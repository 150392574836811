import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.purple50};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray900};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  &.subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &.information {
    color: ${({ theme }) => theme.colors.gray700};
    text-align: right;
    line-height: 22px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Information = styled.div`
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
  width: fit-content;

  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  background: ${({ theme }) => theme.colors.white};

  &.user {
    border: 1px solid ${({ theme }) => theme.colors.purple100};
    background: ${({ theme }) => theme.colors.purple50};
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  &.gap {
    gap: 16px;
  }
  &.justify-center {
    justify-content: center;
  }
`;
