import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Modal } from "features/common/components/Modal/Modal";
import { InputField } from "../../../InputField/InputField";
import { Icon } from "../../../Icon";
import { inputFieldsClient } from "./utils";
import { ModalProps } from "../types";
import DXButton from "features/common/components/DXButton";
import { DropdownUser } from "./DropdownUser";
import { getData } from "services/api";

import { Information, Row, Text } from "../../AddUsers.styles";
import {
  Body,
  Container,
  ContainerFields,
  ContainerSelect,
  CustomCreatableSelect,
  Label,
  Wrapper,
} from "./ModalAddUser.styles";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export default function ModalAddUserClient({
  isOpen,
  closeModal,
  userLeft,
  className,
  users,
  setUsers,
  accountId,
}: ModalProps) {
  const [isUserListValid, setIsUserListValid] = useState(false);
  const { t } = useTranslation();

  const { data: usersData, isLoading } = useQuery(`getUsers`, () =>
    getData(`memberships/account/${accountId}/user`)
  );

  const options =
    usersData?.response.map((user) => ({
      value: user.email,
      label: user.email,
    })) || [];

  const addUser = () => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  };

  const validateList = (list: ICreateUser[]): boolean => {
    return list.every((user) => {
      return Object.values(user).every((value) => value.trim() !== "");
    });
  };

  const handleInputChange = (index, fieldId, value) => {
    const newUsers = [...users];
    newUsers[index][fieldId] = value;
    setUsers(newUsers);
  };

  const handleEmailChange = (index, newValue) => {
    const newUsers = [...users];
    const user = usersData.response.find((u) => u.email === newValue?.label);

    if (newValue && newValue.label && user) {
      newUsers[index].email = newValue.label;

      const [first, last] = user.name.split(" ");
      newUsers[index].firstName = first || "";
      newUsers[index].lastName = last || "";

      setUsers(newUsers);
    } else if (newValue && newValue.label) {
      newUsers[index].email = newValue.label;
      setUsers(newUsers);
    } else {
      newUsers[index] = {
        email: "",
        firstName: "",
        lastName: "",
        role: "",
      };
      setUsers(newUsers);
    }
  };

  useEffect(() => {
    validateList(users) ? setIsUserListValid(true) : setIsUserListValid(false);
  }, [users]);

  useEffect(() => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  }, []);

  const handleCloseModal = () => {
    users.pop();
    closeModal();
  };

  const handleCloseModalSuccess = () => {
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <Modal className={className} onClose={handleCloseModal}>
          <>
            <Modal.Header onClose={handleCloseModal}>
              {t("add-user")}
            </Modal.Header>
            <Information className="user">
              <Row>
                <Icon iconName="user-02" size="M" />
                <Text className="information">
                  You have {userLeft} user license(s) left.
                </Text>
              </Row>
            </Information>
            <Modal.Body>
              <Wrapper>
                {users.map((user, index) => (
                  <Body key={index}>
                    <ContainerSelect>
                      <Label>Email</Label>
                      <CustomCreatableSelect
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "41px",
                          }),
                        }}
                        isClearable
                        placeholder="Select or add a new email"
                        required
                        onChange={(newValue) =>
                          handleEmailChange(index, newValue)
                        }
                        options={options}
                      />
                    </ContainerSelect>
                    {inputFieldsClient.map((field) => (
                      <ContainerFields key={field.id}>
                        <InputField
                          label={field.label}
                          id={`${field.id}-${index}`}
                          type={field.type}
                          required={field.required}
                          placeholder={field.placeholder}
                          value={user[field.id]}
                          onChange={(event) =>
                            handleInputChange(
                              index,
                              field.id,
                              event.target.value
                            )
                          }
                        />
                      </ContainerFields>
                    ))}
                    <ContainerFields>
                      <DropdownUser
                        title={t("user-type")}
                        value={user.role}
                        className="small"
                        handleChange={(event) =>
                          handleInputChange(index, "role", event.target.value)
                        }
                      />
                    </ContainerFields>
                  </Body>
                ))}
                <DXButton
                  type="button"
                  variant="text"
                  children="Add Another User(s)"
                  onClick={addUser}
                  disabled={users.length >= userLeft}
                />
              </Wrapper>
            </Modal.Body>
            <Modal.ActionButtons>
              <Container>
                <DXButton
                  type="button"
                  variant="contained"
                  children="Add User(s)"
                  disabled={userLeft <= 0 || !isUserListValid}
                  onClick={handleCloseModalSuccess}
                />
              </Container>
            </Modal.ActionButtons>
          </>
        </Modal>
      )}
    </>
  );
}
