import styled from "styled-components";
import { Icon } from "../Icon";

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 153%;
  color: black;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
  backdrop-filter: blur(5px);
`;

export const Overlay = styled.div`
  align-items: center;
  height: 100%;
  left: 0;
  opacity: 0.5;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 60%;
  z-index: 7;
`;

export const ModalContent = styled.div`
  display: flex;
  max-width: 1012px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--base-white, #fff);
  padding: 0px 24px 40px 24px;
  /* Shadow/xl */
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  &.edit {
    width: 40%;
  }

  &.delete {
    width: 406px;
  }

  &.addUser {
    margin-left: 250px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 24px 0px 0px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  align-self: stretch;
`;

export const ActionsContainer = styled.div``;

export const IconClose = styled(Icon)`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
