interface AccessControl {
    enable?: string[];
    hide?: string[];
    disable?: string[];
    limited?: string[];
}

type PermissionOptions = 'enable' | 'hide' | 'disable' | 'limited';

export interface Permissions {
    main_dashboard: {
        top_bar?: {
            add_company?: PermissionOptions,
            switch_company?: PermissionOptions,
            settings?: PermissionOptions,
        }
        side_bar?: {
            template_library?: PermissionOptions,
            upload_files?: PermissionOptions,
            faqs?: PermissionOptions,
            settings?: PermissionOptions,
            integrations?: PermissionOptions,
            logout?: PermissionOptions
        }
    },
    my_profile: {
        my_organization?: {
            role?: PermissionOptions,
            actions?: PermissionOptions,
            number_of_users?: PermissionOptions,
            manage_users?: PermissionOptions,
            shortcuts?: PermissionOptions,
            tab?: PermissionOptions
            delete_organization?: PermissionOptions
        }
        my_client_account?: {
            logout?: PermissionOptions,
            request_higher_access?: PermissionOptions,
            number_of_users?: PermissionOptions,
            edit_account_details?: PermissionOptions,
            delete_account?: PermissionOptions,
            actions?: PermissionOptions
            manage_users?: PermissionOptions,
            role?: PermissionOptions,
            tab?: PermissionOptions
            workspace_name?: PermissionOptions
        }
        my_subscription?: {
            change_plan?: PermissionOptions,
        },
        personal_settings?: {
            change_password?: PermissionOptions,
        }
    },
    manage_companies: {
        organization_box?: {
            email?: PermissionOptions,
            role?: PermissionOptions,
            delete_account?: PermissionOptions,
            name?: PermissionOptions,
            add_user?: PermissionOptions,
            action_edit?: PermissionOptions,
            tab?: PermissionOptions
            action_delete: PermissionOptions
            edit_account_details?: PermissionOptions,
        },
        company_box?: {
            delete_account?: PermissionOptions,
            name?: PermissionOptions,
            actions?: PermissionOptions,
            edit_everyone?: PermissionOptions,
            delete_yourself?: PermissionOptions,
            delete_everyone?: PermissionOptions,
            edit_yourself?: PermissionOptions,
            email?: PermissionOptions,
            edit_account_details?: PermissionOptions,
            role?: PermissionOptions,
            tab?: PermissionOptions
            add_user?: PermissionOptions,
        },
        features?: {
            filter_a_z?: PermissionOptions,
            client_account_button?: PermissionOptions,
            upgrade_plan_button?: PermissionOptions,
            search_bar?: PermissionOptions,
        }
    },
    billing: {
        invoices?: {
            export_data?: PermissionOptions,
            invoice_history?: PermissionOptions,
            download_invoices?: PermissionOptions,
        },
        billing_details?: {
            full_data?: PermissionOptions,
            edit_plan_button?: PermissionOptions
        }
    };
    manage_plans: {
        user_licenses?: {
            upgrade_plan?: PermissionOptions,
            available_licenses?: PermissionOptions,
            add_user_workspace?: PermissionOptions,
        },
        quickbooks_licenses?: {
            add_client_button?: PermissionOptions,
            upgrade_plan?: PermissionOptions,
        },
        plan_details?: {
            change_plan?: PermissionOptions,
        },
        payment?: {
            upgrade_payment?: PermissionOptions,
        }
        add_user_workspace?: PermissionOptions,
        add_client_button?: PermissionOptions,
        available_licenses?: PermissionOptions,
        upgrade_payment?: PermissionOptions,
        upgrade_plan?: PermissionOptions,
        change_plan?: PermissionOptions
    },
    manage_users: {
        features_2?: {
            role?: PermissionOptions,
            actions?: PermissionOptions,
            edit_yourself?: PermissionOptions,
            delete_everyone?: PermissionOptions,
            number_of_workspaces?: PermissionOptions,
            list_users_organization?: PermissionOptions,
            email_adresses?: PermissionOptions,
            organization_name?: PermissionOptions,
            edit_everyone?: PermissionOptions,
            delete_yourself?: PermissionOptions,
        },
        features?: {
            add_new_user_button?: PermissionOptions,
            search_bar?: PermissionOptions,
            filter_a_z?: PermissionOptions,
            upgrade_plan_button?: PermissionOptions,
        }
    }
}

export const getPermissionsByOrgAndCompRole = (orgRole: string, compRole: string, rbac: any): Permissions => {
    const permissions: Permissions = {
        main_dashboard: {},
        my_profile: {},
        manage_companies: {},
        billing: {},
        manage_plans: {},
        manage_users: {},
    };
    if (!rbac) {
        return;
    }
    if (!orgRole) {
        orgRole = 'ORG_NO_PERMISSIONS';
    }

    if (!compRole) {
        compRole = 'COMP_NO_PERMISSIONS';
    }
    Object.entries(rbac).forEach(([section, features]) => {
        if (typeof features !== 'object' || features === null) {
            return;
        }
        Object.entries(features).forEach(([feature, subFeatures]) => {
            if (typeof subFeatures !== 'object' || subFeatures === null) {
                return;
            }
            Object.entries(subFeatures).forEach(([subFeature, access]) => {
                const accessControl = access as AccessControl;
                const orgEnabled = accessControl.enable?.includes(orgRole);
                const compEnabled = accessControl.enable?.includes(compRole);
                const orgHide = accessControl.hide?.includes(orgRole);
                const compHide = accessControl.hide?.includes(compRole);
                const orgDisabled = accessControl.disable?.includes(orgRole);
                const compDisabled = accessControl.disable?.includes(compRole);
                const roleNotPresent = !(orgEnabled || compEnabled || orgHide || compHide || orgDisabled || compDisabled);
    
                if (!permissions[section]) {
                    permissions[section] = {};
                }
                if (!permissions[section][feature]) {
                    permissions[section][feature] = {};
                }
    
                if (orgEnabled || compEnabled) {
                    permissions[section][feature][subFeature] = 'enable';
                } else if (orgHide || compHide) {
                    permissions[section][feature][subFeature] = permissions[section][feature][subFeature] !== 'enable' ? 'hide' : 'enable';
                } else if (orgDisabled || compDisabled || roleNotPresent) {
                    permissions[section][feature][subFeature] = permissions[section][feature][subFeature] !== 'enable' && permissions[section][feature][subFeature] !== 'hide' ? 'disable' : permissions[section][feature][subFeature];
                }
            });
        });
    });
    return permissions;
}