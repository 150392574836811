import api from "services/api";

interface CreateCompanyOrFirmInput {
  name: string;
  account_id: string;
  logo: any;
  color: string;
  role?: string;
}

export const createCompany = async ({
  name,
  account_id,
  logo,
  color,
  role
}: CreateCompanyOrFirmInput) => {
  try {
    const response = await api.post("/memberships/company", {
      name,
      account_id,
      logo,
      color,
      role
    });

    return response;
  } catch (error) {
    console.error("Error in createCompany:", error);
  }
};

export const createFirm = async ({
  name,
  account_id,
  logo,
  color,
}: CreateCompanyOrFirmInput) => {
  try {
    const response = await api.post("/memberships/firm", {
      name,
      account_id,
      logo,
      color,
    });

    return response;
  } catch (error) {
    console.error("Error in createCompany:", error);
  }
};

export const createUser = ({
  email,
  name,
  account_role,
  account_id,
  companies,
}: {
  email: string;
  name: string;
  account_role: string;
  account_id: string;
  companies: [{ company_id: string; company_role: string }];
}) =>
  api.post("memberships/user", {
    email,
    account_role,
    name,
    account_id,
    companies,
  });
