import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { StyledThemeProvider } from "theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import muiTheme from "./theme/mui-theme";
const APP_ID = "zvmp41ux";
export const queryClient = new QueryClient({});

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<StyledThemeProvider>
			<ThemeProvider theme={muiTheme}>
				<BrowserRouter>
					<IntercomProvider appId={APP_ID}>
						<App />
					</IntercomProvider>
				</BrowserRouter>
			</ThemeProvider>
		</StyledThemeProvider>
	</QueryClientProvider>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
