import React, { useEffect, useState } from "react";

import { Header } from "./Header";
import { UserInfo } from "./UserInfo";
import { UserCompanyTable } from "./UserCompanyTable";
import { MainLayout } from "features/common/components/MainLayout/MainLayout";
import { ModalDeleteCompany } from "features/Settings/ManageCompanies/ModalDeleteCompany";

import { Container, Wrapper } from "./styles";
import { useMutation, useQuery } from "react-query";
import { deleteData, getData, put } from "services/api";
import { useHistory, useParams } from "react-router-dom";
import { queryClient } from "index";

export interface IEditUser {
  id: string;
  cognito_id: string | null;
  email: string;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  name: string;
  companies: {
    name: string;
    role: string;
    company_x_user_id: string;
    company_id: string;
  }[];
}

export const EditUser = () => {
  const history = useHistory();
  const { data: userData, isLoading } = useQuery(`getUser`, () =>
    getData(`/memberships/user/${user_id}`)
  );
  const [user, setUser] = useState<IEditUser>(userData);
  const [companyXUserId, setCompanyXUserId] = useState();

  const [isOpenRemoveUser, setIsOpenRemoveUser] = useState<boolean>(false);
  const [isOpenRemoveUserAllAccounts, setIsOpenRemoveUserAllAcounts] =
    useState<boolean>(false);
  const { user_id } = useParams<{ user_id: string }>();

  const handleInputChange = (fieldId, value, index?: number) => {
    const newUser = { ...user };
    if (index !== undefined) newUser.companies[index][fieldId] = value;
    else newUser[fieldId] = value;
    setUser(newUser);
  };

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const handleDeleteUser = (user_id) => {
    deleteData(`memberships/user/${user_id}`)
      .then(() => {
        setIsOpenRemoveUserAllAcounts(false);
        history.push({
          pathname: "/settings",
          state: {
            tabValue: 2,
          },
        });
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const handleDeleteUserCompany = (companyXUserId) => {
    deleteData(`memberships/userXcompany/${companyXUserId}`)
      .then(() => {
        setIsOpenRemoveUser(false);
        queryClient.invalidateQueries(`getUser`);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const handleSubmitUser = async () => {
    try {
      for (const company of user.companies) {
        await put(`/memberships/userXcompany/${company.company_x_user_id}`, {
          role: company.role,
        });
      }

      await put(`/memberships/user/${userData?.id}`, {
        name: user.name,
        //ME FALTA EL ROL PERO NO LO TENGO porq soy muchos roles
      });

      history.push("/settings");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <MainLayout>
        <Container>
          <Header onSubmit={handleSubmitUser} />
          <Wrapper>
            <UserInfo
              user={user}
              handleInputChange={handleInputChange}
              setIsOpenRemoveUserAllAcounts={setIsOpenRemoveUserAllAcounts}
            />
            <UserCompanyTable
              companies={user?.companies}
              handleInputChange={handleInputChange}
              setIsOpenRemoveUser={setIsOpenRemoveUser}
              setCompanyXUserId={setCompanyXUserId}
            />
          </Wrapper>
        </Container>
      </MainLayout>
      {isOpenRemoveUser && (
        <ModalDeleteCompany
          isOpenModalDelete={isOpenRemoveUser}
          setIsOpenModalDelete={setIsOpenRemoveUser}
          text={`Are you sure you want to remove ${user?.name} from this account?`}
          confirmLabel="Remove User"
          handleDelete={() => handleDeleteUserCompany(companyXUserId)}
          title="Remove User"
        />
      )}
      {isOpenRemoveUserAllAccounts && (
        <ModalDeleteCompany
          isOpenModalDelete={isOpenRemoveUserAllAccounts}
          setIsOpenModalDelete={setIsOpenRemoveUserAllAcounts}
          text={`Would you like to delete ${user?.name} from all accounts?`}
          confirmLabel="Delete User"
          handleDelete={handleDeleteUser}
          title="Delete User"
        />
      )}
    </>
  );
};
