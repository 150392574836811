import React from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const DXInputField = (props: TextFieldProps) => {
  return (
    <FormControl
      variant='standard'
      sx={{ width: props.fullWidth ? "100%" : "inherit" }}
    >
      {props.label && <InputLabel shrink>{props.label}</InputLabel>}
      <TextField {...props} label='' />
    </FormControl>
  );
};

export default DXInputField;
