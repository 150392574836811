import Chip, { ChipProps, chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const DXChip = styled(({ ...props }: ChipProps) => (
  <Chip {...props} variant='outlined' />
))(({ theme }) => ({
  color: theme.palette.common.black,
  [`&.${chipClasses.outlined}Success`]: {
    backgroundColor: theme.palette.success.light,
    fontSize: ".9rem",
    borderColor: theme.palette.success[100],
  },
}));

export default DXChip;
