import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  ${({ theme }) => theme.colors.gray700};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const QBStep = styled.div`
  color: #6938EF;
  font-family: Inter;
  background-color: rgb(240, 235, 253) !important;
  border: 1px solid rgba(200, 200, 200, 0.20);
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 4px 8px;
`;

export const Input = styled.div`
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.spacing {
    margin-right: 3px;
  }

  &.bold {
    font-weight: 600;
  }

  &.license {
    color: var(--gray-700, #344054);
    font-family: Inter;
    font-weight: 600;
  }

  &.email {
    @media screen and (max-width: 730px) {
      width: 109px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;


export const ButtonQuickbooks = styled.button`
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-family: Avenir Next;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const Title = styled.div`
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TextContainer = styled.div``;

export const TextModal = styled.div`
  color: #1d2939;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;

  &.clientAccount {
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  }
`;
