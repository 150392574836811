import { Dispatch, SetStateAction, useState } from "react";
import { Box } from "@mui/material";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";

import DXButton from "features/common/components/DXButton";
import DXInputField from "features/common/components/DXInputField";
import DXModal from "features/common/components/DXModal/DXModal";
import DXAlert from "features/common/components/DXAlert/DXAlert";

import { WrapperAlert } from "./SignInForm.styles";

export interface PasswordModalProps {
  isPasswordOpen: boolean;
  setIsPassword: Dispatch<SetStateAction<boolean>>;
  userName: string;
}
export const ForgotPassword = ({
  isPasswordOpen,
  setIsPassword,
  userName,
}: PasswordModalProps) => {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isError, setError] = useState("");

  const isButtonDisabled = () => {
    if (newPassword !== confirmNewPassword || newPassword === "") return true;
    return false;
  };

  async function forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string
  ) {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      console.log(data);
      toast.success("Password Updated");
      setIsPassword(false);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  }

  return (
    <DXModal
      title={"Change Password"}
      open={isPasswordOpen}
      onClose={() => setIsPassword(false)}
      children={
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <DXInputField
              placeholder="Code"
              label="Code (check your email)"
              onChange={(e) => setCode(e.target.value)}
            />
            <DXInputField
              placeholder="New Password"
              label="New Password"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <DXInputField
              placeholder="Confirm New Password"
              label=" Confirm New Password"
              type="password"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              paddingTop: 4,
            }}
          >
            {isError && (
              <WrapperAlert className="password">
                <DXAlert
                  children={isError}
                  color="error"
                  sx={{ width: "100%" }}
                  onClose={() => {
                    setError("");
                  }}
                />
              </WrapperAlert>
            )}
            <DXButton
              variant="contained"
              disabled={isButtonDisabled()}
              onClick={() => forgotPasswordSubmit(userName, code, newPassword)}
              sx={{ minWidth: "140px" }}
            >
              Save Changes
            </DXButton>
          </Box>
        </>
      }
    />
  );
};
