import { createSlice } from "@reduxjs/toolkit";

interface ISignupFlow {
  licenses: number;
  accountType: string;
  accountId: string;
}

const initialState: ISignupFlow = {
  licenses: 0,
  accountType: "",
  accountId: "",
};

const signupFlow = createSlice({
  name: "signupFlow",
  initialState,
  reducers: {
    increaseLicenses: (state) => {
      state.licenses++;
    },
    decreaseLicenses: (state) => {
      state.licenses--;
    },
    setLicenses: (state, action) => {
      state.licenses = action.payload.licenses;
      return state;
    },
    setAccountType: (state, action) => {
      state.accountType = action.payload.accountType;
      return state;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload.accountId;
      return state;
    },
    resetSignupFlow: (state) => {
      return initialState;
    },
  },
});

export const {
  increaseLicenses,
  decreaseLicenses,
  setAccountType,
  setLicenses,
  setAccountId,
  resetSignupFlow,
} = signupFlow.actions;

export default signupFlow.reducer;
