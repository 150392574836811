import DXButton from "features/common/components/DXButton/DXButton";
import DXIcon from "features/common/components/DXIcon";
import DXModal from "features/common/components/DXModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { quantityLicensesCompanies, quantityMaxUsers } from "utils/quantityLicensesAndUsers";
import { ModalPlanSettings } from "../ModalPlanSettings";
import { SettingsProps } from "../utils";
import { ManageClientAccount } from "./ManageClientCompany/ManageClientCompany";
import {
  AccountContainer,
  CompanyContainer,
  Container,
  FilterContainer,
  Text,
  TextUpgrade,
  UpgradeButton,
  UpgradeContainer,
  Wrapper
} from "./ManageCompanies.styles";
import { TableManageAccount } from "./TableManageCompany";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { QBLogoutModal } from "features/common/components/QBLogoutModal/QBLogoutModal";
import { QBRegisteredAccount } from "features/common/components/QBRegisteredAccount/QBRegisteredAccount";
import ModalAddQBAccount from "features/common/components/AddQBAccount/ModalAddQBAccount";
import useCurrentUser from "hooks/useCurrentUser";
import { SettingsSystemDaydream } from "@mui/icons-material";

export const ManageCompanies = ({ dxUserData }: SettingsProps) => {
  const { t } = useTranslation();
  const [isClientAccount, setClientAccount] = useState(false);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const { currentCompany } = useSelector((state: RootState) => state);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [addQBAccount, setAddQBAccount] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [congratulations, setCongratulations] = useState(false);


  const queryClient = useQueryClient();

  const params = new URLSearchParams(window.location.search);
  const paramTab = params.get("openModal") === "true";
  const { userData } = useCurrentUser();

  const licensesLeft = quantityLicensesCompanies(dxUserData, currentCompany?.account);
  const [orgCompanies, setOrgCompanies] = useState(dxUserData.accounts?.[0].companies);

  const maxUsers = quantityMaxUsers(dxUserData, currentCompany?.account);
  const companyLicensesLeft = currentCompany?.account?.license?.max_companies - currentCompany?.account?.companies?.length;
  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  const handleCompanyIdChange = (newCompanyId) => {
    setCompanyId(newCompanyId);
  };

  useEffect(() => {
    window.location.href.includes("connected") &&
      setCongratulations(true)
    setAddQBAccount(false)
    setConfirmLogout(false)
  }, [])


  useEffect(() => {
    setOrgCompanies(currentCompany?.account?.companies);
  }, [currentCompany]);

  const isDisabled = () => {
    return permissions?.manage_companies?.features?.client_account_button === 'disable' || licensesLeft <= 0;
  }

  const isUpgradePlanDisabled = () => {
    return permissions?.manage_companies?.features?.upgrade_plan_button === 'disable';
  }

  const logoutConfirmed = () => {
    setConfirmLogout(false);
    setAddQBAccount(true);
  }

  return dxUserData && (
    <>
      <Container>
        <Text>{t("manage-accounts")}</Text>
        {permissions?.manage_companies?.features?.client_account_button === 'enable' &&
          <DXButton
            children={"New Client Company"}
            size={"large"}
            variant={"contained"}
            disabled={isDisabled()}
            onClick={() => setConfirmLogout(true)}
            startIcon={<DXIcon name="Plus" cssClass="dark-icon" />}
          />
        }
      </Container>

      <Wrapper>
        <FilterContainer>
          <AccountContainer></AccountContainer>
        </FilterContainer>
        {permissions?.manage_companies?.features?.upgrade_plan_button === 'enable' && (
          <UpgradeContainer>
            <TextUpgrade>You have</TextUpgrade>
            <TextUpgrade className="number">{licensesLeft}</TextUpgrade>
            <TextUpgrade>license(s) available. Need more?</TextUpgrade>
            <UpgradeButton
              onClick={() => {
                if (permissions?.manage_companies?.features?.upgrade_plan_button === 'enable') setOpenModalPlan(true);
              }}
              style={{
                cursor: isUpgradePlanDisabled() ? 'not-allowed' : 'pointer',
                opacity: isUpgradePlanDisabled() ? 0.5 : 1,
              }}>
              Upgrade plan
            </UpgradeButton>
          </UpgradeContainer>
        )}
      </Wrapper>

      {(orgCompanies?.map((company) =>
        <CompanyContainer key={company?.id}>
          <TableManageAccount
            company={company}
            role={currentCompany?.account?.role}
            maxUsers={maxUsers}
            isFromManageCompanies
            accountId={currentCompany?.account?.id}
            companyRole={company.role}
          />
        </CompanyContainer>)
      )}

      {(isClientAccount || paramTab) && (
        <DXModal
          title={""}
          customWidth={1000}
          open={isClientAccount || paramTab}
          onClose={() => setClientAccount(false)}
          children={
            <ManageClientAccount
              onClose={() => {
                setClientAccount(false);
                queryClient.refetchQueries("getCompanies");
              }}
            />
          }
        />
      )}

      {permissions?.manage_companies?.features?.client_account_button === 'enable' && (
        <QBLogoutModal
          openModal={confirmLogout}
          onClose={() => setConfirmLogout(false)}
          logoutConfirmed={logoutConfirmed}
          className='clientAccount'
        ></QBLogoutModal>
      )}
      {addQBAccount && (
        <ModalAddQBAccount
          onClose={() => setAddQBAccount(false)}
          openModal={() => addQBAccount}
          accountId={dxUserData.accounts[0].id}
          cognitoId={userData.sub}
          onCompanyIdChange={handleCompanyIdChange}
          companyLicensesLeft={companyLicensesLeft}
        />
      )}
      {congratulations && (
        <QBRegisteredAccount
          openModal={congratulations}
          onClose={() => setCongratulations(false)}
          userLicensesLeft={licensesLeft}
          accountId={currentCompany?.account?.id}
          companyId={currentCompany?.company?.id}
          isFromSignUp={false}
        />
      )}
    </>
  );
};
