import { TemplateLibrary } from "features/TemplateLibrary/TemplateLibrary";
import { Wrapper } from "features/common/components/Wrapper";
import GlobalStyle from "styles/globalStyles";

const TemplateLibraryPage: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <TemplateLibrary />
    </Wrapper>
  );
};
export default TemplateLibraryPage;
