import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { decreaseLicenses } from "redux/actions";
import { RootState } from "redux/reducers";
import useCurrentUser from "hooks/useCurrentUser";
import { useLocation } from "react-router-dom";
import { getData, post } from "services/api";
import { quantityLicensesUsers } from "utils/quantityLicensesAndUsers";
import { StepProps } from "../../types";
import { createCompany, createUser } from "../services";
import * as Styled from "./Form.styles";
import { QBRegisteredAccount } from "features/common/components/QBRegisteredAccount/QBRegisteredAccount";
import ModalAddQBAccount from "features/common/components/AddQBAccount/ModalAddQBAccount";
import { QBLogoutModal } from "features/common/components/QBLogoutModal/QBLogoutModal";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const Form = ({ setCurrentStep, setLicenses, userLicensesLeft, setUserLicensesLeft, companiesLicenses, setAllUsers, allUsers }: StepProps) => {
  const query = useQuery();
  const { dxUserData, userData } = useCurrentUser();
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState<ICreateUser[]>([]);
  const [companyId, setCompanyId] = useState("")
  const [isOpen, setIsOpen] = useState(false);
  const [qbIsOpen, setQbIsOpen] = useState(false);
  const [isQBconnect, setIsQBconnect] = useState<boolean>()
  const [confirmLogout, setConfirmLogout] = useState(true);
  const [confirmCongratulations, setConfirmContratulations] = useState(false);
  const [addQBAccount, setAddQBAccount] = useState(false);
  const [congratulations, setCongratulations] = useState(false)
  const companyLicensesLeft = dxUserData.accounts?.[0].license.max_companies - dxUserData.accounts?.[0].companies.length
  const usersLicensesLeft = dxUserData.accounts?.[0].license.max_users - dxUserData.accounts?.[0].license.used_licenses
  const { currentCompany } = useSelector((state: RootState) => state);

  useEffect(() => {
    window.location.href.includes("connected") &&
      setOpenModal(true)
    setCongratulations(true)
    setConfirmLogout(false)
  }, [])

  useEffect(() => {
    window.location.href.includes("cognito_id") &&
      setConfirmLogout(true)
  }, [])

  const account = dxUserData?.accounts?.[0]?.id;

  const { signupFlow: { accountId, accountType } } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const [payload, setPayload] = useState({
    name: "",
    lastName: "",
    account_id: account || "",
    logo: "",
    color: ""
  });

  const isCompany = dxUserData.accounts?.[0].license.max_companies === companiesLicenses

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      account_id: dxUserData?.accounts?.[0]?.id,
    }));

    setUserLicensesLeft(quantityLicensesUsers(dxUserData))

    setIsQBconnect(dxUserData.accounts?.[0].companies.some(el => el.credentials_id))
  }, [dxUserData]);

  const handleChange = (name: string, value: string) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCompanyIdChange = (newCompanyId) => {
    setCompanyId(newCompanyId);
  };


  // const handleFile = async (file: File) => {
  //   try {
  //     const response = await post("/files/company/upload-logo", {
  //       original_filename: file.name,
  //       metadata: {},
  //       origin: "web",
  //       content_type: file.type,
  //       membership_account_id: accountId,
  //     });

  //     if (response.uploadUrl) {
  //       await axios.put(response.uploadUrl, file, {
  //         headers: {
  //           "Content-Type": file.type,
  //         },
  //       });

  //       setPayload((prev) => ({
  //         ...prev,
  //         logo: process.env.REACT_APP_S3_AMAZONAWS + response.location,
  //       }));
  //     } else {
  //       console.error("Failed to upload file");
  //     }
  //   } catch (error) {
  //     console.error("Failed to upload file", error);
  //   }
  // };
  const handleSubmit = async () => {

    try {
      mutateCreateCompany({
        ...payload,
      });

      setLicenses((prev: number) => prev - 1)

      setPayload(() => ({
        name: "",
        lastName: "",
        account_id: account || "",
        logo: "",
        color: "",
      }))

      if (userLicensesLeft) {
        setIsOpen(true)
      } else {
        isQBconnect ? setOpenModal(true) : setQbIsOpen(true)
      }

    } catch (error) {
      console.error("Failed to create client account");
    }

    console.log(isCompany)
  };

  const { mutate: mutateCreateUser } = useMutation(createUser);

  const checkUserExists = async ({ email, users }: { email: string; users: any; }) => {
    const user = users.find((u) => u.email === email);
    return user ? user.id : null;
  };

  const { mutate: mutateCreateCompany } = useMutation(createCompany, {
    onSuccess: async (response) => {
      dispatch(decreaseLicenses());
      setCompanyId(response.data.id)
    }
  });

  // const { mutate: mutateCreateFirm } = useMutation(createFirm, {
  //   onSuccess: async (response) => {

  //     dispatch(decreaseLicenses());
  //     const userData = await getData(`memberships/account/${accountId}/user`);
  //     for (const user of users) {
  //       const { firstName, lastName, email, role } = user;
  //       const userId = await checkUserExists({
  //         email,
  //         users: userData.response,
  //       });

  //       if (!userId) {
  //         mutateCreateUser({
  //           name: `${firstName} ${lastName}`,
  //           email,
  //           account_role: "user",
  //           account_id: accountId,
  //           companies: [
  //             {
  //               company_id: response.data.id,
  //               company_role: role,
  //             },
  //           ],
  //         });
  //       } else {
  //         await post("/memberships/userXcompany", {
  //           user_id: userId,
  //           company_id: response.data.id,
  //           role,
  //         });
  //       }
  //     }
  //   },
  // });

  // const handleUpload = (dataTransfer) => {
  //   const files = dataTransfer.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     handleFile(file);
  //   }
  // };

  useEffect(() => {
    if (query.get("on_fail_redirect")) {
      setTimeout(() => {
        toast.error("Failed to connect to Quickbooks");
      }, 1000);
      console.log("on_fail_redirect");
    }

    if (query.get("on_success_redirect")) {
      setOpenModal(true);
      dispatch(decreaseLicenses());
    }
  }, []);

  const handleSubmitUsers = async () => {

    const userData = await getData(`memberships/account/${accountId}/user`);

    console.log(users)

    for (const user of users) {

      const { firstName, lastName, email, role } = user;

      const userId = await checkUserExists({ email, users: userData.response });

      setAllUsers(prev => [...prev, email])

      if (!userId) {

        mutateCreateUser({
          name: `${firstName} ${lastName}`,
          email,
          account_role: "user",
          account_id: accountId,
          companies: [
            {
              company_id: companyId,
              company_role: role,
            },
          ],
        });
      } else {
        await post("/memberships/userXcompany", {
          user_id: userId,
          company_id: companyId,
          role,
        });
      }

      setUserLicensesLeft(prev => {
        if (allUsers.some(el => el === email)) {
          return prev
        } else {
          return prev - 1
        }
      })
    }
    setIsOpen(false)

    setUsers([{ firstName: "", lastName: "", email: "", role: "" }])

    setPayload(() => ({
      name: "",
      lastName: "",
      account_id: account || "",
      logo: "",
      color: "",
    }))
  };

  const handleClose = () => {
    setConfirmLogout(false);
    setConfirmContratulations(false);
  }

  const logoutConfirmed = () => {
    setConfirmLogout(false);
    setAddQBAccount(true);
  }

  const checkUserLicenses = () => {
    if (currentCompany?.account?.license.max_users) {
      return currentCompany?.account?.license.max_users - currentCompany?.account?.license.used_licenses
    }
    return dxUserData.accounts?.[0].license.max_users - dxUserData.accounts?.[0].license.used_licenses
  }

  return Boolean(dxUserData.created_at) && (
    <Styled.FormContainer>
      <QBLogoutModal
        openModal={confirmLogout}
        onClose={() => null}
        logoutConfirmed={logoutConfirmed}
        className={"clientAccount"}
      ></QBLogoutModal>
      {openModal && (
        <QBRegisteredAccount
          openModal={openModal}
          onClose={handleClose}
          userLicensesLeft={checkUserLicenses()}
          accountId={dxUserData.accounts[0].id}
          companyId={companyId ? companyId : dxUserData.accounts[0].companies[0].id}
          isFromSignUp={true}
        />
      )}
      {addQBAccount && (
        <ModalAddQBAccount
          onClose={() => setAddQBAccount(false)}
          openModal={() => addQBAccount}
          accountId={dxUserData.accounts[0].id}
          cognitoId={userData.sub}
          onCompanyIdChange={handleCompanyIdChange}
          companyLicensesLeft={companyLicensesLeft}
        />
      )}
    </Styled.FormContainer>
  )
}
