import { useTranslation } from "react-i18next";

import { MainLayout } from "features/common/components/MainLayout/MainLayout";
import { Step1 } from "./Steps/Step1/Step1";
import { Step2 } from "./Steps/Step2/Step2";
import { Step3 } from "./Steps/Step3/Step3";

import { Container, Text } from "./TemplateLibrary.styles";

import { useFetchCurrentCompany } from "utils/fetchCurrentCompany";

export const TemplateLibrary = () => {
  const { t } = useTranslation();
  useFetchCurrentCompany("Template Library");

  return (
    <MainLayout>
      <Container>
        <Text>{t("template-library")}</Text>
        <Step1 />
        <Step2 />
        <Step3 />
      </Container>
    </MainLayout>
  );
};
