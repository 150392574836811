import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import api, { getData } from "services/api";

import DXTabPanel from "features/common/components/DXTabPanel";
import DXTabs from "features/common/components/DXTabs";
import { ITab } from "features/common/components/DXTabs/DXTabs";
import { MainLayout } from "features/common/components/MainLayout/MainLayout";
import useCurrentUser from "hooks/useCurrentUser";
import BillingInvoicing from "./BillingInvoicing";
import { ManageCompanies } from "./ManageCompanies/ManageCompanies";
import { ManagePlan } from "./ManagePlan/ManagePlan";
import { ManageUsers } from "./ManageUsers";
import { Profile } from "./Profile/Profile";

import { Container, ContainerTabs, Title, Wrapper } from "./Settings.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useDispatch } from "react-redux";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";
import { QBRegisteredAccount } from "features/common/components/QBRegisteredAccount/QBRegisteredAccount";

interface LocationState {
  tabValue?: number;
}

export const Settings = () => {
  const { state }: { state: LocationState } = useLocation();
  const { dxUserData } = useCurrentUser();
  const { currentCompany } = useSelector((state: RootState) => state);
  const role = currentCompany?.account?.role;
  const history = useHistory()
  const dispatch = useDispatch();


  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);
  const [congratulations, setCongratulations] = useState(false)

  useEffect(() => {
    window.location.href.includes("connected") &&
      setCongratulations(true)
  }, [])

  useQuery("getProfileInformation", async () => {
    try {
      console.log("Getting profile information")
      const data = await getData("/memberships/user/profile");
      let account;
      let company;
      if (data) {
        console.log("Setting current company")
        dispatch(setDXUserData(data));
        if (currentCompany?.account) {
          account = data.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
        } else {
          account = data.accounts[0];
        }

        if (currentCompany?.company) {
          company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
        } else {
          company = data.accounts[0].companies[0]
        }
        const rbac = data.rbac;
        dispatch(setCurrentCompany({ account, company, rbac }))
        return data.response;
      }
    } catch (err) {
      throw err;
    }
  });

  useEffect(() => {

    api.get("https://l6nnsn1qhe.execute-api.us-east-1.amazonaws.com/DEV/memberships/validate-invite")
      .then(res => {
        if (!res.data.hasPaid) {
          history.push("/sign-up/#3")
          return
        }
      })
  }, [])

  const params = new URLSearchParams(window.location.search);
  const paramTab = parseInt(params.get("tabValue"));

  const [tabValue, setTabValue] = useState(state?.tabValue || paramTab || 0);

  useEffect(() => {
    setTabValue(paramTab || 0);
  }, [paramTab]);

  useEffect(() => {
    if (state) {
      setTabValue(state?.tabValue || 0);
    }
  }, [state]);

  const [tabs, setTabs] = useState<ITab[]>([
    { label: "My Profile", index: 0, disabled: false },
    { label: "Manage Companies", index: 1, disabled: false },
    { label: "Manage Users", index: 2, disabled: false },
    { label: "Manage Plans", index: 3, disabled: false },
    { label: "Billing & Invoicing", index: 4, disabled: false },
  ]);

  useEffect(() => {
    setTabValue(state?.tabValue || paramTab || 0);
    if (orgRole === "ORG_FINANCIAL") {
      setTabs((prevTabs) =>
        prevTabs.map((tab) =>
          tab.index === 1 || tab.index === 2 ? { ...tab, disabled: true } : tab
        )
      );
    }
    if (orgRole === "ORG_ADMIN") {
      setTabs((prevTabs) =>
        prevTabs.map((tab) =>
          tab.index === 3 || tab.index === 4 ? { ...tab, disabled: true } : tab
        )
      );
    }
  }, [state, role]);

  const handleChangeTab = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTabIndex = e.target.tabIndex;
    if (tabs[newTabIndex].disabled) {
      return
    }
    setTabValue(newTabIndex);
  };

  return (
    <MainLayout>
      <Container>
        <ContainerTabs>
          <Title> Settings</Title>
          <DXTabs
            tabs={tabs}
            value={tabValue}
            onChange={handleChangeTab}
          />
        </ContainerTabs>
        <Wrapper>
          <DXTabPanel value={tabValue} index={0}>
            <Profile dxUserData={dxUserData} />
          </DXTabPanel>
          <DXTabPanel value={tabValue} index={1}>
            <ManageCompanies dxUserData={dxUserData} />
          </DXTabPanel>
          <DXTabPanel value={tabValue} index={2}>
            <ManageUsers dxUserData={dxUserData} setTabValue={setTabValue} />
          </DXTabPanel>
          <DXTabPanel value={tabValue} index={3}>
            <ManagePlan setTabValue={setTabValue} dxUserData={dxUserData} />
          </DXTabPanel>
          <DXTabPanel value={tabValue} index={4}>
            <BillingInvoicing />
          </DXTabPanel>
        </Wrapper>
      </Container>
      {congratulations && (
        <QBRegisteredAccount
          openModal={{ congratulations }}
          onClose={() => {setCongratulations(false); history.push("/settings")}}
          userLicensesLeft={0}
          accountId={currentCompany?.account?.id}
          companyId={currentCompany?.company?.id}
          isFromSignUp={false}
        />
      )}
    </MainLayout>
  );
};
