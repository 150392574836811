import { useTranslation } from "react-i18next";

import { Dropdown } from "../Dropdown/Dropdown";

import {
  Container,
  ContainerFooter,
  ContainerLeft,
  ContainerRight,
  Divider,
  Text,
  TextFooter,
} from "./Footer.styles";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <ContainerLeft>
          <Text>{t("footer-alchemist")}</Text>
          <Divider />
          <Text
            className="pointer"
            onClick={() =>
              window.open("https://www.datumxpress.com/privacy-policy")
            }
          >
            {t("footer-alchemist2")}
          </Text>
          <Divider />
          <Text
            className="pointer"
            onClick={() => window.open("https://www.datumxpress.com/terms")}
          >
            {t("footer-alchemist3")}
          </Text>
        </ContainerLeft>
        {/* <ContainerRight>
          <Text className="language">{t("footer-select-language")}</Text>
          <Dropdown />
        </ContainerRight> */}
      </Container>
      <ContainerFooter>
        <TextFooter>{t("footer-alchemist4")}</TextFooter>
        <TextFooter>{t("footer-alchemist5")}</TextFooter>
      </ContainerFooter>
    </>
  );
};
