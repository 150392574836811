import DXCard from "features/common/components/DXCard/Card";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  ContainerModal,
  Information,
  Input,
  Text,
  TextFieldNumber,
  TextNumber,
} from "./Plan.styles";

interface PlanProps {
  selectedCardAccount: string;
  setNumUsers: Dispatch<SetStateAction<number>>;
  numUsers: number;
  setNumQuickBooks: Dispatch<SetStateAction<number>>;
  numQuickBooks: number;
  selectedCardOption: string;
  setSelectedCardOption: Dispatch<SetStateAction<string>>;
  products: any[];
}
export const ModalPlan = ({
  selectedCardAccount,
  setNumUsers,
  setNumQuickBooks,
  selectedCardOption,
  setSelectedCardOption,
  products,
  numUsers,
  numQuickBooks
}: PlanProps) => {
  const { t } = useTranslation();

  const handleCardClick = (option) => {
    setSelectedCardOption(selectedCardOption === option ? null : option);
  };
  useEffect(() => {
    if (selectedCardAccount === "Company" || selectedCardAccount === "Startup") {
      setNumQuickBooks(1);
    }
    if (selectedCardAccount === "Startup" || selectedCardAccount === "Accountant") {
      setNumUsers(1);
    }
  }, [selectedCardAccount]);

  const handleChangeCompany = (value) => {
    if (value > 0) {
      setNumQuickBooks(value);
    } else {
      setNumQuickBooks(1);
    }
  };


  return (
    <Container>
      <Information>
        <Input>
          <Text>{t("step-Plan-Companies")}</Text>
          {selectedCardAccount === "Startup" ?
            <TextNumber>1</TextNumber>
            :
            <TextFieldNumber
              type="number"
              label=""
              min={1}
              defaultValue={numQuickBooks}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                if (value > 0) {
                  handleChangeCompany(value);
                } else {
                  event.target.value = 1;
                }
              }}
            />
          }
        </Input>
        <Input>
          <Text>{t("step-Plan-Users")}</Text>
          {selectedCardAccount === "startup" || selectedCardAccount === "accountant" ?
            <TextNumber>1</TextNumber>
            :
            <TextFieldNumber
              type="number"
              label=""
              min={"1"}
              defaultValue={numUsers}
              onChange={(event) => {
                if (event.target.value > 0) {
                  setNumUsers(event.target.value);
                } else {
                  event.target.value = 1;
                }
              }}
            />
          }
        </Input>
      </Information>
      <ContainerModal>
        {products.map(({ title, price, description, option }) => (
          <DXCard
            title={title}
            price={price === 0 ? "$" : `$${price}`}
            description={description}
            isSelected={selectedCardOption === option}
            onClick={() => handleCardClick(option)}
            isPlan
            className="plan"
          />
        ))}
      </ContainerModal>
    </Container>
  );
};
