import styled from "styled-components";
import { CheckboxButton } from "features/common/components/CheckboxButton/CheckBoxButton.styles";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const CardContainer = styled.div`
  display: flex;
  padding: 16px 16px 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #ffff;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  width:260px;
  max-width:260px;
  //TODO: fix when dimensions are smaller
  cursor: pointer;

  @media screen and (max-width: 1500px) {
    position: inherit;
  }

  &.account {
    position: absolute;
    @media screen and (max-width: 1500px) {
      position: inherit;
    }
  }

  @media screen and (max-width: 1280px) {
    position: inherit;
  }

  &.select {
    outline: #6938ef solid 3px;
  }

  &.select:hover {
    outline: 3px solid #6938ef;
  }

  :hover {
    outline: 3px solid #d9d6fe;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 0px 24px -4px rgba(16, 24, 40, 0.15);
  }

  &.startup {
    top: 0;
    left: 0;
    height: 110px;
  }

  &.company {
    top: 0;
    right: 0;
    height: 110px;
  }

  &.accountant {
    top: 0;
    left: 0;
    height: 110px;
  }
  &.firm {
    top: 0;
    right: 0;
    height: 110px;
  }

 
`;

export const Checkbox = muiStyled(CheckboxButton)`
&.input {
    border-radius: 25px;
  }
  &.hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const AccountName = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const TextType = muiStyled(Box)`
  color: #6938EF;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &.firm {
    color: rgb(0, 63, 192);
  }
  &.accountant {
    color: rgb(0, 63, 192);
  }
  &.startup {
    color: rgb(101, 33, 189);
  }
  &.company {
    color: rgb(101, 33, 189);
  }
  
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TextInfo = muiStyled(Box)`
  color: var(--gray-700, #344054);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;


`;

export const Tag = styled.div`
  display: flex;
  height: 24px;
  padding: 2px 8px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: #6af9ca;
`;

export const TextTag = styled.div`
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
