import { Select } from "@mui/material";
import styled from "styled-components";

import { Icon } from "features/common/components/Icon";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Title = styled.div`
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;


export const ContainerButton = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding-bottom: 0;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const TextForm = styled.div`
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const Input = styled.input`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray700};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.purple300};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.lavender},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const InputContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;

  &.column {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
`;

export const Dropdown = styled(Select)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  min-height: unset;
  height: 38px;

  &.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-width: 0px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: transparent;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.purple300};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.lavender},
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .MuiSelect-placeholder {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .MuiSelect-select {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray700};
  }
`;

export const FileContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.purple300};
`;

export const IconContainer = styled.div`
  border-radius: 28px;
  border: 6px solid ${({ theme }) => theme.colors.purple100};
  background-color: ${({ theme }) => theme.colors.purple100};
`;

export const IconUpload = styled(Icon)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray600};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.upload {
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.purple};
  }
`;

export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const RowText = styled.div`
  display: flex;
  gap: 4px;

  &.scheme {
    gap: 16px;
    align-items: flex-end;
  }
`;
