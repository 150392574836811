import { TextField } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  flex-direction: column;
`;

export const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.gray50};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const TextNumber = styled.div`
  margin-right: 15px;
`;

export const Input = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 130px;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
  }
`;

export const TextFieldNumber = styled(TextField)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  max-width: 90px;
  height: 40px;
  padding: 8px 12px;

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.purple600};
  }

  .MuiInputBase-root {
    width: 100%;
    max-width: 90px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    text-align: center;
  }
`;
