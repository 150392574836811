import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DXButton from "features/common/components/DXButton";
import { Modal } from "features/common/components/Modal/Modal";
import { Icon } from "../../../Icon";
import { InputField } from "../../../InputField/InputField";
import { Information, Row, Text } from "../../AddUsers.styles";
import { ModalProps } from "../types";
import { DropdownUser } from "./DropdownUser";
import {
  Body,
  Container,
  ContainerFields,
  Wrapper,
} from "./ModalAddUser.styles";
import { inputFields } from "./utils";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export default function ModalAddUserForm({
  isOpen,
  closeModal,
  userLeft,
  className,
  users,
  setUsers,
  handleSubmitUsers,
}: ModalProps) {
  const [isUserListValid, setIsUserListValid] = useState(false);
  const { t } = useTranslation();

  const addUser = () => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  };

  const validateList = (list: ICreateUser[]): boolean => {
    return list.every((user) => {
      return Object.values(user).every((value) => value.trim() !== "");
    });
  };

  const handleInputChange = (index, fieldId, value) => {
    const newUsers = [...users];
    newUsers[index][fieldId] = value;
    setUsers(newUsers);
  };

  useEffect(() => {
    validateList(users) ? setIsUserListValid(true) : setIsUserListValid(false);
  }, [users]);

  useEffect(() => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  }, []);

  const handleCloseModal = () => {
    users.pop();
    closeModal();
  };

  const handleCloseModalSuccess = () => {
    closeModal();
    handleSubmitUsers && handleSubmitUsers()
  };

  return (
    <>
      {isOpen && (
        <Modal className={className} onClose={handleCloseModal}>
          <>
            <Modal.Header onClose={handleCloseModal}>
              {t("add-user")}
            </Modal.Header>
            <Information className="user">
              <Row>
                <Icon iconName="user-02" size="M" />
                <Text className="information">
                  You have {userLeft} user license(s) left.
                </Text>
              </Row>
            </Information>
            <Modal.Body>
              <Wrapper>
                {users.map((user, index) => (
                  <Body key={index}>
                    {inputFields.map((field) => (
                      <ContainerFields key={field.id}>
                        <InputField
                          label={field.label}
                          id={`${field.id}-${index}`}
                          type={field.type}
                          required={field.required}
                          placeholder={field.placeholder}
                          value={user[field.id]}
                          onChange={(event) => handleInputChange(index, field.id, event.target.value)}
                        />
                      </ContainerFields>
                    ))}
                    <ContainerFields>
                      <DropdownUser
                        title={t("user-type")}
                        value={user.role}
                        className="small"
                        handleChange={(event) => handleInputChange(index, "role", event.target.value)}
                      />
                    </ContainerFields>
                  </Body>
                ))}
                <DXButton
                  type="button"
                  variant="text"
                  children="Add Another User(s)"
                  onClick={addUser}
                  disabled={users.length >= userLeft}
                />
              </Wrapper>
            </Modal.Body>
            <Modal.ActionButtons>
              <Container>
                <DXButton
                  type="button"
                  variant="contained"
                  children="Add User(s)"
                  disabled={userLeft <= 0 || !isUserListValid}
                  onClick={handleCloseModalSuccess}
                />
              </Container>
            </Modal.ActionButtons>
          </>
        </Modal>
      )}
    </>
  );
}
