import { Dispatch, SetStateAction, useState } from "react";

import DXButton from "features/common/components/DXButton/DXButton";
// import { ChangeEmailModal } from "./ChangeEmailModal";
import { ChangePasswordModal } from "./ChangePasswordModal";

import {
  Container,
  Title,
  Text,
  Input,
  Wrapper,
  Information,
  Row,
  ContainerButton,
  Column,
  Line,
} from "./Profile.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

interface PersonalSettingsProps {
  setAlertMessage: Dispatch<SetStateAction<string>>;
  name: string;
  email: string;
}

export const PersonalSettings = ({
  setAlertMessage,
  name,
  email,
}: PersonalSettingsProps) => {
  // const [isEmailOpen, setIsEmail] = useState(false);
  const [isPasswordOpen, setIsPassword] = useState(false);
  const { currentCompany } = useSelector((state: RootState) => state);
  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  return (
    <Container>
      <Title>Personal Settings</Title>
      <Information>
        <Row>
          <Text>Name:</Text>
          <Input>{name}</Input>
        </Row>
        <Wrapper>
          <ContainerButton>
            <Column className="email">
              <Text>Email Address:</Text>
              <Input className="email">{email}</Input>
            </Column>
            <Column>
              <Text>Password:</Text>
              <Input>*********</Input>
            </Column>
            {/* <DXButton
              size="medium"
              color="info"
              variant="text"
              children="Change Email"
              onClick={() => setIsEmail(true)}
            /> */}
          </ContainerButton>
          <Line />
          <ContainerButton className="password">
            <DXButton
              size="medium"
              color="info"
              variant="text"
              children="Change Password"
              onClick={() => setIsPassword(true)}
              disabled={permissions?.my_profile?.personal_settings?.change_password === "disable"}
            />
          </ContainerButton>
        </Wrapper>
      </Information>
      {/* {isEmailOpen && (
        <ChangeEmailModal
          isOpenEmail={isEmailOpen}
          setIsEmail={setIsEmail}
          setAlertMessage={setAlertMessage}
        />
      )} */}
      {isPasswordOpen && (
        <ChangePasswordModal
          isPasswordOpen={isPasswordOpen}
          setIsPassword={setIsPassword}
          setAlertMessage={setAlertMessage}
        />
      )}
    </Container>
  );
};
