import Paper, { PaperProps } from "@mui/material/Paper";
import { styled as muiStyled } from "@mui/material/styles";
import { Icon } from "features/common/components/Icon/Icon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: var(--purple-50, #f4f3ff);
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 500px;
  height: 100%;
  border-radius: 20px 0 0 20px;
  padding: 48px;

  @media screen and (max-width: 730px) {
    width: 300px;
  }
`;

export const WrapperAlert = styled.div`
  margin-top: 10px;
  width: 100%;

  &.password {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const Text = styled.div`
  color: var(--dx-gradient-2, #0742b9);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const IconDX = styled(Icon)`
  width: 190px;
  height: 52px;

  &.vector {
    width: 204.366px;
    height: 98.519px;
  }
`;

export const IconDXVector = styled(Icon)`
  position: absolute;
  left: 117px;
  bottom: 397px;
  width: 150.366px;
  height: 82.519px;
`;

export const IconDXVector2 = styled(Icon)`
  position: absolute;
  width: 204.314px;
  height: 98.508px;
  left: 0px;
  top: 49px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 250px;
  color: white;
  font-size: 32px;
  text-align: center;
  position: relative;
`;

export const PaperWrapper = muiStyled((props: PaperProps) => (
  <Paper {...props} />
))(({ theme }) => ({
  borderRadius: 20,
  width: 750,
  height: 464,
  background: "linear-gradient(180deg, #235FDB 0%, #6938EF 100%)",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 10px 15px -3px,rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
}));

export const Form = styled.div.attrs({
  component: "form",
})``;
