import { singleQBOoptions, multipleQBOoptions } from "./utils";
import DXCard from "features/common/components/DXCard";

import { Container, Title } from "./AccountType.styles";
interface AccountProps {
  selectedCard?: string;
  setSelectedCard?: React.Dispatch<React.SetStateAction<string>>;
}
export const AccountType = ({
  selectedCard,
  setSelectedCard,
}: AccountProps) => {
  const handleCardClick = (card) => {
    setSelectedCard(selectedCard === card ? null : card);
  };

  return (
    <><Container
    style={{
      backgroundColor: 'rgb(244, 243, 255)',
    }}>
       <Title>Connect to One QuickBooks Online Account</Title>
      {singleQBOoptions.map(({ name, icon, className }) => (
        <DXCard
          name={name}
          icon={icon}
          className={className}
          isSelected={selectedCard === className}
          onClick={() => handleCardClick(className)}
          isPlan={false}
          key={name} />
      ))}
    </Container>
    <Container
    style={{
      backgroundColor: 'rgb(238, 244, 255)',
    }}
    >
    <Title>Connect to Multiple QuickBooks Online Accounts</Title>
        {multipleQBOoptions.map(({ name, icon, className }) => (
          <DXCard
            name={name}
            icon={icon}
            className={className}
            isSelected={selectedCard === className}
            onClick={() => handleCardClick(className)}
            isPlan={false}
            key={name} />
        ))}
      </Container>
      </>
  );
};
