const ROUTES = {
  ROOT_PATH: "/",
  SIGN_UP_PATH: "/sign-up",
  SIGN_IN_PATH: "/sign-in",
  UPLOAD_FILES_PATH: "/uploadFiles",
  TEMPLATE_PATH: "/templateLibrary",
  ACTIVITY_PATH: "/activityHistory",
  INTEGRATIONS_PATH: "/integrations",
  SETTINGS_PATH: "/settings",
  EDIT_USER_PATH: "/settings/edit/:user_id",
};

export { ROUTES };
