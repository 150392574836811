
export const isRoleDisabled = (name: string, permissionSet) => {
    if (!permissionSet) {
        return false
    }
    const nameToPermission = {
        "Upload Your Files": "upload_files",
        "Template Library": "template_library",
        "Integrations": "integrations",
        "FAQs": "faqs",
        "Settings": "settings",
        "Log Out": "logout"
    };

    const permissionKey = nameToPermission[name];
    return permissionSet.main_dashboard?.side_bar?.[permissionKey] === 'disable';
}
