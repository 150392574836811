// #region Global Imports
import { BaseTheme } from "styled-components";
// #endregion Global Imports

const common: BaseTheme = {
  colors: {
    aliceBlue: "#f9fafb",
    aliceBlue2: "#e8ecf3",
    blackGrey: "#20232A",
    blackGray: "rgba(0, 0, 0, 0.1)",
    black: "#000",
    black2: "#0f003f",
    blueDark: "#0040C1",
    blueDark2: "#00162f",
    blueDark50: "#EFF4FF",
    blue700: "#175cd3",
    darkGrey: "#282C34",
    dxGradient:
      "linear-gradient(270deg, #235fdb 0%, #6938ef 50%, #0c3081 100%)",
    error25: "#fffbfa",
    error200: "#fecdca",
    error300: "#fda29b",
    error50: "#fef3f2",
    lavender: "#e2ccfd",
    gray50: "#f9fafb",
    gray100: "#F2F4F7",
    gray200: "#EAECF0",
    gray300: "#d0d5dd",
    gray500: "#667085",
    gray600: "#475467",
    gray700: "#344054",
    gray800: "#1d2939",
    gray900: "#101828",
    green: "#2ca01c",
    green2: "#1EAF77",
    green50: "#EDFCF2",
    purple: "#5d45cd",
    purple50: "#F4F3FF",
    purple100: "#ebe9fe",
    purple200: "#D9D6FE",
    purple300: "#bdb4fe",
    purple600: "#6938ef",
    purple700: "#5925DC",
    purple800: "#4A1FB8",
    success25: "#f6fef9",
    success50: "#ecfdf3",
    success100: "#d1fadf",
    success300: "#6ce9a6",
    success700: "#027a48",
    transparent: "transparent",
    white: "#FFFFFF",
    warning50: "#fffaeb",
    warning200: "#fedf89",
  },
  fonts: {
    primary: "Roboto",
    secondary: "Inter",
  },
  breakpoints: {
    xxs: "550px",
    xs: "650px",
    sm: "844px",
    md: "950px",
    lg: "1280px",
    xlg: "1500px",
    xl: "1920px",
    xxl: "2560px",
  },
};

export { common };
