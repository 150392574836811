import DXButton from "features/common/components/DXButton/DXButton";
import DXIcon from "features/common/components/DXIcon";
import DXModal from "features/common/components/DXModal";
import { useEffect, useMemo, useState } from "react";
import { quantityLicensesUsers, quantityMaxUsers } from "utils/quantityLicensesAndUsers";
import {
  AccountContainer,
  CompanyContainer,
  Container,
  FilterContainer,
  Text,
  TextUpgrade,
  UpgradeButton,
  UpgradeContainer,
  Wrapper,
} from "../ManageCompanies/ManageCompanies.styles";
import { ModalPlanSettings } from "../ModalPlanSettings";
import { SettingsProps } from "../utils";
import ModalAddUsers from "./ModalAddUsers/ModalAddUsers";
import { CompanyModal } from "./ModalCompany/ModalCompany";
import { TableManageUsers } from "./TableManageUsers";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";


export const ManageUsers = ({ dxUserData, setTabValue }: SettingsProps) => {
  const { currentCompany } = useSelector((state: RootState) => state);
  const [isOpenAddUser, setIsOpenAddUser] = useState<boolean>(false);
  const [orgCompanies, setOrgCompanies] = useState(currentCompany?.account?.companies);

  const licensesLeft = useMemo(() => {
    return quantityLicensesUsers(dxUserData, currentCompany?.account);
  }, [])

  const maxUsers = useMemo(() => {
    return quantityMaxUsers(dxUserData, currentCompany?.account);
  }, [])

  const licensesUsed = currentCompany?.account?.license?.max_users - currentCompany?.account?.license?.used_licenses;

  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);


  const isUpgradePlanDisabled = () => {
    return permissions.manage_users?.features?.upgrade_plan_button === 'disable';
  }
  

  useEffect(() => {
    setOrgCompanies(currentCompany?.account?.companies);
  }, [currentCompany]);

  const licenses = currentCompany?.account?.license.max_users - currentCompany?.account?.license.used_licenses

  return (
    <>
      <Container>
        <Text>Manage Users</Text>
        {permissions?.manage_users?.features?.add_new_user_button === "enable" && (
          <DXButton
            children={"Add new user"}
            size={"large"}
            variant={"contained"}
            disabled={licenses <= 0}
            onClick={() => setIsOpenAddUser(true)}
            startIcon={<DXIcon name="Plus" cssClass="dark-icon" />}
          />
        )}
      </Container>
      <Wrapper>
        <FilterContainer>
          <AccountContainer></AccountContainer>
        </FilterContainer>
        {permissions.manage_users?.features?.upgrade_plan_button === 'enable' && (
          <UpgradeContainer>
            <TextUpgrade>You have</TextUpgrade>
            <TextUpgrade className="number">{licensesUsed}</TextUpgrade>
            <TextUpgrade>license(s) available. Need more?</TextUpgrade>
            <UpgradeButton 
            onClick={() => {
              if(permissions.manage_users?.features?.upgrade_plan_button === 'enable') setOpenModalPlan(true);
            }}
            style={{
              cursor: isUpgradePlanDisabled() ? 'not-allowed' : 'pointer',
              opacity: isUpgradePlanDisabled() ? 0.5 : 1,
            }}>

          
              Upgrade plan
            </UpgradeButton>
          </UpgradeContainer>
        )}
      </Wrapper>
      <CompanyContainer>
        <TableManageUsers
          permissions={permissions}
          accountId={currentCompany?.account?.id}
          maxUsers={maxUsers}
          setIsOpenModal={setOpenModal}
        />
      </CompanyContainer>

        <ModalAddUsers
          isOpen={isOpenAddUser}
          closeModal={() => setIsOpenAddUser(false)}
          className="addUser"
          companies={orgCompanies}
          accountId={currentCompany?.account?.id}
          userLeft={licensesUsed}
        />

      {openModalPlan && (
        <DXModal
          title={"Change Plan"}
          open={openModalPlan}
          onClose={() => setOpenModalPlan(false)}
          customWidth={700}
          children={
            <ModalPlanSettings
              setOpenModalPlan={setOpenModalPlan}
              dxUserData={dxUserData}
            />
          }
        />
      )}

      {openModal && (
        <CompanyModal
          isOpen={openModal}
          setIsClose={setOpenModal}
          setTabValue={setTabValue}
        />
      )}
    </>
  );
};
