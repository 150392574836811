import { useState } from "react";

import { Subscription } from "./Subscription";
import DXAlert from "features/common/components/DXAlert/DXAlert";
import { TableProfile } from "./TableProfile";
import { PersonalSettings } from "./PersonalSettings";
import { SettingsProps } from "../utils";

import { ContainerBoxes, WrapperAlert } from "./Profile.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

export const Profile = ({ dxUserData }: SettingsProps) => {
  const [alertMessage, setAlertMessage] = useState("");
  const { currentCompany } = useSelector((state: RootState) => state);
  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac;
  const changePlanPermission = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac)?.my_profile?.my_subscription?.change_plan;

  return (
    <>
      {alertMessage && (
        <WrapperAlert>
          <DXAlert
            children={alertMessage}
            color="success"
            sx={{ width: "100%" }}
            onClose={() => setAlertMessage("")}
          />
        </WrapperAlert>
      )}
      <ContainerBoxes>
        <PersonalSettings
          setAlertMessage={setAlertMessage}
          name={dxUserData.name}
          email={dxUserData.email}
        />
        <Subscription
          license={currentCompany?.account?.license}
          role={currentCompany?.account?.role}
          isDisabled={ changePlanPermission === 'disable' }
        />
      </ContainerBoxes>
      <TableProfile accounts={dxUserData?.accounts} />
    </>
  );
};
