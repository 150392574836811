import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import QRCode from "react-qr-code";
import {Amplify, Auth} from "aws-amplify";
import DXAlert from "features/common/components/DXAlert";
import DXButton from "features/common/components/DXButton";
import DXInputField from "features/common/components/DXInputField";
import DXModal from "features/common/components/DXModal/DXModal";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/actions";
import { checkUserInvite } from "services/api";
import { setUserCredentials } from "utils/setUserCredentials";
import { handleLink } from "utils/handleLink";
import { useHistory } from "react-router-dom";
import { ROUTES } from "features/routes";
import amplifyConfig from "config/amplify-config";

export interface QrCodeProps {
  isOpenModalQr: boolean;
  setIsOpenModalQr: Dispatch<SetStateAction<boolean>>;
  handleQr?: void;
  title: string;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  qrCodeUrl: string;
  user: any;
}

Auth.configure(amplifyConfig);
Amplify.configure(amplifyConfig);

export const ModalQrCode = ({
  setIsOpenModalQr,
  isOpenModalQr,
  title,
  setCurrentStep,
  qrCodeUrl,
  user,
}: QrCodeProps) => {
  const [isError, setError] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const [verifyCode, setVerifyCode] = useState({
    code: "",
  });

  const handleCode = async () => {

    try {
      await Auth.verifyTotpToken(user, verifyCode.code);

      await Auth.setPreferredMFA(user, "TOTP");

      setUserCredentials({
        accessToken: user.signInUserSession.accessToken.jwtToken,
        idToken: user.signInUserSession.idToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token,
      });

      dispatch(
        setCredentials({
          accessToken: user.signInUserSession.accessToken.jwtToken,
          idToken: user.signInUserSession.idToken.jwtToken,
          refreshToken: user.signInUserSession.refreshToken.token,
        })
      );

      const isInvite = await checkUserInvite()

      if (isInvite) {

        history.push(ROUTES.UPLOAD_FILES_PATH);

        console.log("isInvite")
      } else {
        console.log("isNotInvite")
        handleLink("#2");
        setCurrentStep((prevStep) => prevStep + 1);
      }

    } catch (error) {
      console.error("Error handling code:", error);
      setError(error.message);
    }
  };

  const handleChangeVerifyCode = (value: string) => {
    setVerifyCode({
      code: value,
    });
  };

  return (
    <DXModal
      title={title}
      open={isOpenModalQr}
      onClose={() => setIsOpenModalQr(false)}
      children={
        <>
          <Box sx={{ textAlign: "center", fontSize: "20px" }}>
            Open your MFA provider and scan the QR code
          </Box>

          {qrCodeUrl && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 4,
                flexDirection: "column",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <QRCode value={qrCodeUrl} />
              <DXInputField
                label="Confirmation code"
                type={"number"}
                placeholder="Enter the code sent to your authenticator app"
                fullWidth
                value={verifyCode.code || ""}
                onChange={({ target }) => handleChangeVerifyCode(target.value)}
              />
              {isError && (
                <DXAlert
                  children={"Incorrect Code: Try Again"}
                  color="error"
                  sx={{ width: "100%" }}
                  onClose={() => {
                    setError("");
                  }}
                />
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: 4,
            }}
          >
            <DXButton
              color="secondary"
              variant="contained"
              sx={{ minWidth: "140px" }}
              onClick={() => setIsOpenModalQr(false)}
            >
              Cancel
            </DXButton>
            <DXButton
              variant="contained"
              sx={{ minWidth: "140px" }}
              disabled={!verifyCode}
              onClick={() => {
                handleCode();
              }}
            >
              Continue set up
            </DXButton>
          </Box>
        </>
      }
    />
  );
};
