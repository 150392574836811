import { Dispatch, SetStateAction } from "react";

import DXIcon from "../DXIcon";

import {
  ContainerText,
  Icon,
  Information,
  StepContainer,
  StepIcon,
  Subtitle,
  TextInformation,
} from "./Step.styles";

interface StepProps {
  title: string;
  color: string;
  text?: string;
  subtitle?: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
  isUpload?: boolean;
}

export const Step = ({
  title,
  color,
  text,
  subtitle,
  setIsOpen,
  isOpen,
  isUpload,
}: StepProps) => {
  return (
    <StepContainer className={isUpload ? "isUpload" : ""}>
      <Information>
        <StepIcon color={color}>{title}</StepIcon>
        <ContainerText>
          <TextInformation className={title === "Type 3" ? "step3" : ""}>
            {text}
          </TextInformation>
          <Subtitle className={title === "Type 3" ? "step3" : ""}>
            {subtitle}
          </Subtitle>
        </ContainerText>
      </Information>
      {title !== "Type 3" && !isUpload && (
        <Icon
          onClick={() => setIsOpen((prev) => !prev)}
          className={isOpen ? "rotate" : ""}
        >
          <DXIcon name="ChevronUp" />
        </Icon>
      )}
    </StepContainer>
  );
};
