import styled from "styled-components";
import { Icon } from "../../Icon";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 220px));
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;

  :hover {
    background: ${({ theme }) => theme.colors.purple100};
    border-radius: 6px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const IconStyle = styled(Icon)`
  cursor: pointer;
`;
