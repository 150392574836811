import {
  createTheme,
  responsiveFontSizes,
  darken,
  lighten,
  alpha,
} from "@mui/material/styles";

declare module "@mui/material/Button/Button" {
  interface ButtonPropsVariantOverrides {
    xsmall: true;
  }
}

let muiTheme = createTheme({
  palette: {
    common: {
      black: "#100f0d",
      white: "#fff",
    },
    primary: {
      main: "#6938EF",
      dark: darken("#6938EF", 0.15),
      light: lighten("#6938EF", 0.9),
      contrastText: "#fff",
    },
    secondary: {
      main: "#F2F4F7",
      dark: "#EAECF0",
      light: "#F9FAFB",
      contrastText: "#1D2939",
    },
    error: {
      main: "#D92D20",
      dark: darken("#D92D20", 0.15),
      light: lighten("#D92D20", 0.9),
      contrastText: "#fff",
    },
    info: {
      main: "#004EEB",
      dark: darken("#004EEB", 0.15),
      light: lighten("#004EEB", 0.9),
      contrastText: "#fff",
    },
    success: {
      main: "#12B76A",
      dark: darken("#12B76A", 0.15),
      light: lighten("#12B76A", 0.9),
      contrastText: "#fff",
      100: "#D1FADF",
    },
    warning: {
      main: "#12B76A",
      dark: darken("#12B76A", 0.15),
      light: lighten("#12B76A", 0.9),
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Inter"].join(","),
    fontWeightRegular: 500,
    body1: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.19em",
    },
  },
  shape: {
    borderRadius: 5,
  },
});
muiTheme = responsiveFontSizes(muiTheme);
muiTheme = createTheme(muiTheme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        size: "large",
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 600,
          textTransform: "none",
          borderRadius: "6px",
          fontFamily: "Inter",
          "&:focus": {
            outline: "4px solid",
          },
          "&:disabled": {
            opacity: 0.7,
            outline: "none",
          },
          ...(ownerState.variant === "text" && {
            backgroundColor: muiTheme.palette[ownerState.color].light,
            border: "1px solid",
            borderColor: darken(muiTheme.palette[ownerState.color].light, 0.08),
            "&:hover": {
              backgroundColor: lighten(
                muiTheme.palette[ownerState.color].main,
                0.8
              ),
            },
            "&:focus": {
              outline: "4px solid",
              outlineColor: lighten(
                muiTheme.palette[ownerState.color].main,
                0.8
              ),
            },
          }),
          ...(ownerState.variant === "contained" && {
            "&:focus": {
              outline: "4px solid",
              outlineColor: lighten(
                muiTheme.palette[ownerState.color].main,
                0.6
              ),
            },
            "&:disabled": {
              opacity: 0.3,
              outline: "none",
              backgroundColor: muiTheme.palette[ownerState.color].main,
              color: muiTheme.palette[ownerState.color].contrastText,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              "& .dark-icon > g > path": {
                stroke: muiTheme.palette[ownerState.color].contrastText,
              },
            }),
          ...(ownerState.variant === "xsmall" && {
            "&:hover": {
              backgroundColor: lighten(
                muiTheme.palette[ownerState.color].main,
                0.9
              ),
            },
            "&:focus": {
              outline: "2px solid",
              outlineColor: lighten(
                muiTheme.palette[ownerState.color].main,
                0.6
              ),
            },
          }),
          ...(ownerState.variant === "xsmall" &&
            ownerState.color === "success" && {
              "&:hover": {
                color: darken(muiTheme.palette[ownerState.color].main, 0.2),
                backgroundColor: lighten(
                  muiTheme.palette[ownerState.color].main,
                  0.9
                ),
              },
            }),
          ...(ownerState.variant === "xsmall" &&
            ownerState.color === "error" && {
              "&:hover": {
                color: darken(muiTheme.palette[ownerState.color].main, 0.2),
                backgroundColor: lighten(
                  muiTheme.palette[ownerState.color].main,
                  0.9
                ),
              },
            }),
          ...(ownerState.variant === "xsmall" &&
            ownerState.color === "info" && {
              "&:hover": {
                color: darken(muiTheme.palette[ownerState.color].main, 0.2),
                backgroundColor: lighten(
                  muiTheme.palette[ownerState.color].main,
                  0.9
                ),
              },
            }),
        }),
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            padding: muiTheme.spacing(0.6, 1.7),
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: muiTheme.spacing(1, 2),
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "1rem",
            padding: muiTheme.spacing(1, 2.5),
          },
        },
        {
          props: { variant: "xsmall" },
          style: {
            textTransform: "none",
            fontSize: ".7rem",
            padding: muiTheme.spacing(0.4, 1.2),
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "label + &": {
            marginTop: muiTheme.spacing(3),
          },
          "& .MuiInputBase-input": {
            borderRadius: 6,
            boxShadow: "rgba(16, 24, 40, 0.06) 0px 1px 2px 0px",
            position: "relative",
            border: "1px solid",
            borderColor: "transparent",
            fontSize: 16,
            padding: "8px 12px",
            transition: muiTheme.transitions.create(["box-shadow"]),
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: 6,
            color: "#344054",
            borderColor: "#d0d5de",
            "&:hover fieldset": {
              transition: "0.2s",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#d0d5de",
              boxShadow: `${alpha(
                muiTheme.palette.primary.main,
                0.2
              )} 0 0 0 0.25rem`,
            },
          },
          "& .MuiInputBase-colorPrimary .Mui-focused": {
            border: "none",
          },
          "& .MuiOutlinedInput-input": {
            font: "caption",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          color: "#344054",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          border: "1px solid",
          ...(ownerState.color === "success" && {
            background: lighten(muiTheme.palette.success.main, 0.95),
            borderColor: "#6CE9A6",
            color: muiTheme.palette.success.dark,
          }),
          ...(ownerState.color === "error" && {
            background: lighten(muiTheme.palette.error.main, 0.95),
            borderColor: "#F04438",
            color: muiTheme.palette.error.dark,
          }),
          ...(ownerState.color === "info" && {
            background: lighten(muiTheme.palette.info.main, 0.95),
            borderColor: "#84ADFF",
            color: muiTheme.palette.info.dark,
          }),
          ...(ownerState.color === "warning" && {
            background: "#FEFDF0",
            borderColor: "#FDE272",
            color: "#CA8504",
            "& .MuiSvgIcon-root": {
              color: "#CA8504",
            },
          }),
          "& .MuiAlert-message": {
            padding: "0",
          },
        }),
      },
    },
  },
});

export default muiTheme;
