import {
  PaginationLeft,
  PaginationLeftEnd,
  PaginationRight,
  PaginationRightEnd,
} from "assets/icons";
import { useState } from "react";

import { Img, Page, PaginationContainer } from "./styles";

export const Pagination = () => {
  const [page, setPage] = useState<number>(1);

  const nextPage = () => {
    if (page > 0) setPage(page + 1);
  };

  const previousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <PaginationContainer>
      <Img src={PaginationLeftEnd} />
      <Img src={PaginationLeft} onClick={previousPage} />
      <Page>{page}</Page>
      <Img src={PaginationRight} onClick={nextPage} />
      <Img src={PaginationRightEnd} />
    </PaginationContainer>
  );
};
