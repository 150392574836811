import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { IDXUserData } from "redux/types";

interface IUserCredentials {
  accessToken?: string;
  clientId?: string;
  email?: string;
  firstName?: string;
  idToken?: string;
  lastName?: string;
  refreshToken?: string;
  sub?: string;
}

const useCurrentUser = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<IUserCredentials>({});
  const [dxUserData, setDxUserData] = useState<IDXUserData>({});

  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
        setUserData({
          accessToken: response.signInUserSession.accessToken.jwtToken,
          clientId: jwtDecode(response.signInUserSession.accessToken.jwtToken)[
            "client_id"
          ],
          email: jwtDecode(response.signInUserSession.idToken.jwtToken)[
            "email"
          ],
          firstName: response.attributes["custom:first_name"],
          idToken: response.signInUserSession.idToken.jwtToken,
          lastName: response.attributes["custom:last_name"],
          refreshToken: response.signInUserSession.refreshToken.token,
          sub: response.attributes.sub,
        });

        const dxResponse = await axios.request({
          url: `${process.env.REACT_APP_API_BASE}memberships/user/profile`,
          headers: {
            Authorization: `Bearer ${response.signInUserSession.accessToken.jwtToken}`,
          },
          method: "GET",
        });

        setDxUserData(dxResponse.data);
      } catch (error) {
        console.error(error);
        setIsLoggedIn(false);
      }
    };
    checkUser();
  }, []);

  return {
    isLoggedIn,
    dxUserData,
    userData,
  };
};

export default useCurrentUser;
