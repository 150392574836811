import { HeaderProps } from "./types";

import { HeaderContainer, IconClose, Text, Wrapper } from "./Modal.styles";

function Header({ children, className, onClose }: HeaderProps) {
  return (
    <HeaderContainer className={className}>
      <Wrapper>
        <IconClose iconName="x-close" onClick={onClose} />
      </Wrapper>
      <Text>{children}</Text>
    </HeaderContainer>
  );
}

export { Header };
