import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import DxTable from "features/common/components/DXTable/DXTable";
import DXIcon from "features/common/components/DXIcon";
import { deleteData, getData } from "services/api";
import { formatManageUserData, formatUserData } from "utils/formatUserData";
import { ROUTES } from "features/routes";
import { Cell } from "../ManageCompanies/Cell";
import { manageUsersColumns } from "../ManageCompanies/utils";
import { queryClient } from "index";
import { ModalDeleteCompany } from "../ManageCompanies/ModalDeleteCompany";
import { useDispatch, useSelector } from "react-redux";
import { Permissions } from "utils/rbac";

import {
  ButtonContainer,
  ComponentTitle,
  Container,
  Icon,
  OrgComponent,
  UserComponent,
  Wrapper,
} from "../ManageCompanies/TableManageCompany.styles";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import { RootState } from "redux/reducers";
import toast from "react-hot-toast";

interface TableProps {
  role?: string;
  maxUsers: number;
  accountId: string;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  permissions: Permissions;
}

export const TableManageUsers = ({
  permissions,
  maxUsers,
  accountId,
  setIsOpenModal,
}: TableProps) => {
  const { push } = useHistory();
  const [isOpenTable, setIsOpenTable] = useState(true);
  const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userLeft, setUsersLeft] = useState(null);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector((state: RootState) => state);


  const users = useQuery(
    `getUsers`,
    () => getData(`/memberships/account/${accountId}/user`),
    {
      onSuccess: (data) => console.log(data),
      enabled: !!accountId,
      onError: () => {
        setIsOpenModal(true);
      },
    }
  ).data?.response;

  useEffect(() => {
    setUsersLeft(maxUsers - users?.items?.length);
  }, [users]);

  const handleOpenDeleteUser = (userId) => {
    setSelectedUserId(userId);
    setIsOpenDeleteUser(true);
  };

  const handleOpenEditUser = (userId) => {
    setSelectedUserId(userId);
    setIsOpenEditUser(true);
  };

  const handleDeleteUser = async (userId) => {
    setTableData((prevData) => prevData.filter((user) => user.id !== userId));
    deleteData(`memberships/user/${userId}`)
      .then(async () => {
        setIsOpenDeleteUser(false);
        queryClient.invalidateQueries("getUsers");
        const resp = await getData(`/memberships/user/profile`);
        const rbac = resp.rbac;
        const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
        const company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
        dispatch(setDXUserData(resp));
        dispatch(setCurrentCompany({ account, company, rbac }))
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast.error("Error deleting user");
      });
      toast.success("User deleted successfully");
  };

  const [tableData, setTableData] = useState([]);


  const isEditDisabled = () => {
		return permissions.manage_users.features_2.edit_everyone === 'disable';
	}
	const isDeleteDisabled = () => {
		return permissions.manage_users.features_2.delete_everyone === "disable";
	}
	const isEditHidden = () => {
		return permissions.manage_users.features_2.delete_everyone === "hide";
	}
	const isDeleteHidden = () => {
		return permissions.manage_users.features_2.delete_everyone === "hide";
	}


  const actions = {
    field: "actions",
    headerName: "Actions",
    align: "center",
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => (
      <Cell
        setSelectedUser={setSelectedUser}
        setIsOpenEditUser={setIsOpenEditUser}
        handleOpenDeleteUser={handleOpenDeleteUser}
        handleOpenEditUser={handleOpenEditUser}
        params={params}
        editDisabled={isEditDisabled()}
        deleteDisabled={isDeleteDisabled()}
        editHide={isEditHidden()}
        deleteHide={isDeleteHidden()}
      />
    ),
  };

  const handleColumns = () => [...manageUsersColumns, actions];

  useEffect(() => {
    if (users) {
      setTableData(formatManageUserData(users));
    }
  }, [users]);

  useEffect(() => {
    if (isOpenEditUser) {
      push(ROUTES.EDIT_USER_PATH.replace(":user_id", selectedUserId));
    }
  }, [isOpenEditUser]);

  return (
    <>
      {users && (
        <ComponentTitle className={isOpenTable ? "isOpen" : "isClose"}>
          <Wrapper>
            <Container>
              <OrgComponent>
							{currentCompany?.account.name}
						</OrgComponent>
              <UserComponent>
                <DXIcon name="UserPurple" />
                {users.length} users
              </UserComponent>
            </Container>
          </Wrapper>
          <ButtonContainer>
            <Icon
              onClick={() => setIsOpenTable((prev) => !prev)}
              className={isOpenTable ? "rotate" : ""}
            >
              <DXIcon name="ChevronUp" />
            </Icon>
          </ButtonContainer>
        </ComponentTitle>
      )}
      {isOpenTable && (
        <DxTable
          checkboxField={false}
          rows={tableData}
          columns={handleColumns()}
        />
      )}

      {isOpenDeleteUser && (
        <ModalDeleteCompany
          isOpenModalDelete={isOpenDeleteUser}
          setIsOpenModalDelete={setIsOpenDeleteUser}
          handleDelete={handleDeleteUser}
          id={selectedUserId}
          text="Are you sure you want to delete this user?"
          confirmLabel="Yes, delete"
          title="Delete"
        />
      )}
    </>
  );
};
