import { Link } from "react-router-dom";
import { Popover } from "@mui/material";
import { Icon } from "../../Icon";

import styled from "styled-components";

export const Container = styled.div<{ isOpen?: string }>`
  position: fixed;
  display: flex;
  height: 100%;
  width: 250px;
  left: 0;
  top: 0;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: var(
    --sidebar-bg,
    linear-gradient(180deg, #1d2a6a 0%, #40379f 61.98%, #3843b4 100%)
  );
  z-index: 10;

  @media screen and (max-width: 730px) {
    width: ${(props) => (props.isOpen ? "250px" : "0px")};
  }
`;

export const Hamburger = styled.img<{ isUpperHeader?: boolean }>`
  left: 8px;
  position: fixed;
  top: ${(props) => (props.isUpperHeader ? "39px" : "34px")};
  width: 27px;
`;

export const IconDX = styled(Icon)`
  width: 172px;
  height: 47px;
`;

export const Column = styled.div``;

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const Wrapper = styled.div`
  height: 100%;
`;

export const Row = styled.button`
  display: flex;
  width: 220px;
  height: 38px;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  background: transparent;
  border: none;

  &.isActive {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.blue700};
  }

  &.disabled {
    cursor: not-allowed;
    }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const LinkPath = styled(Link)`
  text-decoration: none;
`;

export const PopoverStyle = styled(Popover)`
  .MuiPaper-root {
    padding: 4px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #595dc1;
  margin-bottom: 16px;
`;
