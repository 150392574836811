import { useState } from "react";

import DXButton from "features/common/components/DXButton/DXButton";
import DXTooltip from "features/common/components/DXTooltip";
import { License } from "redux/types";
import DXModal from "features/common/components/DXModal";
import { ModalPlanSettings } from "../ModalPlanSettings";

import {
  Container,
  Title,
  Text,
  Input,
  Information,
  ContainerButton,
  Column,
  TextContainer,
  RowText,
} from "./Profile.styles";
import useCurrentUser from "hooks/useCurrentUser";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

interface SubscriptionProps {
  license: License;
  role: string;
  isDisabled: boolean;
}
export const Subscription = ({ license, role, isDisabled }: SubscriptionProps) => {
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const { dxUserData } = useCurrentUser();
  const { currentCompany } = useSelector((state: RootState) => state);

  const orgRole = currentCompany?.account?.role;
  const compRole = currentCompany?.company?.role;
  const rbac = currentCompany?.rbac
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  return (
    <Container>
      <Title>My Subscription</Title>
      <Information>
        <TextContainer>
          <Column>
            <Input className="license">
              {license?.interval_user === "year"
                ? "Annual Plan"
                : "Monthly Plan"}
            </Input>
            <Input>
              Since{" "}
              {new Date(license?.starts_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Input>
          </Column>
          <RowText>
            <Input className="spacing">Your current plan allows for </Input>
            <Input className="spacing bold">
              {" "}
              {license?.max_companies} companies{" "}
            </Input>
            <Input className="spacing"> and </Input>
            <Input className="spacing bold"> {license?.max_users} users</Input>
          </RowText>
        </TextContainer>

        <ContainerButton className="subscription">
          {permissions?.my_profile?.my_subscription?.change_plan === 'disable' ? (
            <DXTooltip
              title="Please contact your administrator to change the plan."
              placement="left-start"
            >
              <span
              style={{ cursor: "not-allowed" }}
              >
                <DXButton
                  size="medium"
                  color="info"
                  variant="text"
                  children="Change Plan"
                  disabled={isDisabled}
                />
              </span>
            </DXTooltip>
          ) : (
            <DXButton
              size="medium"
              color="info"
              variant="text"
              children="Change Plan"
              onClick={() => setOpenModalPlan(true)}
            />
          )}
        </ContainerButton>
      </Information>
      {openModalPlan && (
        <DXModal
          title={"Change Plan"}
          open={openModalPlan}
          onClose={() => setOpenModalPlan(false)}
          customWidth={700}
          children={
            <ModalPlanSettings
              setOpenModalPlan={setOpenModalPlan}
              dxUserData={dxUserData}
            />
          }
        />
      )}
    </Container>
  );
};
