import React from "react";

import { Wrapper } from "features/common/components/Wrapper";
import { EditUser } from "features/Settings/ManageUsers/EditUser";

import GlobalStyle from "styles/globalStyles";

const EditUserPage: React.FC = () => (
  <Wrapper>
    <GlobalStyle />
    <EditUser />
  </Wrapper>
);

export default EditUserPage;
