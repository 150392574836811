export const step2Information = [
  {
    iconName: "bills",
    text: "Bills",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/bill.xlsx#",
  },
  {
    iconName: "bills",
    text: "Bill Payments",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/billpayment.xlsx#",
  },
  {
    iconName: "credit-card-charges",
    text: "Credit Card Payment",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/creditcardpayment.xlsx#",
  },
  {
    iconName: "credit-memos",
    text: "Credit Memos",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/creditmemo.xlsx#",
  },
  {
    iconName: "deposits",
    text: "Deposits",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/deposit.xlsx#",
  },
  {
    iconName: "estimates",
    text: "Estimates",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/estimate.xlsx#",
  },
  {
    iconName: "invoices",
    text: "Invoices",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/invoice.xlsx#",
  },
  {
    iconName: "jounal-entries",
    text: "Journal Entries",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/journalentry.xlsx#",
  },
  {
    iconName: "purchase-orders",
    text: "Purchase Orders",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/purchaseorder.xlsx#",
  },
  {
    iconName: "refund-receipts",
    text: "Refund Receipts",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/refundreceipt.xlsx#",
  },
  {
    iconName: "sales-receipts",
    text: "Sales Receipts",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/salesreceipt.xlsx#",
  },
  {
    iconName: "time-activities",
    text: "Time Activities",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/timeactivity.xlsx#",
  },
  {
    iconName: "transfers",
    text: "Transfers",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/transfer.xlsx#",
  },
  {
    iconName: "vendor-credits",
    text: "Vendor Credits",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/vendorcredit.xlsx#",
  },
];
