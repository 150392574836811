import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import CircularProgress from "@mui/material/CircularProgress";
import DXButton from "features/common/components/DXButton/DXButton";
import DXModal from "features/common/components/DXModal";
import { IDXUserData } from "redux/types";
import { getData } from "services/api";
import { ManageClientAccount } from "../ManageCompanies/ManageClientCompany/ManageClientCompany";
import { ModalPlanSettings } from "../ModalPlanSettings";

import { quantityLicensesUsers, quantityMaxCompanies, quantityMaxUsers } from "utils/quantityLicensesAndUsers";
import {
  Card,
  CardText,
  CardTextContainer,
  Column,
  ColumnText,
  ColumnWrapper,
  ContainerCard,
  ContainerText,
  LinearProgressStyle,
  Row,
  Text,
  TextSubtitle,
  Wrapper,
} from "./ManagePlan.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

interface ManagePlanProps {
  setTabValue: Dispatch<SetStateAction<number>>;
  dxUserData: IDXUserData;
}

export const ManagePlan = ({ setTabValue, dxUserData }: ManagePlanProps) => {
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [isClientAccount, setClientAccount] = useState(false);
  const [account, setAccount] = useState(null);
  const { currentCompany } = useSelector((state: RootState) => state);

  useEffect(() => {
    setAccount(currentCompany?.account);
  }, [currentCompany])

  const maxQuickbooksLicenses = currentCompany?.account?.license.max_companies;

  const usedQuickbooksLicenses = dxUserData.accounts ? currentCompany?.account?.companies.length : 0;

  const maxUserLicenses = currentCompany?.account?.license.max_users;

  const usedUserLicenses = dxUserData.accounts ? parseInt(currentCompany?.company?.number_of_users) : 0;

  const { data: content, isLoading } = useQuery(`getCard`, () =>
    getData(`/sales/cards?membership_account_id=${currentCompany?.account?.id}`)
  );

  const handlePlanClick = () => {
    window.location.href = 'https://billing.stripe.com/p/login/eVaeX890t0qg3bWdQQ';
  };

  const availableLicenses = maxQuickbooksLicenses - usedQuickbooksLicenses;
  const compRole = currentCompany?.company?.role;
  const orgRole = currentCompany?.account?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  return (
    <ColumnWrapper>
      <Column>
        <ContainerCard>
          <ContainerText>
            <Text>QuickBooks Online Account Licenses</Text>
            <TextSubtitle className="bold">
              {usedQuickbooksLicenses > maxQuickbooksLicenses ? maxQuickbooksLicenses : usedQuickbooksLicenses} of {currentCompany?.account?.license.max_companies} License(s)
              Used
            </TextSubtitle>
            <LinearProgressStyle
              variant="determinate"
              value={(usedQuickbooksLicenses / currentCompany?.account?.license.max_companies) * 100}
              sx={{ height: 8 }}
            />
            {permissions?.manage_plans?.quickbooks_licenses?.upgrade_plan === 'enable' && (
              <Wrapper>
                <TextSubtitle>Need more spots?</TextSubtitle>
                <TextSubtitle
                  onClick={() => setOpenModalPlan(true)}
                  className="blue"
                >
                  Upgrade plan
                </TextSubtitle>
              </Wrapper>
            )}
            <Card>
              <CardTextContainer>
                <CardText className="number">
                  {availableLicenses < 0 ? 0 : availableLicenses}
                </CardText>
                <CardText>license(s) available</CardText>
              </CardTextContainer>
              {permissions?.manage_plans?.quickbooks_licenses?.add_client_button === 'enable' && (
                <DXButton
                  children="Add New Client Company"
                  variant="text"
                  color="info"
                  size="medium"
                  disabled={maxQuickbooksLicenses - usedQuickbooksLicenses === 0}
                  onClick={() => setClientAccount(true)}
                />)}
            </Card>
          </ContainerText>
        </ContainerCard>
        <ContainerCard>
          <ContainerText>
            <Text>User Licenses</Text>
            {!usedUserLicenses ? (
              <>
                <TextSubtitle className="bold">
                  You need to add a client account to see your user licenses
                </TextSubtitle>
                <DXButton
                  children="Add New Client Company"
                  variant="text"
                  color="info"
                  size="medium"
                  onClick={() => setClientAccount(true)}
                />
              </>
            ) : (
              <>
                {permissions?.manage_plans?.user_licenses?.available_licenses === 'enable' && (
                  <>
                    <TextSubtitle className="bold">
                      {currentCompany?.account?.license.used_licenses} of {currentCompany?.account?.license.max_users} License(s) Used
                    </TextSubtitle>
                    <LinearProgressStyle
                      variant="determinate"
                      value={(usedUserLicenses / maxUserLicenses) * 100}
                      sx={{ height: 8 }}
                    />
                  </>
                )}
                {permissions?.manage_plans?.user_licenses?.upgrade_plan === 'enable' && (
                  <Wrapper>
                    <TextSubtitle>Need more spots?</TextSubtitle>
                    <TextSubtitle
                      onClick={() => setOpenModalPlan(true)}
                      className="blue"
                    >
                      Upgrade plan
                    </TextSubtitle>
                  </Wrapper>
                )}
                <Card>
                  {permissions.manage_plans?.user_licenses?.available_licenses === 'enable' && (
                  <CardTextContainer>
                    <CardText className="number">
                      {currentCompany?.account?.license.max_users - currentCompany?.account?.license.used_licenses}
                    </CardText>
                    <CardText>license(s) available</CardText>
                  </CardTextContainer>
                  )}
                  <Wrapper className="plan">
                    {permissions.manage_plans?.user_licenses?.add_user_workspace === 'enable' && (
                    <Row>
                      <TextSubtitle>Add users directly in your</TextSubtitle>
                      <TextSubtitle
                        onClick={() => setTabValue(1)}
                        className="blue"
                      >
                        workspace
                      </TextSubtitle>
                    </Row>
                    )}
                    <TextSubtitle className="italic">
                      Users can work across multiple accounts at no additional
                      charge.
                    </TextSubtitle>
                  </Wrapper>
                </Card>
              </>
            )}
          </ContainerText>
        </ContainerCard>
      </Column>
      <Column>
        <ContainerCard>
          <ContainerText>
            <Text>Plan Details</Text>
            <Card>
              <ColumnText>
                <CardText className="plan">
                  {currentCompany?.account?.license.interval_user === "year"
                    ? "Annual Plan"
                    : "Monthly Plan"}
                </CardText>
                <CardText className="planSubtitle">
                  Your subscription will renew on{" "}
                  {new Date(
                    currentCompany?.account?.license.ends_at
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </CardText>
              </ColumnText>
              {permissions?.manage_plans?.plan_details?.change_plan === 'enable' && (
              <DXButton
                children="Change Plan"
                variant="text"
                color="info"
                size="small"
                onClick={() => setOpenModalPlan(true)}
              />
              )}
            </Card>
          </ContainerText>
        </ContainerCard>
        <ContainerCard>
          <Text>Payment</Text>
          <Card>
            {isLoading ? (
              <CircularProgress size={28} sx={{ color: "#6938EF" }} />
            ) : (
              <ColumnText>
                <CardText className="card">
                  {content?.items[0].card.brand.toUpperCase()} ending in{" "}
                  {content?.items[0].card.last4}
                </CardText>
                <CardText className="expiration">
                  Expiration {content?.items[0].card.exp_month}/
                  {content?.items[0].card.exp_year}
                </CardText>
              </ColumnText>
            )}
            { permissions?.manage_plans?.payment?.upgrade_payment === 'enable' && (
            <DXButton
              children="Update Payment Method"
              variant="text"
              color="info"
              size="small"
              onClick={() => handlePlanClick()}
            />
            )}
          </Card>
        </ContainerCard>
      </Column>
      {openModalPlan && (
        <DXModal
          title={"Change Plan"}
          open={openModalPlan}
          onClose={() => setOpenModalPlan(false)}
          customWidth={700}
          children={
            <ModalPlanSettings
              setOpenModalPlan={setOpenModalPlan}
              dxUserData={dxUserData}
            />
          }
        />
      )}
      {isClientAccount && (
        <DXModal
          title={""}
          customWidth={1000}
          open={isClientAccount}
          onClose={() => setClientAccount(false)}
          children={
            <ManageClientAccount onClose={() => setClientAccount(false)} />
          }
        />
      )}
    </ColumnWrapper>
  );
};
