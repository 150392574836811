export const inputFields = [
  {
    label: "Name*",
    id: "name",
    type: "text",
    required: true,
    placeholder: "Enter name",
  },
  {
    label: "Email*",
    id: "email",
    type: "email",
    placeholder: "Enter email address",
    disabled: true,
  },
];
