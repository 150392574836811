import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Modal } from "features/common/components/Modal/Modal";
import { InputField } from "../../../InputField/InputField";
import { Icon } from "../../../Icon";
import { inputFields } from "./utils";
import { ModalProps } from "../types";
import DXButton from "features/common/components/DXButton";
import { getData, post } from "services/api";
import { DropdownUser } from "./DropdownUser";
import { queryClient } from "index";

import { Information, Row, Text } from "../../AddUsers.styles";
import {
  Body,
  Container,
  ContainerFields,
  Wrapper,
} from "./ModalAddUser.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useDispatch } from "react-redux";
import { setCurrentCompany, setDXUserData } from "redux/actions";
import toast from "react-hot-toast";
import { DropdownCompanyRoles } from "features/common/components/DropdownCompanyRoles/DropdownCompanyRoles";
import useCurrentUser from "hooks/useCurrentUser";
import DXIcon from "features/common/components/DXIcon";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export default function ModalAddUser({
  isOpen,
  closeModal,
  userLeft,
  className,
  companyId,
  accountId,
}: ModalProps) {
  const { t } = useTranslation();
  const { currentCompany } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { dxUserData, userData } = useCurrentUser();

  const [isUserListValid, setIsUserListValid] = useState(false);

  const [users, setUsers] = useState<ICreateUser[]>([
    { firstName: "", lastName: "", email: "", role: "" },
  ]);

  const addUser = () => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  };

  const validateList = (list: ICreateUser[]): boolean => {
    return list.every((user) => {
      return Object.values(user).every((value) => value.trim() !== "");
    });
  };

  const handleSubmitUser = async (user) => {
    try {
      const response = await post("/memberships/user", {
        email: user.email,
        account_id: accountId,
        account_role: "ORG_USER",
        companies: [
          {
            company_id: companyId,
            company_role: user.role,
          },
        ],
        name: `${user.firstName} ${user.lastName}`,
      }).then(() => queryClient.invalidateQueries(`getUsers_${companyId}`));
      const resp = await getData(`/memberships/user/profile`);
      dispatch(setDXUserData(resp));
      if (currentCompany.account && currentCompany.company) {
        const account = resp.accounts.find((acc) => acc?.id === currentCompany?.account?.id);
        const company = account.companies.find((comp) => comp?.id === currentCompany?.company?.id);
        const rbac = resp.rbac;
        dispatch(setCurrentCompany({ account, company, rbac }))
      } else {
        dispatch(setCurrentCompany({
          account: dxUserData.accounts[0],
          company: dxUserData.accounts[0].companies[0],
          rbac: resp.rbac
        }));
      }
     
      toast.success("User added successfully");
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const promises = users.map((user) => handleSubmitUser(user));
    await Promise.all(promises);
    handleCloseModal();
  };

  const handleInputChange = (index, fieldId, value) => {
    console.log({ index, fieldId, value });
    const newUsers = [...users];
    newUsers[index][fieldId] = value;
    setUsers(newUsers);
  };

  useEffect(() => {
    validateList(users) ? setIsUserListValid(true) : setIsUserListValid(false);
  }, [users]);

  const handleCloseModal = () => {
    setUsers([{ firstName: "", lastName: "", email: "", role: "" }]);
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <Modal className={className} onClose={handleCloseModal}>
          <>
            <Modal.Header onClose={handleCloseModal}>
              {t("add-user")}
            </Modal.Header>
            <Information className="user">
              <Row>
                <Icon iconName="user-02" size="M" />
                <Text className="information">
                  You have {userLeft} user license(s) left.
                </Text>
              </Row>
            </Information>
            <Modal.Body>
              <Wrapper>
                {users.map((user, index) => (
                  <Body key={index}>
                    {inputFields.map((field) => (
                      <ContainerFields key={field.id}>
                        <InputField
                          label={field.label}
                          id={`${field.id}-${index}`}
                          type={field.type}
                          required={field.required}
                          placeholder={field.placeholder}
                          value={user[field.id]}
                          onChange={(event) =>
                            handleInputChange(
                              index,
                              field.id,
                              event.target.value
                            )
                          }
                        />
                      </ContainerFields>
                    ))}
                    <ContainerFields>
                      <DropdownCompanyRoles
                        title={t("user-type")}
                        value={user.role}
                        handleChange={(event) =>
                          handleInputChange(index, "role", event.target.value)
                        }
                      />
                    </ContainerFields>
                  </Body>
                ))}
                <DXButton
                  type="button"
                  variant="text"
                  startIcon={<DXIcon name="PlusPurple" style={{ color: "#6938EF" }} />}
                  children="Add Another User(s)"
                  onClick={addUser}
                  disabled={users.length >= userLeft}
                />
              </Wrapper>
            </Modal.Body>
            <Modal.ActionButtons>
              <Container>
                <DXButton
                  type="button"
                  variant="contained"
                  children="Send Invites"
                  onClick={handleSubmit}
                  disabled={userLeft <= 0 || !isUserListValid}
                />
              </Container>
            </Modal.ActionButtons>
          </>
        </Modal>
      )}
    </>
  );
}
