import { createSlice } from "@reduxjs/toolkit";
import { IDXUserData } from "redux/types";

const initialState: IDXUserData = {
  name: "",
  email: "",
  created_at: undefined,
  accounts: [],
};

const dxUserDataSlice = createSlice({
  name: "dxUserData",
  initialState,
  reducers: {
    setDXUserData: (state, action) => {
      return action.payload;
    },
    resetDXUserData: (state) => {
      return initialState;
    },
  },
});

export const { setDXUserData, resetDXUserData } = dxUserDataSlice.actions;

export default dxUserDataSlice.reducer;
