import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { handleLink } from "utils/handleLink";
import { StepProps } from "../types";
import { Form } from "./Form/Form";
import DXButton from "features/common/components/DXButton";
import DXIcon from "features/common/components/DXIcon";
import { setAccountId } from "redux/actions";
import { createOrganization, createUser } from "./services";

import * as StyledStep from "features/Steps/Steps.styles";

interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export const Organization = ({ setCurrentStep, selectedCard }: StepProps) => {
  const { t } = useTranslation();
  const [organizationSize, setOrganizationSize] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationDescription, setOrganizationDescription] = useState("");
  const [organizationImage, setOrganizationImage] = useState("");
  const [organizationUser, setOrganizationUser] = useState("");
  const [users, setUsers] = useState<ICreateUser[]>([]);
  const [color, setColor] = useState("");
  const dispatch = useDispatch();

  const handleContinue = async () => {
    try {
      const response = await mutateCreateOrganization({
        name: organizationName,
        organization_size: organizationSize,
        description: organizationDescription,
        logo: process.env.REACT_APP_S3_AMAZONAWS + organizationImage,
        color: color,
      });
      setCurrentStep((prevStep) => prevStep + 1);
      handleLink("#5");
    } catch (error) {
      console.error("Failed to create organization", error);
    }
  };

  const { mutate: mutateCreateUser } = useMutation(createUser);

  const { mutate: mutateCreateOrganization } = useMutation(createOrganization, {
    onSuccess: (response) => {
      dispatch(setAccountId({ accountId: response.data.id }));
      users?.map(({ firstName, lastName, email, role }) =>
        mutateCreateUser({
          name: `${firstName} ${lastName}`,
          email,
          account_role: role,
          account_id: response.data.id,
          companies: [],
        })
      );
    },
  });

  return (
    <StyledStep.PageContainer>
      <StyledStep.StepContainer>
        <StyledStep.Content>
          <StyledStep.Title>
            <StyledStep.StepTitle>
              {t("step-Organization-Title")}
            </StyledStep.StepTitle>
            <StyledStep.StepSubtitle>
              {t("step-Organization-Subtitle")}
            </StyledStep.StepSubtitle>
          </StyledStep.Title>
          <Form
            setOrganizationName={setOrganizationName}
            setOrganizationDescription={setOrganizationDescription}
            setColor={setColor}
            setOrganizationSize={setOrganizationSize}
            setOrganizationImage={setOrganizationImage}
            organizationName={organizationName}
            organizationDescription={organizationDescription}
            organizationSize={organizationSize}
            color={color}
            selectedCard={selectedCard}
            organizationUser={organizationUser}
            setOrganizationUser={setOrganizationUser}
            setUsers={setUsers}
            users={users}
          />
          <StyledStep.ButtonsContainer className="create">
            <DXButton
              color="primary"
              variant="contained"
              endIcon={<DXIcon name="ArrowRight" />}
              onClick={() => {
                handleContinue();
              }}
            >
              Create Your Organization
            </DXButton>
          </StyledStep.ButtonsContainer>
        </StyledStep.Content>
      </StyledStep.StepContainer>
    </StyledStep.PageContainer>
  );
};
