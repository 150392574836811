import { CognitoUser } from "amazon-cognito-identity-js";
import {Amplify, Auth, Hub} from "aws-amplify";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import DXButton from "features/common/components/DXButton/DXButton";
import DXIcon from "features/common/components/DXIcon/DXIcon";
import DXInputField from "features/common/components/DXInputField/DXInputField";
import { handleLink } from "utils/handleLink";
import { FormProps } from "./types";
import { inputFields, inputPasswordFields } from "./utils";

import useCurrentUser from "hooks/useCurrentUser";
import { hasNoEmptyProperties } from "utils/hasNoEmptyProperties";

import * as Styled from "./Form.styles";

import { useLocation } from "react-router-dom";
import { getCognitoUser } from "utils/cognito";
import { ModalQrCode } from "./ModalQrCode";
import amplifyConfig from "config/amplify-config";

export const Form = ({ setCurrentStep }: FormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isOpenQrCode, setIsOpenQrCode] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [userCognito, setUserCognito] = useState<CognitoUser | null>(null);

  const location = useLocation();

  const SetupEmail = () => {
    return location.hash.split("=").length > 1 ? location.hash.split("=").at(-1) : ""
  }

  Auth.configure(amplifyConfig);
  Amplify.configure(amplifyConfig);

  const [payloadData, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: SetupEmail(),
    password: "",
    confirmPassword: "",
  });
  const [verifyCode, setVerifyCode] = useState({
    code: "",
  });

  const { t } = useTranslation();
  const { isLoggedIn } = useCurrentUser();

  const handleChange = (name: string, value: string) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeVerifyCode = (value: string) => {
    if (value.length <= 6) {
      setVerifyCode({
        code: value,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      const { firstName, lastName, email, password } = payloadData;

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          "custom:first_name": firstName,
          "custom:last_name": lastName,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      setIsCreated(true);

      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      setIsLoading(true);
      await Auth.confirmSignUp(
        payloadData.email,
        verifyCode.code
      );
      setIsVerified(true);
      setIsLoading(false);

      Hub.listen("auth", async ({ payload }) => {
        if (payload.event === "autoSignIn") {
          console.log(payload, "event");
          const user = payload.data;
          const cognitoUser = getCognitoUser(
            user.Session,
            user.username,
            payloadData.email as string
          );
          console.log("cognito", cognitoUser)
          setUserCognito(cognitoUser);
          try {
            if (cognitoUser) {
              const secretCode = await Auth.setupTOTP(cognitoUser);
              const qrCodeData = `otpauth://totp/AWSCognito:${cognitoUser.getUsername()}?secret=${secretCode}&issuer=react-client-dev`;

              setQrCodeUrl(qrCodeData);
            } else {
              console.error("User is not authenticated");
            }
          } catch (error) {
            console.error("Error setting up TOTP:", error);
          }
        }
      });
      setVerifyCode({
        code: "",
      });
      setPayload({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (qrCodeUrl) {
      console.log(qrCodeUrl);
      setIsOpenQrCode(true);
    }
  }, [qrCodeUrl]);

  useEffect(() => {
    if (
      hasNoEmptyProperties(payloadData) &&
      payloadData.password === payloadData.confirmPassword
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [payloadData]);

  useEffect(() => {
    if (isLoggedIn) {
      setCurrentStep((prevStep) => prevStep + 1);
      handleLink("#2");
    }
  }, [isLoggedIn]);

  const sendCode = async (username: string) => {

    try {
      await Auth.resendSignUp(username);

      toast.success('Confirmation code resent')
      console.log('Confirmation code resent successfully');
    } catch (error) {
      console.log("errorrrrrrrr")
      console.error('Error resending confirmation code:', error);
    }
  }

  const resendStyle = {
    cursor: "pointer",
    fontSize: "12px",
    color: "#344054",
    textDecoration: "underline",
    marginLeft: "4px"
  }

  return (
    <Styled.FormContainer>
      <Styled.StyledFormSignUp>
        <Styled.Row className="name">
          {inputFields.map((field) => (
            <DXInputField
              key={field.id}
              label={field.label}
              id={field.id}
              type={field.type}
              required={field.required}
              placeholder={field.placeholder}
              value={payloadData[field.id] || ""}
              onChange={({ target }) => handleChange(field.id, target.value)}
              disabled={isLoading || isCreated}
            />
          ))}
        </Styled.Row>
        <DXInputField
          key={"email"}
          label={"Email*"}
          id={"email"}
          type={"email"}
          required
          placeholder={t("enter-email-address")}
          fullWidth
          value={payloadData.email || ""}
          onChange={({ target }) => handleChange(target.id, target.value)}
          disabled={isLoading || isCreated}
        />
        {inputPasswordFields.map((field) => (
          <DXInputField
            key={field.id}
            label={field.label}
            id={field.id}
            type={field.type}
            required={field.required}
            placeholder={field.placeholder}
            fullWidth
            value={payloadData[field.id] || ""}
            onChange={({ target }) => handleChange(field.id, target.value)}
            disabled={isLoading || isCreated}
          />
        ))}
        {isCreated && !isVerified && (
          <>
            <DXInputField
              label={t("confirmation-code")}
              type={"number"}
              placeholder={t("confirmation-code-placeholder")}
              fullWidth
              value={verifyCode.code || ""}
              onChange={({ target }) => handleChangeVerifyCode(target.value)}
            />

            <span style={resendStyle} onClick={() => sendCode(payloadData.email)}>Resend Code</span>
          </>
        )}

        <Styled.ButtonContainer>
          {isCreated && !isVerified && (
            <DXButton
              color="primary"
              variant="contained"
              onClick={() => handleVerifyEmail()}
              endIcon={<DXIcon name="ArrowRight" />}
              disabled={(isLoading || !isVerified) && verifyCode.code.length < 6}
            >
              {t("verify-account-continue")}
            </DXButton>
          )}
          {!isCreated && (
            <DXButton
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={isLoading || !isValid}
            >
              {t("create-account")}
            </DXButton>
          )}
        </Styled.ButtonContainer>
      </Styled.StyledFormSignUp>
      <ModalQrCode
        title="Two Factor Authentication"
        setIsOpenModalQr={setIsOpenQrCode}
        isOpenModalQr={isOpenQrCode}
        setCurrentStep={setCurrentStep}
        qrCodeUrl={qrCodeUrl}
        user={userCognito}
      />
    </Styled.FormContainer>
  );
};
