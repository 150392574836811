import React from "react";

import { Wrapper } from "features/common/components/Wrapper";
import SigInForm from "features/SignInForm/SigInForm";

import GlobalStyle from "styles/globalStyles";

const SignIn: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <SigInForm />
    </Wrapper>
  );
};
export default SignIn;
