import React, { useState } from "react";

import { pluralize } from "./utils";
import { DropdownUser } from "features/common/components/Users/UserModals/ModalAddUser/DropdownUser";
import { Cross } from "assets/icons";
import { Pagination } from "./Pagination";

import {
  Container,
  RowContainer,
  Tag,
  Text,
  DropwdownContainer,
  RemoveButton,
  RowGrid,
  ButtonContainer,
} from "./styles";
import { DropdownCompanyRoles } from '../../../../common/components/DropdownCompanyRoles/DropdownCompanyRoles';

interface UserCompanyTableType {
  companies: {
    name: string;
    role: string;
    company_id: string;
    company_x_user_id: string;
  }[];
  handleInputChange: any;
  setIsOpenRemoveUser: React.Dispatch<React.SetStateAction<boolean>>;
  setCompanyXUserId: React.Dispatch<React.SetStateAction<string>>;
}

export const UserCompanyTable = ({
  companies,
  handleInputChange,
  setIsOpenRemoveUser,
  setCompanyXUserId,
}: UserCompanyTableType) => {
  return (
    <Container>
      <RowContainer>
        <Tag>{pluralize(companies?.length, "Workspace")}</Tag>
      </RowContainer>
      {companies?.map(
        ({ name, company_id, role, company_x_user_id }, index) => (
          <RowContainer>
            <RowGrid>
              <Text>{name}</Text>
              <DropwdownContainer>
                <DropdownCompanyRoles
                  value={role}
                  handleChange={(event) =>
                    handleInputChange("role", event.target.value, index)
                  }
                />
              </DropwdownContainer>
              <ButtonContainer>
                <RemoveButton
                  onClick={() => {
                    setIsOpenRemoveUser(true);
                    setCompanyXUserId(company_x_user_id);
                  }}
                >
                  <img src={Cross} />
                  Remove user
                </RemoveButton>
              </ButtonContainer>
            </RowGrid>
          </RowContainer>
        )
      )}
      <RowContainer>
        <Pagination />
      </RowContainer>
    </Container>
  );
};
