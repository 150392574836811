import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "features/common/components/Icon";
import { Logo } from "./Logo/Logo";

import { Auth } from "aws-amplify";
import useCurrentUser from "hooks/useCurrentUser";
import { useHistory } from "react-router-dom";
import {
  resetCredentials,
  resetCurrentCompany,
  resetDXUserData,
  resetSignupFlow,
} from "redux/actions";
import * as Styled from "./Header.styles";
import { useDispatch } from "react-redux";

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn } = useCurrentUser();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem("DXUSER");
      dispatch(resetCredentials());
      dispatch(resetDXUserData());
      dispatch(resetCurrentCompany());
      dispatch(resetSignupFlow());
      history.push("/sign-in");
    } catch (error) { }
  };

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Logo />
        {/* TODO: login flow */}
        <Styled.ButtonContainer>
          <Styled.Button>
            {!isLoggedIn && (
              <Styled.Text onClick={() => history.push("/sign-in")}>
                {t("login")}
              </Styled.Text>
            )}
            {isLoggedIn && (
              <Styled.Text onClick={() => handleSignOut()}>
                {t("logout")}
              </Styled.Text>
            )}
          </Styled.Button>
          <Styled.Button>
            <Styled.TextContainer>
              <Icon iconName='help-circle' size='M' />
              {/* TODO: support flow */}
              <Styled.Text>
                <a
                  href="mailto:support@datumexpress.com"
                  style={{
                    color: 'white',
                    textDecoration: "none"
                  }}
                >{t("support")}</a>
              </Styled.Text>
            </Styled.TextContainer>
          </Styled.Button>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
