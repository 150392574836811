import { combineReducers } from "redux";

import userCredentials from "redux/slices/userCredentials";
import dxUserData from "./slices/dxUserData";
import currentCompany from "./slices/currentCompany";
import signupFlow from "./slices/signupFlow";

const rootReducer = combineReducers({
  userCredentials,
  dxUserData,
  currentCompany,
  signupFlow,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
