import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  background: ${({ theme }) => theme.colors.dxGradient};
  position: fixed;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;

  @media (max-width: 575px) {
    min-width: 22rem;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const Button = styled.button`
  padding: 8px 14px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
