import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { Icon } from "../Icon";

export const UploadContainer = muiStyled(Box)`
  width: 100%;
  cursor: pointer;
  outline: none;
  opacity: 1;
  transition: outline 300ms, opacity 300ms;
  outline: white;
  
  &:hover {
    outline: 4px solid #ebe9fe;
    opacity: 1;
    border-radius: 8px;
  }

`;

export const FileContainer = muiStyled(Box)`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed #bdb4fe;
  justify-content: center;

  &.step {
    height: 222px;
  }
`;

export const ColumnText = muiStyled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
`;

export const RowText = styled.div`
  display: flex;
  gap: 4px;

  &.scheme {
    gap: 16px;
    align-items: flex-end;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const IconContainer = muiStyled(Box)`
  border-radius: 28px;
  border: 6px solid #ebe9fe;
  background-color: #ebe9fe;
`;

export const IconUpload = styled(Icon)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const Text = muiStyled(Box)`
  color: #475467;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &.upload {
    font-weight: 600;
    font-family: "Roboto";
    color: #5d45cd;
  }
`;

export const Information = muiStyled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const Container = muiStyled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
`;

export const TextForm = muiStyled(Box)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
