// import styled from "styled-components";
import { styled } from "@mui/material/styles";
import { CheckboxButtonProps } from "./types";

export const CheckboxButton = styled("input")<CheckboxButtonProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  height: fit-content;

  &:before {
    box-sizing: border-box;
    content: "";
    display: block;
    width: ${({ btnSize }) => btnSize || "1.5em"};
    height: ${({ btnSize }) => btnSize || "1.5em"};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    background: ${({ theme }) => theme.palette.common.white};
    cursor: pointer;
  }

  :hover {
    &:before {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.primary.light};
    }
  }

  :checked {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='4' fill='%236938EF'/%3E%3C/svg%3E");
      box-sizing: border-box;
      display: block;
      width: ${({ btnSize }) => btnSize || "1.5em"};
      height: ${({ btnSize }) => btnSize || "1.5em"};
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
      background-color: ${({ theme }) => theme.palette.primary.light};

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;
