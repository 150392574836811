import { useHistory } from "react-router-dom";

import DXButton from "features/common/components/DXButton";
import { ROUTES } from "features/routes";
import { ArrowLeft } from "assets/icons";

import { Container, Image, Text } from "./styles";
import { Row } from "../styles";

export const Header = ({ onSubmit }) => {
  const { push } = useHistory();

  return (
    <Container>
      <Row gap="16px">
        <Image
          src={ArrowLeft}
          onClick={() => push(ROUTES.SETTINGS_PATH, { tabValue: 2 })}
        />
        <Text>Edit User</Text>
      </Row>
      <DXButton
        onClick={onSubmit}
        children="Save Changes"
        size="large"
        variant="contained"
      />
    </Container>
  );
};
