import { useTranslation } from "react-i18next";

import { Modal } from "features/common/components/Modal/Modal";
import { ModalProps } from "../types";
import DXButton from "features/common/components/DXButton";

import { Body, Container, Wrapper, Text } from "./ModalDeleteUser.styles";

export default function ModalDeleteUser({
  isOpen,
  closeModal,
  setUsers,
  users,
  userIndex,
}: ModalProps) {
  const { t } = useTranslation();

  const handleRemoveUser = () => {
    const updatedUsers = [...users];
    updatedUsers.splice(userIndex, 1);
    setUsers(updatedUsers);
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <Modal className="delete" onClose={closeModal}>
          <>
            <Modal.Header onClose={closeModal}></Modal.Header>
            <Modal.Body>
              <Wrapper>
                <Body>
                  <Text>{t("remove-user")}</Text>
                </Body>
              </Wrapper>
            </Modal.Body>
            <Modal.ActionButtons>
              <Container>
                <DXButton children="Cancel" onClick={closeModal} />
                <DXButton
                  type="button"
                  color="error"
                  variant="contained"
                  children="Remove user"
                  onClick={handleRemoveUser}
                />
              </Container>
            </Modal.ActionButtons>
          </>
        </Modal>
      )}
    </>
  );
}
