export const step1Information = [
  {
    iconName: "",
    text: "Classes",
    icon: "classes",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/class.xlsx#",
  },
  {
    iconName: "",
    text: "Customers",
    icon: "customers",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/customer.xlsx#",
  },
  {
    iconName: "",
    text: "Departments",
    icon: "departments",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/department.xlsx#",
  },
  {
    iconName: "",
    text: "Employees",
    icon: "employees",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/employee.xlsx#",
  },
  {
    iconName: "",
    text: "Items, Products & Services",
    icon: "itemsProducts",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/item.xlsx#",
  },
  {
    iconName: "",
    text: "Vendors",
    icon: "vendors",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/vendor.xlsx#",
  },
];
