export const step3Information = [
  {
    iconName: "amortization",
    text: "Amortization & Depreciation",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/depreciationamortization.xlsx#",
  },
  {
    iconName: "deferred",
    text: "Deferred Revenue",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/deferredrevenue.xlsx#",
  },
  {
    iconName: "prepaid",
    text: "Pre-Paid Assets",
    url: "https://neutech-s3-bucket.s3.amazonaws.com/templates/prepaidassets.xlsx#",
  },
];
