import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MainLayout } from "features/common/components/MainLayout/MainLayout";
import { Hubspot, Salesforce } from "assets/icons";

import {
  Subtitle,
  Container,
  Text,
  Card,
  CardContainer,
  Img,
} from "./Integrations.styles";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useMemo } from "react";
import { getPermissionsByOrgAndCompRole } from "utils/rbac";

export const IntegrationsUpdate = () => {
  const { t } = useTranslation();
  const [isSelectedCard, setSelectedCard] = useState("");
  const history = useHistory();
  const { currentCompany } = useSelector((state: RootState) => state);
  const compRole = currentCompany?.company?.role;
  const orgRole = currentCompany?.account?.role;
  const rbac = currentCompany?.rbac;
  const permissions = getPermissionsByOrgAndCompRole(orgRole, compRole, rbac);

  history.push("/settings");
  useMemo(() => {
    if (permissions?.main_dashboard?.side_bar?.integrations === 'disable') {
      history.push("/settings");
    }
  }, [currentCompany])

  return (
    <MainLayout>
      <Container>
        <Text>{t("Sync your Data from Third-Party Platforms")}</Text>
        <Subtitle>
          Advanced algorithms auto-sync relevant data or allow you to create
          deferred revenue schedules.
        </Subtitle>
        <CardContainer>
          <Card
            className="hubspot"
            // onClick={() => setSelectedCard("salesforce")}
            // className={isSelectedCard === "salesforce" ? "selected" : ""}
          >
            <Img src={Salesforce} />
            <Subtitle>Coming soon!</Subtitle>
          </Card>
          <Card
            //TODO: when hubspot is able
            // onClick={() => setSelectedCard("hubspot")}
            // className={
            //   isSelectedCard === "hubspot" ? "selected hubspot" : "hubspot"
            // }
            className="hubspot"
          >
            <Img className="hubspot" src={Hubspot} />
            <Subtitle>Coming soon!</Subtitle>
          </Card>
        </CardContainer>
      </Container>
    </MainLayout>
  );
};
