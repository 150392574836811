import { withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";

export const CustomCheckbox = withStyles({
  root: {
    color: "#d0d5dd",
    "&$checked": {
      color: "#5925DC !important",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
