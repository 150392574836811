import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Step } from "features/common/components/Step/Step";
import { common } from "theme";
import { step2Information } from "./utils";
import { Files } from "features/common/components/Files/Files";
import DXAlert from "features/common/components/DXAlert/DXAlert";

import { StepContainer } from "../../TemplateLibrary.styles";
import { Container, FilesContainer } from "../Step1/Step1.styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export const Step2 = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenAlert, setIsOpenAlert] = useState(true);
  const { currentCompany } = useSelector((state: RootState) => state);

  return (
    <StepContainer className={isOpen ? "isOpen" : "isClose"}>
      <Step
        title="Type 2"
        color={common.colors.blueDark}
        text={t("template-title-2")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <Container>
          {isOpenAlert && (
            <DXAlert
              children="All relevant Type 1 templates must be uploaded prior to using any Type 2 templates."
              color="success"
              severity="info"
              onClose={() => {
                setIsOpenAlert(false);
              }}
              style={{alignItems: 'center'}}
            />
          )}
          <FilesContainer>
            {step2Information.map(({ text, iconName, url }) => (
              <Files
                title={text}
                color={common.colors.blueDark50}
                iconDownload="downloadBlue"
                icon={iconName}
                url={url}
                disabled={
                  currentCompany?.company?.metadata?.type !== 'payment' &&
                    (
                      text === 'Bill Payments' ||
                      text === 'Credit Card Payment' ||
                      text === 'Transfers'
                    ) ? true : false
                }
              />
            ))}
          </FilesContainer>
        </Container>
      )}
    </StepContainer>
  );
};
