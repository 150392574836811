export const manageAccountColumns = [
  {
    field: "userName",
    headerName: "User Name",
    flex: 1,
    disableColumnMenu: true,
    align: "center",
    type: "number",
  },

  {
    field: "email",
    name: "email",
    headerName: "Email",
    align: "center",
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
  },

  {
    field: "role",
    headerName: "Role",
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    align: "center",
  },
];

export const manageUsersColumns = [
  {
    field: "userName",
    headerName: "User Name",
    flex: 1,
    disableColumnMenu: true,
    align: "center",
    type: "number",
  },

  {
    field: "email",
    name: "email",
    headerName: "Email",
    align: "center",
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
  },

  {
    field: "workspaces",
    headerName: "Number of Workspaces",
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    align: "center",
  },
];
