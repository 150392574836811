import React, { Dispatch, SetStateAction } from "react";

import { Icon as IconField } from "features/common/components/Icon";

import {
  ActionContainer,
  IconAction,
  Input,
} from "./TableManageCompany.styles";

interface CellType {
  setSelectedUser: Dispatch<any>;
  setIsOpenEditUser: Dispatch<SetStateAction<boolean>>;
  handleOpenDeleteUser: (userId: any) => void;
  handleOpenEditUser?: (userId: any) => void;
  params: any;
  disabled?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  editHide?: boolean;
  deleteHide?: boolean;
}

export const Cell = ({
  setSelectedUser,
  setIsOpenEditUser,
  handleOpenDeleteUser,
  handleOpenEditUser,
  params,
  editDisabled,
  deleteDisabled,
  editHide,
  deleteHide,
}: CellType) => {
  return (
  <Input>
  { !editHide && (
    <ActionContainer>
      <IconAction 
      onClick={() => handleOpenEditUser(params.row.id)}
      disabled={editDisabled}
      style={{ 
        cursor: editDisabled ? 'not-allowed' : 'pointer',
        color: editDisabled ? 'gray' : 'initial'
    }}
      >
        <IconField 
        iconName="edit-05"></IconField>Edit
      </IconAction>
    </ActionContainer>
  )}
  { !deleteHide && (
    <ActionContainer>
      <IconAction 
      onClick={() => handleOpenDeleteUser(params.row.id)}
      disabled={deleteDisabled}
      style={{ 
        cursor: deleteDisabled ? 'not-allowed' : 'pointer',
        color: deleteDisabled ? 'gray' : 'initial'
    }}
      >
        <IconField iconName="trash-01"></IconField>
        Delete
      </IconAction>
    </ActionContainer>
  )}
  </Input>
)};
