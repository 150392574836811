import React from "react";
import customIcons from "./customIcons";

type Props = {
  name: keyof typeof customIcons;
  style?: any;
  cssClass?: string;
};

export const DXIcon = ({ name, style, cssClass = "" }: Props) => {
  const SelectedIcon = customIcons[name];
  if (!SelectedIcon) return null;
  return <SelectedIcon className={cssClass} style={{ ...style }} />;
};

export default DXIcon;
