export const stepsData = [
  {
    title: "Set Up Your Profile",
    subtitle: "Please provide your name and email",
  },
  {
    title: "Choose Account Type",
    subtitle: "Make your selection based on licensing needs",
  },
  {
    title: "Set Up Your Plan",
    subtitle: "Customize plan and enter billing information",
  },
  {
    title: "Set Up Your Organization",
    subtitle: "Invite users to join your org-level workspace",
  },
  {
    title: "Set Up Client Accounts",
    subtitle: "Invite users to join your client-level workspace",
  },
];
