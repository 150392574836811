import styled from "styled-components";

import { common } from "theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${common.colors.white};
  border-radius: 12px;
  box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.03),
    0px 1px 4px 0px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  min-height: 64px;
  max-height: 64px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid ${common.colors.gray200};
`;

export const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
`;

export const Tag = styled.div`
  display: flex;
  padding: 4px 10px;
  border-radius: 100px;
  border: 1px solid ${common.colors.success100};
  background: ${common.colors.success50};
  color: ${common.colors.gray700};
  font-family: ${common.fonts.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const Text = styled.div`
  color: ${common.colors.gray900};
  font-family: ${common.fonts.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const DropwdownContainer = styled.div`
  width: 100%;
`;

export const RemoveButton = styled.div`
  border-radius: 6px;
  border: 1px solid #fee4e2;
  background: ${common.colors.error50};
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #d92d20;
  font-family: ${common.fonts.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const Page = styled.div`
  color: ${common.colors.gray600};
  text-align: center;
  font-family: ${common.fonts.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  width: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background-color: rgb(249, 250, 251);
`;
