import { useEffect, useState } from "react";

import { StepProps } from "./types";

import {
  Information,
  StepIcon,
  TextInformation,
  ContainerInfo,
  IconDownload,
  IconContainer,
  IconFile,
  IconHover,
} from "./Files.styles";
import DXTooltip from "../DXTooltip";

export const Files = ({ title, color, iconDownload, icon, url, disabled }: StepProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [downloadFinished, setDownloadFinished] = useState(false);

  const handleDownloadClick = (url: string) => {
    setTimeout(() => {
      window.location.href = url;
      setDownloadFinished(true);
    }, 4000);

    setIsClicked(true);
  };

  useEffect(() => {
    if (downloadFinished) {
      setIsClicked(false);
    }
  }, [downloadFinished]);

  const HtmlTooltip = ({ title, children, className }) => {
    return (
      <DXTooltip
        title={<div dangerouslySetInnerHTML={{ __html: title }} />}
        className={className}
      >
        {children}
      </DXTooltip>
    );
  };

  const TooltipWrapper = ({ children, disabled, className, fileTitle }) => {
    
    const title = fileTitle === 'Bill Payments' || fileTitle === 'Credit Card Payment' || fileTitle === 'Transfers' ? 'These templates come with the upgrade to a QuickBooks Payments Account. <a style ="color: white;" href="https://quickbooks.intuit.com/payments/?cid=ppc_G_e_US_.Payments_US_GGL_Brand_Top_Search_Desktop_WP._quickbooks%20payments_txt&agid=58700008069630676&infinity=ict2~net~gaw~ar~625228059025~kw~quickbooks%20payments~mt~e~cmp~Payments_US_GGL_Brand_Top_Search_Desktop_WP~ag~Core&gad_source=1&gclid=CjwKCAjwhvi0BhA4EiwAX25uj4H-ydKObAuNK33x1EmLkJ37NcRP-_9fGY2jsb7KuM4PAtKqVzsXtxoCGgwQAvD_BwE&gclsrc=aw.ds">Click here to upgrade.</a> If you have incorrectly selected a regular account when you have existing access to the payments modules, please <a style="color: white;" href="mailto:support@datumexpress.com">reach out to our support team</a>.' : 'Coming soon.';

    return disabled ? (
      <HtmlTooltip title={title} className={className}>
        {children}
      </HtmlTooltip>
    ) : (
      <>{children}</>
    );
  };

  return (
    <TooltipWrapper disabled={disabled} className='customTooltip' fileTitle={title} >
      <Information >
        <ContainerInfo
        style={{ opacity: disabled ? 0.5 : 1 }}
        >
          <StepIcon color={color}>
            {icon && <IconFile iconName={icon} />}
          </StepIcon>
          <TextInformation>{title}</TextInformation>
        </ContainerInfo>
        <IconContainer
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
          <IconHover
            color={color}
            className={isClicked ? "show" : ""}
            disabled={disabled}
            onClick={() => handleDownloadClick(url)}
          >
            <IconDownload
              iconName={iconDownload} />
          </IconHover>
        </IconContainer>
      </Information>
    </TooltipWrapper>
  );
};